import toast from "react-hot-toast";

const server = process.env.REACT_APP_SERVER_URL || "";

export const createEquipment = {
  add: async (formData: any) => {
    try {
      const { designation } = formData;

      const response = await fetch(`${server}/api/admin/equipments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ designation }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.msg);
        return data;
      } else {
        toast.error(data.error);
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },

  verify: async () => {
    try {
      const response = await fetch(`${server}/api/equipments`);
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  delete: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/admin/equipments/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred while deleting rule .");
      }
      const res = await response.json();
      toast.success(res.message);
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      throw new Error("Error deleting rule.");
    }
  },

  update: async (data: any, id: any) => {
    try {
      const { designation } = data;

      const response = await fetch(`${server}/api/admin/equipments/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ designation }),
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
  getById: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/equipments/${id}`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      const data = await response.json();
      return data;
    } catch (error) {}
  },

  adminGetAllEquipments: async (page: number, designation: string) => {
    const result = await fetch(`${server}/api/admin/equipments?page=${page}&designation=${designation}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },
};
