import { AuthContext } from "context/appContext";
import { Notifications, ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createAnnounce,
  getAnnounceAllDataById,
  initState,
  nextStep,
  previousStep,
  setAnnounceData,
  updateAnnounce,
} from "redux/features/announce/announceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import AnnounceAddress from "../components/AnnounceAddress";
import AnnounceDescription from "../components/AnnounceDescription";
import AnnounceImages from "../components/AnnounceImages";
import AnnounceProfile from "../components/AnnounceProfile";
import { socket } from "utils/socket";

const AnnounceCommonLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const isLactionUpadate = location.pathname.includes("update-announce");

  const authContext = useContext(AuthContext);
  const profile = authContext.user?.profil as ProfilClient;
  const announceState = useAppSelector((state) => state.announce);

  const progress = (announceState.currentStep / announceState.totalSteps) * 100;
  const lastStep = announceState.currentStep === announceState.totalSteps;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const [event, setEvent] = useState<Event>(new Event(""));

  useEffect(() => {
    window.scrollTo(0, 0);
    load();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [announceState.currentStep]);

  useEffect(() => {
    switch (announceState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        if (isLactionUpadate) {
          if (isNextStep) {
            navigate(`/client/announces/`);
          } else {
            setIsLoading(false);
          }
        } else {
          if (announceState.announceData?.id !== null) {
            navigate(`/client/announces`);
          }
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        setIsSubmited(false);
        break;
      }
    }
  }, [announceState.loading]);

  useEffect(() => {
    handleData();
  }, [announceState.announceData]);

  useEffect(() => {
    if (announceState.announceError) {
      toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
    }
  }, [announceState.announceError]);

  useEffect(() => {
    announceState.announceErrorValidation?.forEach((element) => {
      toast.error(`Il y a une erreur du : [${element.path}] \n ${element.msg}`);
    });
  }, [announceState.announceErrorValidation]);

  useEffect(() => {
    if (announceState.announceNotExist) {
      navigate("/404");
    }
  }, [announceState.announceNotExist]);

  const load = async () => {
    await dispatch(initState());

    if (isLactionUpadate) {
      if (id) {
        dispatch(getAnnounceAllDataById(id));
      } else {
        navigate("/404");
      }
    }
  };

  const handleNextClick = () => {
    setIsNextStep(true);
    switch (announceState.currentStep) {
      case 1: {
        setEvent(new Event("chekLocationFormOne"));
        break;
      }
      case 2: {
        setEvent(new Event("chekLocationFormTwo"));
        break;
      }
      case 3: {
        setEvent(new Event("chekLocationFormThree"));
        break;
      }
      case 4: {
        setEvent(new Event("chekLocationFormFour"));
        break;
      }
      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };

  const handlePreviousClick = () => {
    setIsNextStep(false);
    dispatch(previousStep());
  };

  const handleData = async () => {
    if (announceState.announceData) {
      if (lastStep && !isSubmited) {
        setIsSubmited(true);
        if (isLactionUpadate) {
          dispatch(updateAnnounce({ data: announceState.announceData, id }));
        } else {
          const res = await dispatch(createAnnounce(announceState.announceData));

          navigate("/client/announces");
        }

        return;
      }

      if (isNextStep && !lastStep) {
        dispatch(nextStep());
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className={`pr-4 pb-24 pl-4 mx-auto nc-PageAddListing1 lg:pl-10 lg:pr-32 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="mt-10 space-y-6">
        <div className="flex justify-between">
          <p className="mt-5">
            Bonjour Mr/Mme :
            <span className="pl-4 font-bold text-black dark:text-white">
              {profile?.name} {profile?.lastName}
            </span>
          </p>
        </div>

        {announceState.currentStep === 1 && (
          <AnnounceProfile
            isLoading={isLoading}
            handleForm={event}
            data={announceState.announceData}
            FormData={(data) => dispatch(setAnnounceData({ ...announceState.announceData, ...data }))}
          />
        )}

        {announceState.currentStep === 2 && (
          <AnnounceAddress
            isLoading={isLoading}
            handleForm={event}
            data={announceState.announceData}
            FormData={(data) => dispatch(setAnnounceData({ ...announceState.announceData, ...data }))}
          />
        )}

        {announceState.currentStep === 3 && (
          <AnnounceDescription
            isLoading={isLoading}
            handleForm={event}
            data={announceState.announceData}
            FormData={(data) => dispatch(setAnnounceData({ ...announceState.announceData, ...data }))}
          />
        )}

        {announceState.currentStep === 4 && (
          <AnnounceImages
            isLoading={isLoading}
            handleForm={event}
            data={announceState.announceData}
            FormData={(data) => dispatch(setAnnounceData({ ...announceState.announceData, ...data }))}
          />
        )}

        <div className="flex flex-col items-center justify-center space-y-3">
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
          </div>

          <div className="flex items-center justify-between w-full">
            <ButtonSecondary onClick={handlePreviousClick} disabled={announceState.currentStep <= 1}>
              Retour
            </ButtonSecondary>

            <p className="text-lg font-semibold">
              {`Étape ${announceState.currentStep}/${announceState.totalSteps}`}
            </p>

            <div className="space-x-5">
              <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                Continuer
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnounceCommonLayout;
