import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Input from "shared/Input/Input";
import Pagination from "shared/Pagination/Pagination";

import TransactionDetailsPage from "containers/Admin/Transactions/TransactionDetailsPage";
import { AuthContext } from "context/appContext";
import { itemPerDashborad } from "data/constants";
import { ProfilClient } from "data/types";
import { getOwnerAllTransactions } from "redux/features/Admin/Stripe/stripeSlice";
import CommonLayout from "../AccountPage/CommonLayout";
import StripeMenu from "./StripeMenu";

export interface ClientTransactionsPageProps {
  className?: string;
}

const ClientTransactionsPage = () => {
  const dispatch = useAppDispatch();
  const authContext = useContext(AuthContext);

  let profile = authContext.user?.profil as ProfilClient;
  const transactions = useAppSelector((state) => state?.stripe?.transactionsList);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

  const itemsPerPage = itemPerDashborad;

  useEffect(() => {
    if (profile) {
      dispatch(getOwnerAllTransactions({ account_id: profile?.account_id || "" }));
    }
  }, []);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const filteredTransactions = transactions?.filter((item) => {
    const reference = item.id.toLowerCase();
    const amount = item.amount.toString();
    return reference.includes(searchTerm.toLowerCase()) || amount.includes(searchTerm);
  });

  const paginatedTransactions =
    filteredTransactions?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || [];

  const totalPages = Math.ceil((filteredTransactions?.length || 0) / itemsPerPage);

  const handleEyeIconClick = (reclamation: any) => {
    setSelectedTransaction(reclamation);
    setShowTransactionDetails(true);
  };

  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    let calculatedTotal = 0;

    paginatedTransactions.forEach((item) => {
      const halfTotal = item.amount ? parseFloat(item.amount.toString()) : 0;

      calculatedTotal += halfTotal;
    });

    setTotalAmount(calculatedTotal);
  }, [paginatedTransactions, dispatch, profile]);
  return (
    <div className={`nc-AccountPage bg-neutral-50`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <CommonLayout>
        <StripeMenu amount={totalAmount}>
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-4">
              <div></div>
              <div className="relative flex">
                <Input
                  type="text"
                  placeholder="Rechercher"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-500 transform -translate-y-1/2 top-1/2 right-3" />
              </div>
            </div>

            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-sm font-light text-center">
                    <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
                      <tr>
                        <th scope="col" className="px-6 py-4 ">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-4 ">
                          Reférence
                        </th>

                        <th scope="col" className="px-6 py-4 ">
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {paginatedTransactions.map((item, index) => (
                        <tr className="border-b dark:border-neutral-500" key={index}>
                          <td className="px-6 py-4 font-medium whitespace-nowrap">
                            {new Date(item?.created * 1000).toLocaleDateString("fr-FR", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </td>
                          <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.id}</td>

                          <td className="px-6 py-4 font-medium whitespace-nowrap">{item?.amount} € </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end ">
                    <span className="text-sm text-gray-700">
                      {filteredTransactions && filteredTransactions.length} élément
                    </span>
                  </div>
                  {showTransactionDetails && (
                    <TransactionDetailsPage
                      open={true}
                      reclamationData={selectedTransaction}
                      handleClose={() => setShowTransactionDetails(false)}
                    />
                  )}
                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={Math.ceil((transactions?.length ?? 0) / itemsPerPage)}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StripeMenu>
      </CommonLayout>
    </div>
  );
};

export default ClientTransactionsPage;
