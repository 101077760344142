const server = process.env.REACT_APP_SERVER_URL || "";

export const createLocation = {
  getAllValidateLocations: async (pageNumber: number) => {
    const result = await fetch(`${server}/api/location/approuved?page=${pageNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  add: async (formData: any) => {
    try {
      const form = new FormData();
      form.append("title", formData.title);
      form.append("categoryLocationId", formData.categoryLocationId);
      form.append("map", "");
      form.append("price", formData.price);
      form.append("startHour", formData.startHour);
      form.append("endHour", formData.endHour);
      form.append("address", formData.address);
      form.append("address2", formData.fullAddress);
      form.append("city", formData.city);
      form.append("surface", formData.surface);
      form.append("visitor", String(formData.nbreVisitor));
      form.append("parking", formData.parking);
      form.append("wc", formData.wc);
      form.append("description", String(formData.description));
      form.append("water", String(formData.water));
      form.append("light", String(formData.light));
      form.append("handicap", String(formData.handicap));
      form.append("guarantee", formData.guarantee);
      form.append("validation", String(formData.validation));
      form.append("atmosphere", JSON.stringify(formData.selectedAtmospheres));
      form.append("playground", JSON.stringify(formData.selectedPlayground));
      form.append("ownerId", String(formData.ownerId));
      form.append("events", JSON.stringify(formData.selectedEvents));
      form.append("availableDays", JSON.stringify(formData.selectedAvailableDays));

      if (formData.images) {
        for (let i = 0; i < formData.images.length; i++) {
          form.append("images", formData.images[i]);
        }
      }

      const response = await fetch(`${server}/api/location`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: form,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  },

  getLocations: async () => {
    const response = await fetch(`${server}/api/location`);
    return response.json();
  },

  getLocationById: async (id: any) => {
    const response = await fetch(`${server}/api/location/${id}`);
    return response.json();
  },
  getExtraById: async (id: any) => {
    const response = await fetch(`${server}/api/extra/location/${id}`);
    return response.json();
  },
  getCommentById: async (id: any) => {
    const response = await fetch(`${server}/api/comment/location/${id}`);
    return response.json();
  },
  getEquipmentById: async (id: any) => {
    const response = await fetch(`${server}/api/location-equipment/location/${id}`);
    return response.json();
  },
  getConditionById: async (id: any) => {
    const response = await fetch(`${server}/api/condition/location/${id}`);
    return response.json();
  },
  getOwnerLocations: async (id: any) => {
    const response = await fetch(`${server}/api/location/owners/${id}`);
    const locations = await response.json();
    const calculateRoundedAverageLikes = (comments: any) => {
      if (comments.length === 0) {
        return { totalLikes: 0, roundedAverageLikes: 0 };
      }
      const totalLikes = comments.reduce((sum: any, comment: any) => sum + (comment.like || 0), 0);
      const averageLikes = totalLikes / comments.length;
      return { totalLikes, roundedAverageLikes: Math.round(averageLikes) };
    };
    const calculateAverageLikesForEachLocation = (locations: any) => {
      let totalRoundedAverageLikes = 0;
      let totalComments = 0;

      const locationsWithRoundedAverage = locations.map((location: any) => {
        const { totalLikes, roundedAverageLikes } = calculateRoundedAverageLikes(location.Comments);
        totalRoundedAverageLikes += roundedAverageLikes;
        totalComments += location.Comments.length;

        const commentsWithRoundedAverage = location.Comments.map((comment: any) => ({
          ...comment,
        }));
        return {
          ...location,
          Comments: commentsWithRoundedAverage,
          roundedAverageLikes,
        };
      });

      const overallRoundedAverageLikes = totalRoundedAverageLikes / locations.length;

      return {
        locationsWithRoundedAverage,
        overallRoundedAverageLikes,
        totalComments,
      };
    };

    const { locationsWithRoundedAverage, overallRoundedAverageLikes, totalComments } =
      calculateAverageLikesForEachLocation(locations);

    return { locationsWithRoundedAverage, overallRoundedAverageLikes, totalComments };
  },

  addComment: async (formData: any) => {
    try {
      const response = await fetch(`${server}/api/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
  checkLocationFavoriByUserId: async (locationId: any, userId: any) => {
    const result = await fetch(`${server}/api/favori/location/${locationId}/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result.json();
  },

  addLocationToFavoris: async (locationId: any, clientId: any) => {
    const result = await fetch(`${server}/api/favori/`, {
      method: "POST",
      body: JSON.stringify({
        locationId: locationId,
        clientId: clientId,
      }),
    });
    return result.json();
  },

  getFavorisLocationByClientId: async (clientId: any) => {
    const result = await fetch(`${server}/api/favori/client/${clientId}`, {
      method: "GET",
    });
    return result.json();
  },

  deleteLocationFromFavoris: async (locationId: any, userId: any) => {
    const result = await fetch(`${server}/api/favori/${locationId}/${userId}`, {
      method: "DELETE",
    });
    return result.json();
  },

  getAllFavoris: async () => {
    const result = await fetch(`${server}/api/favori/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },
  getLocationsFiltered: async (pageNumber: number, filters: any) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.events) {
        queryParams.append("events", filters.events);
      } else if (filters.event) {
        queryParams.append("events", filters.event);
      }
      if (filters.region) {
        queryParams.append("region", filters.region);
      }
      if (filters.category !== undefined) queryParams.append("categoryLocationId", filters.category);
      if (filters.visitor !== undefined) queryParams.append("visitor", filters.visitor);
      if (filters.minPrice !== undefined) queryParams.append("minPrice", filters.minPrice);
      if (filters.maxPrice !== undefined) queryParams.append("maxPrice", filters.maxPrice);
      if (filters.title !== undefined) queryParams.append("title", filters.title);
      const url = `${server}/api/location/search?${queryParams.toString()}&page=${pageNumber}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const roundedAverageLikes = data.locations.map((location: any) => {
        const comments = location.Comments || [];
        const totalLikes = comments.reduce((sum: any, comment: any) => sum + (comment.like || 0), 0);
        const averageLikes = comments.length > 0 ? totalLikes / comments.length : 0;
        const roundedAverage = Math.round(averageLikes);
        return {
          ...location,
          roundedAverageLikes: roundedAverage,
        };
      });

      return {
        totalPages: data.totalPages,
        count: data.count,
        locations: roundedAverageLikes,
        totalCount: data.totalCount,
      };
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      throw error;
    }
  },

  getSimilarLocations: async (locationId: any, categoryId: any) => {
    const response = await fetch(`${server}/api/locations/similar/${locationId}/${categoryId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  },
};

export const FavorisLocation = {
  add: async (locationId: any, clientId: any) => {
    const response = await fetch(`${server}/api/favori`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        locationId: locationId,
        clientId: clientId,
      }),
    });
    const data = await response.json();
    return data;
  },

  getByClient: async (pclientId: any) => {
    try {
      const { clientId } = pclientId;
      const result = await fetch(`${server}/api/favori/client/${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const jsonResult = await result.json(); // Read the response body only once
      return jsonResult;
    } catch (error) {
      console.error("Error in getByClient:", error);
      throw error; // You may want to handle or log the error appropriately
    }
  },
  delete: async (id: any) => {
    try {
      const response = await fetch(`${server}/api/favori/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete favori");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteLocationFromFavoris: async (locationId: string, clientId: number) => {
    const result = await fetch(`${server}/api/favori/${locationId}/${clientId}`, {
      method: "DELETE",
      body: JSON.stringify({
        locationId: locationId,
        clientId: clientId,
      }),
    });
    return result.json();
  },

  checkFavoriByClientId: async (locationId: any, clientId: number) => {
    const result = await fetch(`${server}/api/favori/location/${locationId}/${clientId}`, {
      method: "GET",
      headers: {
        ContentType: "application/json",
      },
    });

    return result.json();
  },
};

export const createLocationEquipment = {
  add: async (formData: any) => {
    const { locationId, data: equipments } = formData;
    equipments.forEach(async (equipment: any) => {
      const equipmentData = {
        locationId: locationId,
        equipment: equipment,
      };

      const response = await fetch(`${server}/api/location-equipment`, {
        method: "POST",
        body: JSON.stringify(equipmentData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      return data;
    });
  },
};

export const createLocationRule = {
  add: async (formData: any) => {
    const { locationId, data: rules } = formData;

    rules.forEach(async (designation: any) => {
      const rulesData = {
        locationId: locationId,
        designation: designation,
      };

      const response = await fetch(`${server}/api/condition`, {
        method: "POST",
        body: JSON.stringify(rulesData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      return data;
    });
  },
};

export const createLocationExtraEquipment = {
  add: async (formData: any) => {
    const { locationId, data: equips } = formData;
    equips.forEach(async (designation: any) => {
      const equipsData = {
        locationId: locationId,
        designation: designation.extra,
        price: designation.price,
        quantity: designation.quantity,
      };
      const response = await fetch(`${server}/api/extra`, {
        method: "POST",
        body: JSON.stringify(equipsData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      return data;
    });
  },
};
export const createLocationPrice = {
  add: async (formData: any) => {
    const { locationId, data: prices } = formData;
    prices.forEach(async (price: any) => {
      const equipsData = {
        locationId: locationId,
        min: price.min,
        price: price.price,
        max: price.max,
      };
      const response = await fetch(`${server}/api/price`, {
        method: "POST",
        body: JSON.stringify(equipsData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      return data;
    });
  },
  getPricesByIdLocation: async (id: any) => {
    try {
      const result = await fetch(`${server}/api/price/location/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const jsonResult = await result.json(); // Read the response body only once
      return jsonResult;
    } catch (error) {
      console.error("Error in getByClient:", error);
      throw error; // You may want to handle or log the error appropriately
    }
  },
  getPricesById: async (id: any) => {
    try {
      const result = await fetch(`${server}/api/price/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const jsonResult = await result.json(); // Read the response body only once
      return jsonResult;
    } catch (error) {
      console.error("Error in getByClient:", error);
      throw error; // You may want to handle or log the error appropriately
    }
  },

  getWeekendPricesById: async (id: any) => {
    try {
      const result = await fetch(`${server}/api/weekend/price/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const jsonResult = await result.json(); // Read the response body only once
      return jsonResult;
    } catch (error) {
      console.error("Error in getByClient:", error);
      throw error; // You may want to handle or log the error appropriately
    }
  },
};
