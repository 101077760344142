import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const ContactApi = {
  createContactRequest: async (data: any) => {
    const result = await fetch(`${baseURL}/api/contacts/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ ...data }),
    });

    let response = await result.json();
    if (result.ok) {
      return response;
    } else {
      throw new Error(response.message);
    }
  },

  adminGetAllContacts: async (page: number, email: string) => {
    const result = await fetch(`${baseURL}/api/admin/contacts?page=${page}&email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    let response = await result.json();
    if (result.ok) {
      return response;
    } else {
      throw new Error(response.message);
    }
  },

  deleteContact: async (id: any) => {
    const response = await fetch(`${baseURL}/api/admin/contacts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred while deleting rule .");
    }
    const res = await response.json();
    toast.success(res.message);
  },
  catch(error: any) {
    console.error("error", error);
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }
    throw new Error("Error deleting rule.");
  },

  getContactById: async (id: any) => {
    try {
    } catch (error) {}
  },
};
