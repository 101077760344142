import { zodResolver } from "@hookform/resolvers/zod";
import { Card, ListItem } from "@material-tailwind/react";
import { List } from "@mui/material";
import { AnnounceFormProps } from "data/reduxInterfaces";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Input from "shared/Input/Input";
import { z } from "zod";
import FormItem from "./FormItem";

const formSchema = z.object({
  city: z.string().min(1, { message: "Le ville est obligatoire." }),
  address: z.string().min(1, { message: 'L"addresse est obligatoire.' }),
  address2: z.string().optional(),
  country: z.string().optional(),
  codePostale: z.number({
    required_error: "Le code postale est obligatoire.",
    invalid_type_error: "Le code postale doit étre un nombre.",
  }),

  map: z.string().optional(),
});

const AnnounceAddress: FC<AnnounceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const [isload, setIload] = useState(isLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      city: data?.city ?? "",
      address: data?.address ?? "",
      address2: data?.address2 ?? "",
      country: data?.country ?? "",
      codePostale: data?.codePostale ?? undefined,
      map: data?.map ?? "",
    },
    mode: "onSubmit",
  });

  const { placesService, placePredictions, isPlacePredictionsLoading, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_MAPS_API_KEY + "&loading=async",
    options: {
      input: "",
      componentRestrictions: {
        country: "fr",
      },
    },
  });

  useEffect(() => {
    if (data) {
      setValue("city", data.city);
      setValue("address", data.address);
      setValue("address2", data.address2);
      setValue("codePostale", data.codePostale);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormTwo") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const holdeSelectAddress = async (place_id: string) => {
    setIload(true);
    placesService?.getDetails({ placeId: place_id }, (placeDetails: any, placeStatus: any) => {
      if (placeStatus !== "OK") {
        toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
      }
      const parser = new DOMParser();
      const adr_address = parser.parseFromString(placeDetails?.adr_address ?? "", "text/html");
      const streetAddress = adr_address.querySelector(".street-address")?.textContent || "";
      const postalCode = adr_address.querySelector(".postal-code")?.textContent || "";
      const locality = adr_address.querySelector(".locality")?.textContent || "";
      const country_name = adr_address.querySelector(".country-name")?.textContent || "";

      if (streetAddress !== placeDetails?.name) {
        setValue("address", `${placeDetails?.name}, ${streetAddress}`);
      } else {
        setValue("address", streetAddress);
      }
      setValue("codePostale", Number(postalCode));
      setValue("city", locality);
      setValue("country", country_name);
      setValue("map", placeDetails?.url ?? "");

      setIload(false);
    });

    getPlacePredictions({ input: "" });
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <h2 className="text-2xl font-semibold">Quelle est l'adresse d'annonce ?</h2>
          <h3 className="mt-2 text-sm text-neutral-600">Veuillez indiquer l'adresse complète de votre annonce</h3>
          <div className="mt-3 border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="space-y-8 my-">
            <FormItem label="Adresse *">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                  disabled={isLoading || isload}
                  placeholder="..."
                  {...register("address")}
                  onChange={(e) => {
                    setValue("address", e.target.value);
                    getPlacePredictions({ input: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Escape") {
                      getPlacePredictions({ input: "" });
                    }
                  }}
                  loading={isPlacePredictionsLoading || isload}
                />

                {placePredictions.length > 0 && (
                  <div className="flex flex-col flex-1 w-auto mt-0 mb-24 h-50">
                    <Card className="w-96">
                      <List>
                        {placePredictions.map((item, index) => (
                          <ListItem key={index} onClick={() => holdeSelectAddress(item.place_id)}>
                            {item.description}
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  </div>
                )}

                {errors.address?.message && (
                  <div className="text-xs text-red-500">{`${errors.address?.message}`}</div>
                )}
              </div>
            </FormItem>

            <FormItem label="Complément d'adresse">
              <div className="md:w-1/2">
                <Input disabled={isLoading || isload} placeholder="..." {...register("address2")} />
              </div>
            </FormItem>

            <FormItem label="Ville *">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                  disabled={isLoading || isload}
                  placeholder="..."
                  {...register("city")}
                />
              </div>
              {errors.city?.message && <div className="text-xs text-red-500">{`${errors.city?.message}`}</div>}
            </FormItem>

            <FormItem label="Code postale *">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.codePostale?.message ? "border-red-500" : ""}`}
                  disabled={isLoading || isload}
                  placeholder="..."
                  type={"number"}
                  {...register("codePostale", { valueAsNumber: true })}
                />
              </div>
              {errors.codePostale?.message && (
                <div className="text-xs text-red-500">{`${errors.codePostale?.message}`}</div>
              )}
            </FormItem>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AnnounceAddress;
