import { Popover, Transition } from "@headlessui/react";
import { ArrowRightOnRectangleIcon, HeartIcon, HomeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CalendarDaysIcon, Cog6ToothIcon, EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "context/appContext";
import { Menu, ProfilClient, ProfilOwner, ProfilService } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { Fragment, useContext, useEffect, useState } from "react";
import { GrAnnounce } from "react-icons/gr";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { MdDashboardCustomize } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logOut } from "redux/features/auth/authSlice";
import Avatar from "shared/Avatar/Avatar";
import { socket } from "utils/socket";

export default function AvatarDropdown() {
  const imagePng = "static-files/user.png";

  const userState = useAppSelector((state) => state?.auth?.user);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authContext = useContext(AuthContext);
  const [profileImg, setProfileImg] = useState(imagePng);
  const [menu, setMenu] = useState<Menu[]>([]);
  const location = useLocation();
  const locationPath = location.pathname.startsWith("/signup");
  useEffect(() => {
    if (locationPath) {
      setMenu([]);
    }

    if (authContext.isAuthenticated && !locationPath) {
      switch (authContext.user?.role) {
        case "admin": {
          setMenu([
            {
              name: authContext.user?.profil ? authContext.user?.login : "ADMIN",
              href: "/",
              icon: UserCircleIcon,
            },
            {
              name: "Tableau de bord",
              href: "/dashboard",
              icon: MdDashboardCustomize,
            },
          ]);
          break;
        }
        case "creator": {
          setMenu([
            {
              name: authContext.user?.profil ? authContext.user?.login : "CREATOR",
              href: "/dashboard-creator",
              icon: UserCircleIcon,
            },
            {
              name: "Mes Articles",
              href: "/dashboard-creator",
              icon: UserCircleIcon,
            },
            {
              name: "Ajouter Article",
              href: "/dashboard/add-article",
              icon: CalendarDaysIcon,
            },
          ]);
          break;
        }
        case "client": {
          const profile = authContext.user && (authContext.user?.profil as ProfilClient);
          setProfileImg(profile?.client_url || imagePng);
          const clientMenu: Menu[] = [
            {
              name: "Tableau de bord",
              href: "/client/dashboard",
              icon: MdDashboardCustomize,
            },
            {
              name: `${profile?.lastName || "Profil"} ${profile?.name || ""}`.trim() || "Profil",
              href: "/client",
              icon: UserCircleIcon,
            },
            ...(authContext?.user?.profil
              ? [
                  ...(profile?.validation !== "approuvé"
                    ? [
                        {
                          name: "Réservations",
                          href: "/client-reservations",
                          icon: CalendarDaysIcon,
                        },
                      ]
                    : []),

                  ...(profile?.validation === "approuvé"
                    ? [
                        {
                          name: "Réservations",
                          href: "/client/my-reservations",
                          icon: CalendarDaysIcon,
                        },
                      ]
                    : []),

                  ...(profile?.validation === "approuvé"
                    ? [
                        {
                          name: "Demande de réservations",
                          href: "/client/list-pending-reservations",
                          icon: CalendarDaysIcon,
                        },
                      ]
                    : []),

                  ...(profile?.validation === "approuvé"
                    ? [
                        {
                          name: "Mes espaces",
                          href: "/client/locations",
                          icon: HomeIcon,
                        },
                      ]
                    : []),

                  ...(profile?.validation === "en cours"
                    ? [
                        {
                          name: "Mes espaces",
                          href: "/client/demande",
                          icon: HomeIcon,
                        },
                      ]
                    : []),

                  {
                    name: "Espace favoris",
                    href: "/client-savelists",
                    icon: HeartIcon,
                  },
                  {
                    name: "Prestations favoris",
                    href: "/client-serviceslist",
                    icon: HeartIcon,
                  },
                ]
              : []),
          ];

          setMenu(clientMenu);
          break;
        }
        case "service": {
          const profile = authContext.user && (authContext.user?.profil as ProfilService);
          setProfileImg(profile?.service_url || imagePng);

          const serviceMenu: Menu[] = [
            {
              name: profile?.designation || "Profil",
              href: "/prestataire",
              icon: UserCircleIcon,
            },
            ...(authContext?.user?.profil
              ? [
                  {
                    name: "Mes prestations",
                    href: "/prestataire/services-list",
                    icon: HiOutlineSquare3Stack3D,
                  },
                  {
                    name: "Demandes de devis",
                    href: "/prestataire/pricing",
                    icon: EnvelopeIcon,
                  },
                ]
              : []),
          ];

          setMenu(serviceMenu);
          break;
        }
        case "owner": {
          const profile = authContext.user && (authContext.user?.profil as ProfilOwner);
          setProfileImg(profile?.owner_url || imagePng);

          const ownerMenu: Menu[] = [
            {
              name: "Profile" || profile?.companyName || `${profile?.lastName} ${profile?.name}`.trim(),
              href: "/owner",
              icon: UserCircleIcon,
            },
            ...(authContext?.user?.profil
              ? [
                  {
                    name: "Mes espaces",
                    href: "/owner/locations",
                    icon: HomeIcon,
                  },
                  {
                    name: "Mes réservations",
                    href: "/owner/reservations",
                    icon: CalendarDaysIcon,
                  },
                  {
                    name: "Mot de passe",
                    href: "/owner-password",
                    icon: LockClosedIcon,
                  },
                  {
                    name: "Mes préferences",
                    href: `/owner-delete/${authContext.user?.id}`,
                    icon: Cog6ToothIcon,
                  },
                ]
              : []),
          ];

          setMenu(ownerMenu);

          break;
        }
      }
    }
  }, [authContext.user, locationPath]);

  const handleLogOut = () => {
    dispatch(logOut());
    navigate("/");
    socket.emit("send-disconnect", { userId: userState?.id });
  };

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        <Popover.Button
          className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={process.env.REACT_APP_CLOUD_FRONT + profileImg} />

          {/* {role === "admin" || role === "creator" && (
            <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" imgUrl={imagePng} />
          )} */}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
            <div className="overflow-hidden shadow-lg rounded-3xl ring-1 ring-black ring-opacity-5">
              <div className="relative grid gap-6 bg-white p-7 dark:bg-neutral-800">
                {menu.map((item, index) => (
                  <Link
                    key={index}
                    to={item.href}
                    className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  >
                    <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                      <item.icon aria-hidden="true" className="w-6 h-6" />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm font-medium">{item.name}</p>
                    </div>
                  </Link>
                ))}
              </div>
              {/* <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" /> */}
              <div className="relative grid gap-6 -mt-6 bg-white p-7 dark:bg-neutral-800">
                <div className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                  <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                    <ArrowRightOnRectangleIcon aria-hidden="true" className="w-6 h-6" />
                  </div>
                  <div className="ml-4">
                    <button className="text-sm font-medium" onClick={handleLogOut}>
                      Se déconnecter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
