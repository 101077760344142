import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import WarningModal from "components/DeleteModal/WarningModal";
import { EntityDialogForm } from "components/FormDialog/EntityDialogForm";
import List from "components/NavMenu/List";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  addCategory,
  adminGetAllLocationCategories,
  deleteCategory,
  updateCategory,
} from "redux/features/Admin/categorys/categorySlice";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface CategoriesPageProps {
  className?: string;
}

interface Equipment {
  id: number;
  designation: string;
}

interface ModalState {
  isCategory: boolean;
  isDeleteModalOpen: boolean;
  selectedItem: Equipment | null;
  loading: boolean;
}

const CategoriesPage: FC<CategoriesPageProps> = ({ className = "" }) => {
  const columns = [{ title: "Designation", accessor: "designation" }];
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [modalState, setModalState] = useState<ModalState>({
    loading: false,
    isCategory: false,
    isDeleteModalOpen: false,
    selectedItem: null,
  });
  const categories = useAppSelector((state) => state.featureCategory?.categories);
  const totalPages = useAppSelector((state) => state.featureCategory?.totalPages);

  useEffect(() => {
    dispatch(adminGetAllLocationCategories({ page: 1, designation: "" }));
  }, []);

  const getDataAction = () => {
    return (page: number) => dispatch(adminGetAllLocationCategories({ page: page, designation: searchTerm }));
  };
  const handleEdit = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isCategory: true });
  };

  const handleOnDelete = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isDeleteModalOpen: true });
  };

  const handleConfirmDelete = async () => {
    if (!modalState.selectedItem) return;
    setModalState((prev) => ({ ...prev, loading: true }));
    await dispatch(deleteCategory(modalState.selectedItem.id));
    dispatch(adminGetAllLocationCategories({ page: 1, designation: "" }));
    setModalState((prev) => ({ ...prev, isDeleteModalOpen: false, loading: false }));
  };

  const handleConfirmUpdate = async (data: { designation: string }) => {
    if (!modalState.selectedItem) return;
    setModalState({ ...modalState, loading: true });

    await dispatch(updateCategory({ data, id: modalState.selectedItem.id })).then(() => {
      setModalState({ ...modalState, loading: false });
      setModalState({ ...modalState, isCategory: false, selectedItem: null });
      dispatch(adminGetAllLocationCategories({ page: 1, designation: "" }));
    });
  };

  useEffect(() => {
    dispatch(adminGetAllLocationCategories({ page: 1, designation: searchTerm })).then(() => {});
  }, [searchTerm, dispatch]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex justify-between">
          <ButtonPrimary className="mb-5" onClick={() => setModalState({ ...modalState, isCategory: true })}>
            Ajouter catégorie
          </ButtonPrimary>
          <div className="relative flex items-center">
            <Input
              type="text"
              placeholder="Rechercher par désignation"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pr-10"
            />
            <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-400 right-2" />
          </div>
        </div>
        <List
          columns={columns}
          getDataAction={getDataAction()}
          data={categories}
          totalPages={totalPages}
          loading={modalState.loading}
          onEdit={handleEdit}
          onDelete={handleOnDelete}
          showConsult={false}
        />

        {modalState.isDeleteModalOpen && (
          <WarningModal
            open={modalState.isDeleteModalOpen}
            id={modalState.selectedItem?.id}
            title={modalState.selectedItem?.designation}
            handleClose={() => setModalState({ ...modalState, isDeleteModalOpen: false })}
            handleDelete={handleConfirmDelete}
            isLoading={modalState.loading}
          />
        )}
        {modalState.isCategory && (
          <EntityDialogForm
            open={modalState.isCategory}
            handleClose={() =>
              setModalState({ ...modalState, isCategory: false, selectedItem: null, isDeleteModalOpen: false })
            }
            isLoading={modalState.loading}
            addfn={(payload: any) => dispatch(addCategory(payload))}
            updatefn={handleConfirmUpdate}
            id={modalState.selectedItem?.id}
            value={modalState.selectedItem?.designation}
            addfnTitle={"Ajouter catégorie"}
            updatefnTitle={"Modifier catégorie"}
            onSubmitSuccess={() => {
              dispatch(adminGetAllLocationCategories({ page: 1, designation: "" }));
            }}
          />
        )}
      </DashboardMenu>
    </div>
  );
};

export default CategoriesPage;
