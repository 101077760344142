const skeletonData = [1, 2, 3, 4];

export const SkeletonLoader = () => {
  return (
    <div className="grid grid-cols-4 gap-4">
      {skeletonData.map((item) => (
        <div key={item} className="max-w-sm overflow-hidden rounded shadow-lg animate-pulse">
          <div className="w-full h-48 bg-gray-200"></div>
          <div className="px-6 py-4">
            <div className="w-3/4 h-4 mb-2 bg-gray-200 rounded"></div>
            <div className="w-full h-3 mb-1 bg-gray-200 rounded"></div>
            <div className="w-1/2 h-3 bg-gray-200 rounded"></div>
          </div>
          <div className="px-6 pt-4 pb-2">
            <div className="inline-block w-20 h-4 mb-2 mr-2 bg-gray-200 rounded-full"></div>
            <div className="inline-block w-16 h-4 mb-2 mr-2 bg-gray-200 rounded-full"></div>
            <div className="inline-block w-16 h-4 mb-2 mr-2 bg-gray-200 rounded-full"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
