import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Card, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import CustomAccordion from "components/Accordion/CustomAccordion";
import {
  configSubItems,
  locationsSubItems,
  reservationsSubItems,
  servicesSubItems,
  statisticsSubItems,
  stripeSubItems,
  usersSubItems,
} from "data/dashboardSubMenu";
import React, { FC, useEffect } from "react";
import { MdDashboardCustomize } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { GrAnnounce } from "react-icons/gr";

export interface DashboardMenuProps {
  children?: React.ReactNode;
}

const DashboardMenu: FC<DashboardMenuProps> = ({ children }) => {
  const [open, setOpen] = React.useState(0);
  const location = useLocation();
  const [activeSubItem, setActiveSubItem] = React.useState<string | null>(location.pathname);

  const handleSubItemClick = (path: string) => {
    setActiveSubItem(path);
  };

  const handleOpen = (value: React.SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    setActiveSubItem(location.pathname);

    if (statisticsSubItems.some((item) => item.to === location.pathname)) {
      setOpen(1);
    } else if (locationsSubItems.some((item) => item.to === location.pathname)) {
      setOpen(2);
    } else if (usersSubItems.some((item) => item.to === location.pathname)) {
      setOpen(3);
    } else if (servicesSubItems.some((item) => item.to === location.pathname)) {
      setOpen(4);
    } else if (configSubItems.some((item) => item.to === location.pathname)) {
      setOpen(5);
    } else if (reservationsSubItems.some((item) => item.to === location.pathname)) {
      setOpen(6);
    } else if (stripeSubItems.some((item) => item.to === location.pathname)) {
      setOpen(7);
    } else {
      setOpen(0);
    }
  }, [location]);

  return (
    <div className="flex flex-col w-full h-full lg:flex-row bg-neutral-50 dark:bg-neutral-900">
      <div className="p-6 border-r lg:w-1/4 border-neutral-200 dark:border-neutral-700 ">
        <Card className="h-full  w-full max-w-[20rem] lg:max-w-none p-4 shadow-xl shadow-blue-gray-900/5 dark:bg-gray-800 overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200">
          <div className="p-4 mb-2">
            <Typography variant="h5" color="blue-gray" className="dark:text-white">
              Bienvenue
            </Typography>
          </div>
          <ListItem className={`p-0 ${location.pathname === "/dashboard" ? "font-bold" : ""}`}>
            <ListItem>
              <ListItemPrefix>
                <MdDashboardCustomize className="h-5 w-9" />
              </ListItemPrefix>
              <Link className="dark:text-white" to="/dashboard">
                Tableau de bord
              </Link>
            </ListItem>
          </ListItem>
          <List>
            <CustomAccordion
              open={open === 1}
              handleOpen={() => handleOpen(1)}
              title="Statistiques"
              icon={<i className="text-2xl las la-chart-area dark:text-white"></i>}
              subItems={statisticsSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 2}
              handleOpen={() => handleOpen(2)}
              title="Espaces"
              icon={<i className="las la-home dark:text-white"></i>}
              subItems={locationsSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 3}
              handleOpen={() => handleOpen(3)}
              title="Utilisateurs"
              icon={<i className="las la-users-cog dark:text-white"></i>}
              subItems={usersSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 4}
              handleOpen={() => handleOpen(4)}
              title="Prestations"
              icon={<i className="las la-stream dark:text-white"></i>}
              subItems={servicesSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 5}
              handleOpen={() => handleOpen(5)}
              title="Paramètre"
              icon={<i className="las la-tools dark:text-white"></i>}
              subItems={configSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 6}
              handleOpen={() => handleOpen(6)}
              title="Réservations"
              icon={<i className="las la-calendar dark:text-white"></i>}
              subItems={reservationsSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <CustomAccordion
              open={open === 7}
              handleOpen={() => handleOpen(7)}
              title="Stripe"
              icon={<i className="lab la-stripe-s dark:text-white"></i>}
              subItems={stripeSubItems}
              customIcon={
                <ChevronDownIcon
                  className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
                />
              }
              activeSubItem={activeSubItem}
              handleSubItemClick={handleSubItemClick}
            />

            <ListItem
              className={`p-0 ${location.pathname === "/admin/dashboard/reclamations" ? "font-bold" : ""}`}
            >
              <ListItem>
                <ListItemPrefix>
                  <i style={{ fontSize: "20px" }} className="las la-exclamation-triangle dark:text-white"></i>
                </ListItemPrefix>
                <Link className="dark:text-white" to="/admin/dashboard/reclamations">
                  Réclamations
                </Link>
              </ListItem>
            </ListItem>
          </List>
        </Card>
      </div>
      <div className="container pb-24 pt-14 sm:pt-20 lg:pb-32">{children}</div>
    </div>
  );
};

export default DashboardMenu;
