import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Page } from "./types";

import Page404 from "containers/Page404/Page404";
import PageHome from "containers/PageHome/PageHome";
import Footer from "shared/Footer/Footer";
import ScrollToTop from "./ScrollToTop";

import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";

import FooterNav from "components/FooterNav";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";

import ClientAccountPage from "containers/Client/AccountPage/ClientAccountPage";
import ClientAccountPass from "containers/Client/AccountPage/ClientAccountPass";
import ClientAccountSavelists from "containers/Client/AccountPage/ClientAccountSavelists";

import OwnerAccountPage from "containers/Owner/AccountPage/OwnerAccountPage";
import OwnerAccountPass from "containers/Owner/AccountPage/OwnerAccountPass";

import LocationDetailPage from "containers/Locations/LocationDetailPage/location-detail/LocationDetailPage";

import OwnerLocationsPage from "containers/Owner/Locations/OwnerLocationsPage";
import OwnerReservationsPage from "containers/Owner/Reservations/OwnerReservationsPage";

import PaymentConfirmationPage from "containers/Payment/PaymentConfirmationPage";
import PaymentPage from "containers/Payment/PaymentPage";

import OwnerDetailPage from "containers/Owner/OwnersList/OwnerDetailPage";
import OwnersPage from "containers/Owner/OwnersList/OwnersPage";

import ClientReservationsPage from "containers/Client/Reservation/ClientReservationPage";
import LocationsPage from "containers/Locations/AllLocationsPage/LocationsPage";

import CategoryAnnouncementsPage from "containers/Admin/AnnounceCategory/CategoryAnnouncementsPage";
import AdminAllAnnounces from "containers/Admin/Announces/AdminAllAnnounces";
import AdminProfile from "containers/Admin/DashboardPage/AdminProfile";
import StatisticsPage from "containers/Admin/DashboardPage/StatisticsPage";

import AddEventPage from "containers/Admin/Events/AddEventPage";
import ListEventsPage from "containers/Admin/Events/ListEventsPage";
import UpdateEventPage from "containers/Admin/Events/UpdateEventPage";
import AddFieldPage from "containers/Admin/Field/AddFieldPage";
import LisFieldsPage from "containers/Admin/Field/ListFieldsPage";
import UpdateFieldPage from "containers/Admin/Field/UpdateFieldPage";

import LocationDetails from "containers/Admin/Locations/LocationDetailPage/location-detail/LocationDetails";

import ListTransactionAdminPage from "containers/Admin/Transactions/ListTransactionAdminPage";
import ListCanceledReservationByClientAdminPage from "containers/Admin/reservation/ListCanceledReservationByClientAdminPage";
import ListCanceledReservationByOwnerAdminPage from "containers/Admin/reservation/ListCanceledReservationByOwnerAdminPage";
import ListEnAttenteAdminPage from "containers/Admin/reservation/ListEnAttenteAdminPage";
import ListReservationAdminPage from "containers/Admin/reservation/ListReservationAdminPage";
import ListServicesPage from "containers/Admin/services/ListServicesPage";
import AddVideoPage from "containers/Admin/setting/Videos/AddVideoPage";
import ListVideosPage from "containers/Admin/setting/Videos/ListVideosPage";
import UpdateVideoPage from "containers/Admin/setting/Videos/UpdateVideoPage";
import AdminSendEmails from "containers/Admin/setting/emails/AdminSendEmails";
import AddVideoHomePage from "containers/Admin/setting/homeVideo/AddVideoHomePage";
import ListVideosHomePage from "containers/Admin/setting/homeVideo/ListVideosHomePage";
import UpdateVideoHomePage from "containers/Admin/setting/homeVideo/UpdateVideoHomePage";
import SliderPage from "containers/Admin/setting/slider";
import AdminStripe from "containers/Admin/setting/stripe/AdminStripe";
import ListConnectedAccounts from "containers/Admin/setting/stripe/ListConnectedAccounts";
import ListAgentsPage from "containers/Admin/users/agent/ListAgentsPage";
import ListClientsPage from "containers/Admin/users/client/ListClientsPage";
import ListCreatorsPage from "containers/Admin/users/creator/ListCreatorsPage";
import AccountAdd from "containers/Admin/users/creator/addCreator";
import AccountEdit from "containers/Admin/users/creator/editCreator";
import ListOwnersPage from "containers/Admin/users/owner/ListOwnersPage";
import AgentAccountPage from "containers/AgentService/AccountPage/AgentAccountPage";
import AgentAccountPass from "containers/AgentService/AccountPage/AgentAccountPass";
import AgentDeleteAccountPage from "containers/AgentService/AccountPage/AgentDeleteAccountPage";
import UpdateAccountPage from "containers/AgentService/AccountPage/updateAccountPage";
import ServiceCommonLayout from "containers/AgentService/Services/addService/ServiceCommonLayout";
import ServiceAgentServicesPage from "containers/AgentService/Services/listing/ServiceAgentServicesPage";
import PricingCard from "containers/AgentService/Services/pricing/PricingCard";
import PricingAgentServicesPage from "containers/AgentService/Services/pricing/pricingAgentServicesPage";
import UpdateServiceCommonLayout from "containers/AgentService/Services/updateService/UpdateServiceCommonLayout";
import AnnouncesPage from "containers/Announces/AllAnnounces/AnnouncesPage";
import AnnounceDetailPage from "containers/Announces/AnnounceDetailPage/announce-detail/AnnounceDetailPage";
import BlogByTagPage from "containers/BlogPage/BlogByTagPage";
import AccountServiceSavelists from "containers/Client/AccountPage/ClientAccountServicesSavelists";
import ClientAnnouncesList from "containers/Client/AccountPage/ClientAnnouncesList";
import ClientDeleteAccountPage from "containers/Client/AccountPage/ClientDeleteAccountPage";
import ClientUpdateAccountPage from "containers/Client/AccountPage/ClientUpdateAccountPage";
import ClientDashboard from "containers/Client/ClientDashboard";
import UncompletedLocations from "containers/Client/NonCompletedLocation/UncompletedLocations";
import ClientAffectedReservationPage from "containers/Client/Reservation/AffectedReservation/ClientAffectedReservationPage";
import ClientCanceledReservationPage from "containers/Client/Reservation/ClientCanceledReservationPage";
import ClientReservation from "containers/Client/Reservation/ClientReservation";
import ListPendingOwnerPage from "containers/Client/Reservation/ListPendingOwnerPage";
import ClientMadeReservationPage from "containers/Client/Reservation/MadeReservation/ClientMadeReservationPage";
import OwnerReservationPage from "containers/Client/Reservation/RservationByOwner/OwnerReservationPage";
import ReservationSearchResult from "containers/Client/Reservation/SearchReservation/ReservationSearchResult";
import ClientGainPage from "containers/Client/Stripe/ClientGainPage";
import ClientTransactionsPage from "containers/Client/Stripe/ClientTransactionsPage";
import ClientTransfertsPage from "containers/Client/Stripe/ClientTransfertsPage";
import ForgotPassword from "containers/ForgotPasswordPage/ForgotPassword";
import ResetPassword from "containers/ForgotPasswordPage/ResetPassword";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import OwnerDeleteAccountPage from "containers/Owner/AccountPage/OwnerDeleteAccountPage";
import AnnounceCommonLayout from "containers/Owner/Announce/AddUpdateAnnounce/AnnounceCommonLayout";
import LocationCommonLayout from "containers/Owner/Locations/AddUpdateLocations/LocationCommonLayout";
import Congrats from "containers/Owner/Locations/Congrats";
import AddingMessage from "containers/Owner/Locations/components/AddingMessage";
import OwnerCanceledReservationPage from "containers/Owner/Reservations/OwnerCanceledReservationPage";
import Cookies from "containers/PageAbout/Cookies";
import HowItWorks from "containers/PageAbout/HowItWorks";
import LegalNotice from "containers/PageAbout/LegalNotice/LegalNotice";
import Privacy from "containers/PageAbout/Privacy";
import Terms from "containers/PageAbout/Terms";
import { ServiceDetailPage } from "containers/Services/ServiceDetailPage";
import ServicesAll from "containers/Services/ServicesAllFieldPage";
import AddArticlePage from "containers/creator/AddArticlePage";
import AccountPassCreator from "containers/creator/DashboardPage/AccountPassCreator";
import UpdateArticlePage from "containers/creator/UpdateArticlePage";
import ListArticles from "containers/creator/listArticle";
import clientFaq from "containers/faq/client";
import Faq from "containers/faq/faq";
import propretaireFaq from "containers/faq/propriétaire";
import { useAppDispatch } from "hooks/hooks";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { currentUser } from "redux/features/auth/authSlice";
import {
  setFormData,
  setLoginCurrentStep,
  setVerificationMailCurrentStep,
} from "redux/features/signUp/signUpSlice";
import prestataireFaq from "../containers/faq/prestataire";
import { useAuth } from "./auth";
import RulesPage from "containers/Admin/Locations/settings/RulesPage";
import PlaygroundsPage from "containers/Admin/Locations/settings/PlaygroundsPage";
import AtmospheresPage from "containers/Admin/Locations/settings/AtmospheresPage";
import EquipmentsPage from "containers/Admin/Locations/settings/EquipmentsPage";
import CategoriesPage from "containers/Admin/Locations/settings/CategoriesPage";
import ReclamationsPage from "containers/Admin/Reclamation/ReclamationsPage";
import TentativeReservations from "containers/Admin/reservation/TentativeReservations";
import AdminAllLocationsPage from "containers/Admin/Locations/AdminAllLocationsPage";
import CouponsPage from "containers/Admin/setting/stripe/CouponsPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/*", exact: true, component: NotFoundPage },
  //

  { path: "/login", component: PageLogin },
  {
    path: "/faq",
    component: Faq,
  },
  {
    path: "/client/faq",
    component: clientFaq,
  },
  {
    path: "/prestataire/faq",
    component: prestataireFaq,
  },
  {
    path: "/cookies",
    component: Cookies,
  },
  {
    path: "/proprietaire/faq",
    component: propretaireFaq,
  },

  { path: "/signup", component: PageSignUp },
  { path: "/signup/:role", component: PageSignUp },
  //
  { path: "/blogs", component: BlogPage },
  { path: "/blogs/:tag", component: BlogByTagPage },
  { path: "/blog-single/:id", component: BlogSingle },

  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/howItWorks", component: HowItWorks },

  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/howItWorks", component: HowItWorks },
  { path: "/privacy-policy", component: Privacy },
  { path: "/terms", component: Terms },
  { path: "/legal-notice", component: LegalNotice },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },

  // service
  { path: "/prestations", component: ServicesAll },
  { path: "/prestations/field/:field", component: ServicesAll },

  { path: "/prestations/:id", component: ServiceDetailPage },

  // owners
  { path: "/owners", component: OwnersPage },
  { path: "/owners/:ownerId", component: OwnerDetailPage },

  // locations
  { path: "/locations/event/:event", component: LocationsPage },
  { path: "/locations", component: LocationsPage },
  // { path: "/annonces", component: AnnouncesPage },
  // { path: "/annonces/:slug", component: AnnounceDetailPage },

  { path: "/location/:id", component: LocationDetailPage },
  { path: "/add/:addUrl", component: AddingMessage },

  // payment
  { path: "/payment", component: PaymentPage },
  {
    path: "/payment/confirmation",
    component: PaymentConfirmationPage,
  },
  {
    path: "/canceled-reservations",
    component: ClientCanceledReservationPage,
  },
  // owner profil
];

const PublicRoute = ({ element }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentUser());
  }, [location]);

  return element;
};

const PrivateRoute = ({ element, requiredRole, requiredType }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(currentUser());
  }, [location]);

  const { isAuthenticated, isEmailVirefied, isProfil, getUser, getUserRole, getUserType } = useAuth();

  const type = getUserType();

  if (
    isAuthenticated() &&
    getUserRole() === requiredRole &&
    (requiredType === undefined || requiredType.includes(type))
  ) {
    if (!isEmailVirefied() && getUserRole() !== "admin" && getUserRole() !== "creator") {
      dispatch(setVerificationMailCurrentStep());
      dispatch(
        setFormData({
          login: getUser()?.login,
          email: getUser()?.email,
          role: getUser()?.role,
        })
      );
      if (getUserRole() === "client") {
        return navigate("/signup/client");
      } else {
        return navigate("/signup/service");
      }
    } else if (!isProfil() && getUserRole() !== "admin" && getUserRole() !== "creator") {
      dispatch(setLoginCurrentStep());
      dispatch(
        setFormData({
          login: getUser()?.login,
          email: getUser()?.email,
          role: getUser()?.role,
        })
      );
      if (getUserRole() === "client") {
        return navigate("/signup/client");
      } else {
        return navigate("/signup/service");
      }
    } else {
      return element;
    }
  } else if (isAuthenticated() && getUserType() !== requiredType) {
    return <Navigate to="/" state={{ form: location }} />;
  }

  return <Navigate to="/login" state={{ form: location }} />;
};

const privatePages = [
  // Agent routes
  { path: "/prestataire", component: AgentAccountPage, requiredRole: "service" },
  {
    path: "/prestataire/services-list",
    component: ServiceAgentServicesPage,
    requiredRole: "service",
  },
  {
    path: "/prestataire/pricing",
    component: PricingAgentServicesPage,
    requiredRole: "service",
  },
  {
    path: "/pricing-details/:pricingId",
    component: PricingCard,
    requiredRole: "service",
  },
  {
    path: "/agent-password",
    component: AgentAccountPass,
    requiredRole: "service",
  },
  {
    path: "/prestataire",
    component: AgentDeleteAccountPage,
    requiredRole: "service",
  },
  {
    path: "/add-service",
    component: ServiceCommonLayout,
    requiredRole: "service",
  },
  {
    path: "/update-service/:id",
    component: UpdateServiceCommonLayout,
    requiredRole: "service",
  },
  {
    path: "/update-profile-service/",
    component: UpdateAccountPage,
    requiredRole: "service",
  },
  // Owner routes
  {
    path: "/owner",
    component: OwnerAccountPage,
    requiredRole: "owner",
  },
  {
    path: "/add-location",
    component: LocationCommonLayout,
    requiredRole: "client",
  },
  {
    path: "/add-announce",
    component: AnnounceCommonLayout,
    requiredRole: "client",
  },
  {
    path: "/owner-password",
    component: OwnerAccountPass,
    requiredRole: "owner",
  },
  {
    path: "/owner-delete/:userId",
    component: OwnerDeleteAccountPage,
    requiredRole: "owner",
  },
  {
    path: "/update-location/:id",
    component: LocationCommonLayout,
    requiredRole: "client",
  },
  {
    path: "/update-announce/:id",
    component: AnnounceCommonLayout,
    requiredRole: "client",
  },
  {
    path: "/client/locations",
    component: OwnerLocationsPage,
    requiredRole: "client",
    requiredType: "approuvé",
  },
  {
    path: "/owner/reservations",
    component: OwnerReservationsPage,
    requiredRole: "owner",
  },
  {
    path: "/owner/canceled",
    component: OwnerCanceledReservationPage,
    requiredRole: "owner",
  },

  {
    path: "/client/listreservations",
    component: ClientReservation,
    requiredRole: "client",
  },
  {
    path: "/client/list-reservations",
    component: ClientAffectedReservationPage,
    requiredRole: "client",
    requiredType: "approuvé",
  },
  {
    path: "/client/list-pending-reservations",
    component: ListPendingOwnerPage,
    requiredRole: "client",
    requiredType: "approuvé",
  },
  {
    path: "/client/bloqued-reservations",
    component: OwnerReservationPage,
    requiredRole: "client",
    requiredType: "approuvé",
  },
  {
    path: "/client/search",
    component: ReservationSearchResult,
    requiredRole: "client",
  },
  {
    path: "/client/my-reservations",
    component: ClientMadeReservationPage,
    requiredRole: "client",
    requiredType: "approuvé",
  },
  {
    path: "/update-profile-client/",
    component: ClientUpdateAccountPage,
    requiredRole: "client",
  },
  {
    path: "/client-password",
    component: ClientAccountPass,
    requiredRole: "client",
  },
  {
    path: "/client/dashboard",
    component: ClientDashboard,
    requiredRole: "client",
  },

  {
    path: "/client/transfers",
    component: ClientTransfertsPage,
    requiredRole: "client",
  },
  {
    path: "/client/transfers-gain",
    component: ClientGainPage,
    requiredRole: "client",
  },
  {
    path: "/client/transactions",
    component: ClientTransactionsPage,
    requiredRole: "client",
  },
  {
    path: "/client-savelists",
    component: ClientAccountSavelists,
    requiredRole: "client",
  },
  {
    path: "/client/announces",
    component: ClientAnnouncesList,
    requiredRole: "client",
  },
  {
    path: "/client-serviceslist",
    component: AccountServiceSavelists,
    requiredRole: "client",
  },
  {
    path: "/client-reservations",
    component: ClientReservationsPage,
    requiredRole: "client",
    requiredType: "" || "en cours",
  },
  {
    path: "/client/location/completed/:id",
    component: Congrats,
    requiredRole: "client",
  },
  {
    path: "/client-delete/",
    component: ClientDeleteAccountPage,
    requiredRole: "client",
  },
  { path: "/payment", component: PaymentPage, requiredRole: "client" },
  {
    path: "/payment/confirmation",
    component: PaymentConfirmationPage,
    requiredRole: "client",
  },
  { path: "/client", component: ClientAccountPage, requiredRole: "client" },
  {
    path: "/client/demande",
    component: UncompletedLocations,
    requiredRole: "client",
    requiredType: "en cours",
  },
  // Creator routes
  {
    path: "/dashboard-creator",
    component: ListArticles,
    requiredRole: "creator",
  },
  {
    path: "/admin/dashboard/locations/approuved",
    component: AdminAllLocationsPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/locations/incompleted",
    component: AdminAllLocationsPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/locations/unapproved",
    component: AdminAllLocationsPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/add-article",
    component: AddArticlePage,
    requiredRole: "creator",
  },
  {
    path: "/dashboard/update-article/:id",
    component: UpdateArticlePage,
    requiredRole: "creator",
  },
  {
    path: "/dashboard/setting",
    component: AccountPassCreator,
    requiredRole: "creator",
  },
  // Admin routes
  { path: "/dashboard", component: AdminProfile, requiredRole: "admin" },
  // statistiques
  {
    path: "/admin/dashboard/statistiques/:user",
    component: StatisticsPage,
    requiredRole: "admin",
  },
  // {
  //   path: "/admin/dashboard/annonces/unapproved",
  //   component: AdminAllAnnounces,
  //   requiredRole: "admin",
  // },
  // {
  //   path: "/admin/dashboard/annonces/approuved",
  //   component: AdminAllAnnounces,
  //   requiredRole: "admin",
  // },
  // {
  //   path: "/admin/dashboard/annonces/categories",
  //   component: CategoryAnnouncementsPage,
  //   requiredRole: "admin",
  // },
  // client list
  {
    path: "/admin/dashboard/users/clients",
    component: ListClientsPage,
    requiredRole: "admin",
  },
  // service agent list
  {
    path: "/admin/dashboard/users/agents",
    component: ListAgentsPage,
    requiredRole: "admin",
  },
  // service agent list

  {
    path: "/admin/dashboard/stripe/transactions",
    component: ListTransactionAdminPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/reclamations",
    component: ReclamationsPage,
    requiredRole: "admin",
  },
  // owners list
  {
    path: "/admin/dashboard/users/owners",
    component: ListOwnersPage,
    requiredRole: "admin",
  },
  { path: "/dashboard/location/:id", component: LocationDetails, requiredRole: "admin" },
  { path: "/admin/dashboard/settings/slider", component: SliderPage, requiredRole: "admin" },
  { path: "/admin/dashboard/settings/videos", component: ListVideosPage, requiredRole: "admin" },
  { path: "/dashboard/add-homevideos", component: AddVideoHomePage, requiredRole: "admin" },
  { path: "/admin/dashboard/settings/home-videos", component: ListVideosHomePage, requiredRole: "admin" },
  { path: "/admin/dashboard/settings/emails", component: AdminSendEmails, requiredRole: "admin" },
  { path: "/admin/dashboard/settings/stripe-connect", component: AdminStripe, requiredRole: "admin" },
  { path: "/admin/dashboard/stripe/connected-accounts", component: ListConnectedAccounts, requiredRole: "admin" },
  { path: "/admin/dashboard/stripe/coupons", component: CouponsPage, requiredRole: "admin" },

  { path: "/dashboard/edit-homevideos/:id", component: UpdateVideoHomePage, requiredRole: "admin" },
  { path: "/dashboard/edit-videos/:id", component: UpdateVideoPage, requiredRole: "admin" },
  {
    path: "/dashboard/add-videos",
    component: AddVideoPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/locations/equipments",
    component: EquipmentsPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/reservations/canceled/client",
    component: ListCanceledReservationByClientAdminPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/reservations/tentatives",
    component: TentativeReservations,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/reservations/canceled/owner",
    component: ListCanceledReservationByOwnerAdminPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/reservations/pending",
    component: ListEnAttenteAdminPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/reservations/confirmed",
    component: ListReservationAdminPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/locations/events",
    component: ListEventsPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/add-events",
    component: AddEventPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/edit-events/:id",
    component: UpdateEventPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/locations/categories",
    component: CategoriesPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/locations/atmospheres",
    component: AtmospheresPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/locations/playgrounds",
    component: PlaygroundsPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/prestations",
    component: ListServicesPage,
    requiredRole: "admin",
  },

  {
    path: "/admin/dashboard/prestations/fields",
    component: LisFieldsPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/add-fields",
    component: AddFieldPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/edit-field/:id",
    component: UpdateFieldPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/add-fields",
    component: AddFieldPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/edit-field/:id",
    component: UpdateFieldPage,
    requiredRole: "admin",
  },
  {
    path: "/admin/dashboard/users/creators",
    component: ListCreatorsPage,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/add-creator",
    component: AccountAdd,
    requiredRole: "admin",
  },
  {
    path: "/dashboard/edit-creator/:id",
    component: AccountEdit,
    requiredRole: "admin",
  },

  { path: "/admin/dashboard/locations/rules", component: RulesPage, requiredRole: "admin" },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <Toaster position="top-right" reverseOrder={false} />

      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<PublicRoute element={<Component />} />} path={path} />;
        })}

        {privatePages.map(({ component, path, requiredRole, requiredType }) => {
          const Component = component;
          return (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute element={<Component />} requiredRole={requiredRole} requiredType={requiredType} />
              }
            />
          );
        })}

        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
