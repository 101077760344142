import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAtmosphere } from "./featureAPI";

const initialState = {
  designation: "",
  loading: "idle",
  atmospheres: [],
  atmosphere: { designation: "" },
  totalPages: 0,
};

export const addAtmosphere = createAsyncThunk("featureAtmosphere/addAtmosphere", async (data: any) => {
  return await createAtmosphere.add(data);
});

export const fetchAtmospheres = createAsyncThunk("featureAtmosphere/fetchAtmosphere", async () => {
  return await createAtmosphere.verify();
});
export const editAtmosphere = createAsyncThunk(
  "featureAtmosphere/editAtmosphere",
  async ({ data, id }: { data: any; id: any }) => {
    return await createAtmosphere.edit(data, id);
  }
);
export const deleteAtmosphere = createAsyncThunk("featureAtmosphere/deleteAtmosphere", async (id: number) => {
  return await createAtmosphere.delete(id);
});
export const getAtmosphereById = createAsyncThunk("featureAtmosphere/getAtmosphereById", async (id: any) => {
  return await createAtmosphere.getById(id);
});

export const adminGetAllAtmospheres = createAsyncThunk(
  "adminGetAllAtmospheres",
  async ({ page, designation }: { page: number; designation: string }) => {
    return await createAtmosphere.adminGetAllAtmospheres(page, designation);
  }
);

const createAtmosphereSlice = createSlice({
  name: "featureAtmosphere",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addAtmosphere.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addAtmosphere.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addAtmosphere.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(editAtmosphere.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editAtmosphere.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(editAtmosphere.rejected, (state) => {
        state.loading = "failed";
      });
    builder.addCase(fetchAtmospheres.fulfilled, (state, action) => {
      state.atmospheres = action.payload;
    });
    builder.addCase(adminGetAllAtmospheres.fulfilled, (state, action) => {
      state.atmospheres = action.payload.atmospheres;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getAtmosphereById.fulfilled, (state, action) => {
      state.atmosphere = action.payload;
    });
    builder
      .addCase(deleteAtmosphere.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteAtmosphere.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteAtmosphere.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createAtmosphereSlice.actions;
export default createAtmosphereSlice.reducer;
