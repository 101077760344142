import { FC, Fragment, HTMLAttributes, useEffect } from "react";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";

import { Square3Stack3DIcon, TagIcon, UsersIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnnounceFormProps } from "data/reduxInterfaces";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { listAnnounceCategories } from "redux/features/Admin/categorys/categorySlice";
import Select from "shared/Select/Select";
import { z } from "zod";

interface Category {
  id: number;
  designation: any;
}

const formSchema = z.object({
  categoryId: z.number({
    required_error: "Le category est obligatoire.",
    invalid_type_error: "Le category doit étre un nombre.",
  }),
  price: z.number({
    required_error: "Le prix est obligatoire.",
    invalid_type_error: "Le prix doit étre un nombre.",
  }),
  title: z.string().min(1, { message: "Le titre du l'annonce est obligatoire." }),
});

const AnnounceProfile: FC<AnnounceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryId: data?.categoryId ?? undefined,
      title: data?.title ?? undefined,
      price: data?.price ? Number(data?.price) : undefined,
    },
    mode: "onSubmit",
  });

  const categorys: Category[] = useAppSelector((state) => state.featureCategory.announcesCategories);

  useEffect(() => {
    dispatch(listAnnounceCategories());
  }, []);

  useEffect(() => {
    if (data) {
      setValue("categoryId", data.categoryId);
      setValue("title", data.title);
      setValue("price", data.price ? Number(data.price) : 0);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormOne") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <h2 className="mt-5 text-2xl font-semibold">Ajouter vos Informations</h2>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="my-5 space-y-8">
            <FormItem label="Choisir une catégorie *">
              <div className="md:w-1/2">
                <Select
                  className={`mt-1.5 ${errors.categoryId?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  value={getValues("categoryId")}
                  onChange={(e) => setValue("categoryId", Number(e.target.value))}
                >
                  <option value={""}>Sélectionner une categorie...</option>
                  {categorys.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.designation}
                    </option>
                  ))}
                </Select>
                {errors.categoryId?.message && (
                  <div className="text-xs text-red-500">{`${errors.categoryId?.message}`}</div>
                )}
              </div>
            </FormItem>

            <FormItem label="Titre du l'annonce *" className="mt-5">
              <div className="mt-1 md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.title?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  placeholder="Titre du l'annonce"
                  {...register("title")}
                />
              </div>
              {errors.title?.message && <div className="text-xs text-red-500">{`${errors.title?.message}`}</div>}
            </FormItem>

            <FormItem label="Prix *" className="mt-1.5">
              <div className="md:w-1/2">
                <Input
                  className={`mt-1.5 ${errors.price?.message ? "border-red-500" : ""}`}
                  placeholder="Unité en €"
                  type="number"
                  disabled={isLoading}
                  {...register("price", { valueAsNumber: true })}
                />
                {errors.price?.message && <div className="text-xs text-red-500">{`${errors.price?.message}`}</div>}
              </div>
            </FormItem>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AnnounceProfile;
