import { zodResolver } from "@hookform/resolvers/zod";
import Label from "components/Label/Label";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaRegWindowClose } from "react-icons/fa";
import { adminGetUnapprovedLocations, resetValues, validateLocation } from "redux/features/location/locationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import { z } from "zod";

import { socket } from "./../../../utils/socket";
import { createBankAccountToken, createCompanyStripeToken, createParticularStripeToken } from "hooks/stripeHooks";

const formSchema = z
  .object({
    validation: z.string(),
    reason: z.string().optional(),
  })
  .refine(
    (obj) => {
      if (obj.validation === "bloqué") {
        return obj.reason != null && obj.reason !== "";
      }
      return true;
    },
    {
      message: "Raison est obligatoire pour demande bloqué",
      path: ["reason"],
    }
  );
interface UpdateModalProps {
  onClose: (value: boolean) => void;
  id?: any;
  title?: any;
  locationData?: any;
}
const ValidateLocationsModal: React.FC<UpdateModalProps> = ({ onClose, id, title, locationData }) => {
  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (id) {
      let token;
      let bank_token;

      if (locationData?.Client?.account_type === "particular") {
        setIsLoading(true);
        token = await createParticularStripeToken(locationData);
      } else {
        setIsLoading(true);
        token = await createCompanyStripeToken(locationData);
      }
      const fullName = locationData?.Client?.name + " " + locationData?.Client?.lastName;
      if (token && locationData?.Client?.account_type === "particular") {
        setIsLoading(true);
        bank_token = await createBankAccountToken(fullName, "individual", locationData?.Client?.rib);
      } else if (token && locationData?.Client?.account_type === "company") {
        setIsLoading(true);
        bank_token = await createBankAccountToken(
          locationData?.Client?.companyName,
          "company",
          locationData?.Client?.rib
        );
      }

      const result = await dispatch(
        validateLocation({
          data,
          id: id,
          stripeToken: token ?? undefined,
          stripeBankToken: bank_token ?? undefined,
        })
      ).then(() => {
        setOpen(false);
        onClose(open);
        dispatch(adminGetUnapprovedLocations({ page: 1, title: "" }));
        dispatch(resetValues());

        switch (data.validation) {
          case "approuvé":
            socket.emit("send-notification", {
              receiverId: locationData?.Client?.userId,
              action: "confirm_owner_espace",
              locationId: locationData?.id,
              location: locationData,
            });
            break;
          case "bloqué":
            socket.emit("send-notification", {
              receiverId: locationData?.Client?.userId,
              action: "negate_owner_espace",
              locationId: locationData?.id,
              location: locationData,
              reason: data.reason,
            });
            break;

          default:
            break;
        }
      });
    }
  };

  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose(open);
  };
  const phoneInputStyle = {
    borderRadius: "0.375rem",
    backgroundColor: "#FFFFFF",
  };

  const locationState = useAppSelector((state) => state.location);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    switch (locationState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [locationState.loading]);

  const [text, setText] = useState("");

  const handleGetValidation = (value: string) => {
    setText(value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col  h-[30rem]">
        <div className="self-end mr-5">
          <FaRegWindowClose className="text-xl cursor-pointer" onClick={handleClose} />
        </div>
        <h2 className="sticky top-0 p-4 text-2xl font-extrabold text-center border-b border-grey">
          Validation du l'espace : {locationData.title}
        </h2>
        <main className="flex-1">
          <div className="mt-3">
            <Label className="mt-5">Validation </Label>
          </div>
          <Select
            className={`mt-1.5 ${errors.validation?.message ? "border-red-500" : ""}`}
            placeholder="Choix de ville"
            value={getValues("validation")}
            onChange={(e) => {
              setValue("validation", e.target.value);
              handleGetValidation(e.target.value);
            }}
          >
            <option value={""}>Selectionner</option>
            <option value={"en cours"}>En Cours</option>
            <option value={"approuvé"}>Approuvé</option>
            <option value={"bloqué"}>Bloqué</option>
          </Select>

          {watch("validation") === "bloqué" && (
            <>
              <div className="mt-3">
                <Label className="mt-5">Raison *</Label>
              </div>
              <Textarea className="mt-5" {...register("reason")} />
            </>
          )}

          {errors.reason?.message && <div className="text-xs text-red-500">{errors.reason?.message}</div>}
          <div className=""></div>
        </main>
        <ButtonPrimary
          className="w-full px-4 py-2 text-white bg-indigo-600 rounded"
          type="submit"
          loading={isLoading}
        >
          Mise à jour
        </ButtonPrimary>
      </div>
    </form>
  );
};
export default ValidateLocationsModal;
