import * as React from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import classes from "./NotificationModal.module.css";

const SnackbarComponent = ({ open, message, sender }) => {
  React.useEffect(() => {
    if (open) {
      const toastId = toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <IoNotificationsCircleSharp
                    className="w-10 h-10"
                    style={{ color: "white", backgroundColor: "#4663AC", borderRadius: "50%" }}
                  />
                </div>
                <div className="flex-1 ml-3">
                  <p className="text-sm font-medium text-gray-900">{sender}</p>
                  <p className="mt-1 text-sm text-gray-500">{message || "Invalid message"}</p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(toastId)}
                className="flex items-center justify-center w-full p-4 text-sm font-medium text-indigo-600 border border-transparent rounded-none rounded-r-lg hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                X
              </button>
            </div>
          </div>
        ),
        {
          position: "bottom-left",
          duration: 5000,
        }
      );

      const timer = setTimeout(() => {
        toast.dismiss(toastId);
      }, 5000);

      return () => {
        clearTimeout(timer);
        toast.dismiss(toastId);
      };
    }
  }, [open, message, sender]);

  return null;
};
const Backdrop = ({ onHideModal }) => <div onClick={onHideModal} className={classes.backdrop}></div>;

const NotificationModal = (props) => {
  const onHideModal = () => {
    document.getElementById("backdrop-root")?.remove();
    document.getElementById("overlay-root")?.remove();
    props.onHideModal();
  };

  return (
    <>
      {ReactDOM.createPortal(<Backdrop onHideModal={onHideModal} />, document.getElementById("backdrop-root"))}
      {ReactDOM.createPortal(
        <SnackbarComponent open={props.state} message={props.message} sender={props.state} />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default NotificationModal;
