import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import SelectField from "./SelectField";
import LocationInput from "containers/Locations/AllLocationsPage/LocationInput";
import InputField from "./InputField";

interface SearchFormProps {
  fields: {
    region?: boolean;
    event?: boolean;
    field?: boolean;
  };
  onSelect?: (field: string, value: string) => void;
  onSelectField?: (field: string) => void;
  searchClicked?: boolean;
  handleSearchClick: () => void;
  handleDelete?: () => void;
  propEventValue?: string;
  propFieldValue?: string;
  propRegionValue?: string;
}

const SearchForm: FC<SearchFormProps> = ({
  fields,
  onSelectField,
  onSelect,
  searchClicked,
  handleSearchClick,
  handleDelete,
  propEventValue,
  propRegionValue,
  propFieldValue,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryRegion = searchParams.get("region");
  const queryEvent = searchParams.get("event");
  const queryField = searchParams.get("field");

  const [regionCleared, setRegionCleared] = useState(false);
  const [eventCleared, setEventCleared] = useState(false);
  const [fieldCleared, setFieldCleared] = useState(false);

  const handleSelectEvent = (event: string) => {
    onSelect?.("event", event);
    setEventCleared(false);
  };
  const handleSelectRegion = (region: string) => {
    onSelect?.("region", region);
    setRegionCleared(false);
  };
  const handleSelectField = (field: string) => {
    onSelectField?.(field);
    setFieldCleared(false);
  };

  const clearEvent = () => setEventCleared(true);
  const clearRegion = () => setRegionCleared(true);
  const clearField = () => setFieldCleared(true);

  return (
    <form className="relative flex flex-col w-full -mt-8 bg-white divide-y shadow-xl xl: lg:flex-row lg:items-center rounded-3xl lg:rounded-full dark:shadow-2xl dark:bg-neutral-800 divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">
      {fields.region && location.pathname === "/prestations" && (
        <LocationInput
          propRegionValue={queryRegion || propRegionValue}
          onSelectRegion={handleSelectRegion}
          clear={regionCleared}
          className="flex-[1]"
        />
      )}

      {fields.region && location.pathname === "/locations" && (
        <InputField
          propEventValue={queryRegion || propRegionValue}
          onSelectEvent={handleSelectRegion}
          clear={regionCleared}
          name="region"
          placeHolder="Région"
          desc="Saisit votre région"
        />
      )}
      {fields.event && (
        <>
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <SelectField
            type="event"
            placeHolder="Evènement"
            desc="Quels types d'événement ?"
            clear={eventCleared}
            propItemValue={queryEvent || propEventValue}
            onSelectItem={handleSelectEvent}
            icon={<i className="text-3xl las la-glass-cheers"></i>}
          />
        </>
      )}

      {fields.field && (
        <>
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <SelectField
            type="field"
            placeHolder="Type de prestation"
            desc="Quels types de prestation ?"
            clear={fieldCleared}
            propItemValue={queryField || propFieldValue}
            onSelectItem={handleSelectField}
            icon={<i className="text-3xl las la-user-tie"></i>}
          />
        </>
      )}

      <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>

      <div className="flex [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none">
        <ButtonCircle
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (handleDelete) {
              handleDelete();
            }
            clearEvent();
            clearRegion();
            clearField();
          }}
        >
          <i className="las la-times"></i>
        </ButtonCircle>
        <ButtonCircle type="button" className="btn btn-primary" onClick={handleSearchClick}>
          <i className="las la-search"></i>
        </ButtonCircle>
      </div>
    </form>
  );
};

export default SearchForm;
