import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addLocation,
  getLocationAllDataById,
  initStates,
  nextStep,
  previousStep,
  setLocationData,
  updateLocation,
} from "redux/features/location/locationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import LocationAccessibility from "../components/LocationAccessibility";
import LocationAddress from "../components/LocationAddress";
import LocationAvailibility from "../components/LocationAvailibility";
import LocationDescription from "../components/LocationDescription";
import LocationEvent from "../components/LocationEvent";
import LocationImages from "../components/LocationImages";
import LocationPlayground from "../components/LocationPlayground";
import LocationProfile from "../components/LocationProfile";
import toast from "react-hot-toast";

const LocationCommonLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const isLactionUpadate = location.pathname.includes("update-location");

  const authContext = useContext(AuthContext);
  const profile = authContext.user?.profil as ProfilClient;

  const locationState = useAppSelector((state) => state.location);
  const progress = (locationState.currentStep / locationState.totalSteps) * 100;
  const lastStep = locationState.currentStep === locationState.totalSteps;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const [event, setEvent] = useState<Event>(new Event(""));

  useEffect(() => {
    window.scrollTo(0, 0);
    load();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationState.currentStep]);

  useEffect(() => {
    switch (locationState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        if (isLactionUpadate) {
          if (isNextStep) {
            navigate(`/client/locations/`);
          } else {
            setIsLoading(false);
          }
        } else {
          if (locationState.locationData?.id !== null) {
            navigate(`/client/location/completed/${locationState.locationData?.id}`);
          } else if (profile.validation === "approuvé") {
            navigate(`/client/locations/`);
          } else {
            navigate(`/client/demande`);
          }
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        setIsSubmited(false);
        break;
      }
    }
  }, [locationState.loading]);

  useEffect(() => {
    handleData();
  }, [locationState.locationData]);

  useEffect(() => {
    if (locationState.locationError) {
      toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
    }
  }, [locationState.locationError]);

  useEffect(() => {
    locationState.locationErrorValidation?.forEach((element) => {
      toast.error(`Il y a une erreur du : [${element.path}] \n ${element.msg}`);
    });
  }, [locationState.locationErrorValidation]);

  useEffect(() => {
    if (locationState.locationNotExist) {
      navigate("/404");
    }
  }, [locationState.locationNotExist]);

  const load = async () => {
    await dispatch(initStates());

    if (isLactionUpadate) {
      if (id) {
        dispatch(getLocationAllDataById(id));
      } else {
        navigate("/404");
      }
    }
  };

  const handleNextClick = () => {
    setIsNextStep(true);
    switch (locationState.currentStep) {
      case 1: {
        setEvent(new Event("chekLocationFormOne"));
        break;
      }
      case 2: {
        setEvent(new Event("chekLocationFormTwo"));
        break;
      }
      case 3: {
        setEvent(new Event("chekLocationFormThree"));
        break;
      }
      case 4: {
        setEvent(new Event("chekLocationFormFour"));
        break;
      }
      case 5: {
        setEvent(new Event("chekLocationFormFive"));
        break;
      }
      case 6: {
        setEvent(new Event("chekLocationFormSix"));
        break;
      }
      case 7: {
        setEvent(new Event("chekLocationFormSeven"));
        break;
      }
      case 8: {
        setEvent(new Event("chekLocationFormEight"));
        break;
      }
      default: {
        setEvent(new Event(""));
        break;
      }
    }
  };

  const handlePreviousClick = () => {
    setIsNextStep(false);
    dispatch(previousStep());
  };

  const handleData = async () => {
    if (locationState.locationData) {
      if (lastStep && !isSubmited) {
        setIsSubmited(true);
        if (isLactionUpadate) {
          dispatch(updateLocation({ data: locationState.locationData, id }));
        } else {
          dispatch(addLocation(locationState.locationData));
        }

        return;
      }

      if (isNextStep && !lastStep) {
        dispatch(nextStep());
        setIsLoading(false);
      }
    }
  };

  const handleSaveAndExit = async () => {
    if (lastStep) {
      setIsSubmited(true);
      setIsLoading(true);

      await dispatch(setLocationData({ ...locationState.locationData, validation: "brouillon" }));

      dispatch(addLocation(locationState.locationData));
    }
  };

  return (
    <div
      className={`pr-4 pb-24 pl-4 mx-auto nc-PageAddListing1 lg:pl-10 lg:pr-32 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="mt-10 space-y-6">
        <div className="flex justify-between">
          <p className="mt-5">
            Bonjour Mr/Mme :
            <span className="pl-4 font-bold text-black dark:text-white">
              {profile?.name} {profile?.lastName}
            </span>
          </p>
          {!isLactionUpadate && (
            <div className="px-3">
              <button
                onClick={handleSaveAndExit}
                disabled={!lastStep}
                className="items-center px-4 py-2 text-sm font-medium text-gray-700 border rounded-full disabled:cursor-not-allowed disabled:bg-slate-100 text-opacity-90 xl:inline-flex group border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 dark:text-neutral-300 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                Enregistrer et Quitter
              </button>
            </div>
          )}
        </div>

        {locationState.currentStep === 1 && (
          <LocationProfile
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 2 && (
          <LocationAddress
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 3 && (
          <LocationEvent
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 4 && (
          <LocationPlayground
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 5 && (
          <LocationAccessibility
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 6 && (
          <LocationAvailibility
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 7 && (
          <LocationDescription
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        {locationState.currentStep === 8 && (
          <LocationImages
            isLoading={isLoading}
            handleForm={event}
            data={locationState.locationData}
            FormData={(data) => dispatch(setLocationData({ ...locationState.locationData, ...data }))}
          />
        )}

        <div className="flex flex-col items-center justify-center space-y-3">
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-blue-600 h-2.5 rounded-full w-45" style={{ width: `${progress}%` }}></div>
          </div>

          <div className="flex items-center justify-between w-full">
            <ButtonSecondary onClick={handlePreviousClick} disabled={locationState.currentStep <= 1}>
              Retour
            </ButtonSecondary>

            <p className="text-lg font-semibold">
              {`Étape ${locationState.currentStep}/${locationState.totalSteps}`}
            </p>

            <div className="space-x-5">
              <ButtonPrimary onClick={handleNextClick} loading={isLoading} disabled={isLoading}>
                Continuer
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCommonLayout;
