import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContactApi } from "./contactAPI";

interface ContactState {
  loading?: "idle" | "pending" | "succeeded" | "failed";
  senderNomPrenom?: string;
  senderMail?: string;
  senderMessage?: string;
  contactError?: string;
  error?: SerializedError | "";
  response?: any;
  contacts?: any;
  totalPages?: number;
  formData?: any;
}
const initialState: ContactState = {
  loading: "idle",
};

export const createContactRequest = createAsyncThunk("createContactRequest", async (data: any) => {
  return await ContactApi.createContactRequest(data);
});

export const adminGetAllContacts = createAsyncThunk(
  "adminGetAllContacts",
  async ({ page, email }: { page: number; email: string }) => {
    return await ContactApi.adminGetAllContacts(page, email);
  }
);

export const deleteContact = createAsyncThunk("deleteContacts", async (id: number) => {
  return await ContactApi.deleteContact(id);
});

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    initState(state) {
      state = { formData: undefined, error: "", loading: "idle" };
    },

    resetValues(state) {
      state.error = "";
      state.loading = "idle";
      state.formData = undefined;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Create contact (reclamation)
    builder.addCase(createContactRequest.pending, (state, action) => {
      state.loading = "pending";
      state.contactError = "";
    });
    builder.addCase(createContactRequest.rejected, (state, action) => {
      state.loading = "failed";
      state.response = action.payload;
    });
    builder.addCase(createContactRequest.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.response = action.payload.data;
    });
    // get all contacts (reclamations)
    builder.addCase(adminGetAllContacts.pending, (state, action) => {
      state.loading = "pending";
      state.contactError = "";
    });
    builder.addCase(adminGetAllContacts.rejected, (state, action) => {
      state.loading = "failed";
      state.response = action.payload;
    });
    builder.addCase(adminGetAllContacts.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.response = action.payload.contacts;
      state.totalPages = action.payload.totalPages;
    });

    builder.addCase(deleteContact.pending, (state, action) => {
      state.loading = "pending";
      state.contactError = "";
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.loading = "failed";
      state.response = action.payload;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.response = action.payload;
    });
  },
});

export const { initState, setError, resetValues } = contactSlice.actions;
export default contactSlice.reducer;
