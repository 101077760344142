import { Tab } from "@headlessui/react";
import { Spinner } from "@material-tailwind/react";
import AnnounceCard from "components/CarCard/AnnounceCard";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useContext, useEffect, useState } from "react";
import { getAnnouncesByUserId } from "redux/features/announce/announceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Pagination from "shared/Pagination/Pagination";
import CommonLayout from "./CommonLayout";

const ClientAnnouncesList = () => {
  const [page, setPage] = useState(1);
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(getAnnouncesByUserId(page)).then(() => setIsLoading(false));
    }
  }, [profilId, page]);
  const announceState = useAppSelector((state) => state.announce.allAnnounces || []);
  const totalPages = useAppSelector((state) => state.announce?.totalPages);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div className="flex justify-between">
          <h2 className="text-3xl font-semibold dark:text-white">Mes annonces</h2>
          <ButtonPrimary href={"/add-announce"}> Déposer annonce </ButtonPrimary>
        </div>
        <span className="text-base">{announceState.length} annonces</span>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {isLoading ? (
          <div className="flex items-center justify-center mt-16">
            <p>
              <Spinner color="blue" className="w-12 h-12" />
            </p>
          </div>
        ) : (
          <div>
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="mt-8">
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {announceState?.map((item: any) => {
                      return <AnnounceCard key={item.id} data={item} featuredImage={item?.images} />;
                    })}
                  </div>

                  <div className="flex items-center justify-center mt-11">
                    {typeof totalPages === "number" && (
                      <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            {announceState.length === 0 && (
              <div className="flex items-center justify-center">
                <h2 className="text-xl ">Aucun annonce</h2>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default ClientAnnouncesList;
