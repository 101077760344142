import { CalendarDaysIcon, ClockIcon, CurrencyEuroIcon } from "@heroicons/react/24/solid";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppSelector } from "hooks/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { z } from "zod";
import FormItem from "./FormItem";
import { Checkbox, Typography } from "@material-tailwind/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { watch } from "fs";

const daysOfWeek = [
  { value: "1", label: "Lundi" },
  { value: "2", label: "Mardi" },
  { value: "3", label: "Mercredi" },
  { value: "4", label: "Jeudi" },
  { value: "5", label: "Vendredi" },
  { value: "6", label: "Samedi" },
  { value: "0", label: "Dimanche" },
];

const startHourOptions = [
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const formSchema = z.object({
  prices: z.array(
    z.object({
      price: z.number(),
      min: z.number(),
      max: z.number(),
    }),
    { required_error: "Les prix horaires sont obligatoires" }
  ),
  cleaning_costs: z.number().optional(),
  guarantee: z.number({
    required_error: "Le montant du dépôt de garantie est obligatoire.",
    invalid_type_error: "Le montant du dépôt de garantie doit étre un nombre.",
  }),
  startHour: z
    .string({
      required_error: "L'heure de début est obligatoire.",
    })
    .min(1, { message: "L'heure de début est obligatoire." }),
  endHour: z
    .string({
      required_error: "L'heure de fin est obligatoire.",
    })
    .min(1, { message: "L'heure de fin est obligatoire." }),
  availableDays: z
    .array(z.string(), {
      required_error: "La disponibilité est obligatoire",
    })
    .min(1, { message: "La disponibilité est obligatoire" }),
  reservation_type: z
    .string({
      required_error: "La politique d'annulation est obligatoire.",
    })
    .min(1, { message: "La politique d'annulation est obligatoire." }),

  weekendPrices: z.array(
    z
      .object({
        weekendPrice: z.number(),
        weekendMin: z.number(),
        weekendMax: z.number(),
      })
      .optional()
  ),
});

const LocationAvailibility: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const [minVisitors] = useState<number>(1);
  const [maxVisitors, setMaxVisitors] = useState<number | undefined>();
  const [priceVisitors, setPriceVisitors] = useState<number | undefined>();

  const [maxVisitorsErrorMsg, setMaxVisitorsErrorMsg] = useState<string | null>(null);
  const [priceVisitorsErrorMsg, setPriceVisitorsErrorMsg] = useState<string | null>(null);

  // weekendPrices

  const [weekendMinVisitors] = useState<number>(1);
  const [weekendMaxVisitors, setWeekendMaxVisitors] = useState<number | undefined>();
  const [weekendPriceVisitors, setWeekendPriceVisitors] = useState<number | undefined>();

  const [weekendMaxVisitorsErrorMsg, setWeekendMaxVisitorsErrorMsg] = useState<string | null>(null);
  const [weekendPriceVisitorsErrorMsg, setWeekendPriceVisitorsErrorMsg] = useState<string | null>(null);

  const [enableEndHour, setEnableEndHour] = useState(false);

  const [endHourOptions, setEndHourOptions] = useState<string[]>(startHourOptions);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      prices: data?.prices ?? [],
      weekendPrices: data?.weekendPrices ?? [],
      cleaning_costs: data?.cleaning_costs ?? 0,
      guarantee: data?.guarantee ?? 0,
      startHour: data?.startHour ?? undefined,
      endHour: data?.endHour ?? undefined,
      availableDays: data?.availableDays ?? [],
      reservation_type: data?.reservation_type ?? "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (data) {
      setValue("prices", data.prices);
      setValue("guarantee", data.guarantee);
      setValue("cleaning_costs", data.cleaning_costs);
      setValue("startHour", data.startHour);
      setValue("endHour", data.endHour);
      setValue("availableDays", data.availableDays);
      setValue("reservation_type", data.reservation_type);
      setValue("weekendPrices", data.weekendPrices);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormSix") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const handleAddPrices = () => {
    if (!maxVisitors) {
      setMaxVisitorsErrorMsg("Ce champ et obligatoire");
    } else {
      if (data?.visitor && maxVisitors > data.visitor) {
        setMaxVisitorsErrorMsg(`La valeur ne peut pas dépasser la capacité d'accueil :  ${data.visitor}`);
      } else {
        setMaxVisitorsErrorMsg(null);
      }
    }

    if (!priceVisitors) {
      setPriceVisitorsErrorMsg("Ce champ et obligatoire");
    } else {
      setPriceVisitorsErrorMsg(null);
    }

    if (maxVisitors && priceVisitors && data?.visitor && maxVisitors <= data.visitor) {
      const extraValues = getValues("prices") || [];
      setValue("prices", [
        ...extraValues,
        {
          min: minVisitors,
          max: maxVisitors,
          price: priceVisitors,
        },
      ]);

      setMaxVisitors(undefined);
      setPriceVisitors(undefined);
      trigger("prices");
    }
  };

  const handleAddWeekendPrices = () => {
    if (!weekendMaxVisitors) {
      setWeekendMaxVisitorsErrorMsg("Ce champ et obligatoire");
    } else {
      if (data?.visitor && weekendMaxVisitors > data.visitor) {
        setWeekendMaxVisitorsErrorMsg(`La valeur ne peut pas dépasser la capacité d'accueil :  ${data.visitor}`);
      } else {
        setWeekendMaxVisitorsErrorMsg(null);
      }
    }

    if (!weekendPriceVisitors) {
      setWeekendPriceVisitorsErrorMsg("Ce champ et obligatoire");
    } else {
      setWeekendPriceVisitorsErrorMsg(null);
    }

    if (weekendMaxVisitors && weekendPriceVisitors && data?.visitor && weekendMaxVisitors <= data.visitor) {
      const extraValues = getValues("weekendPrices") || [];
      setValue("weekendPrices", [
        ...extraValues,
        {
          weekendMin: weekendMinVisitors,
          weekendMax: weekendMaxVisitors,
          weekendPrice: weekendPriceVisitors,
        },
      ]);

      setWeekendMaxVisitors(undefined);
      setWeekendPriceVisitors(undefined);
      trigger("weekendPrices");
    }
  };

  const handleRemoveTag = (index: number) => {
    const prices = [...(getValues("prices") || [])];
    prices.splice(index, 1);
    setValue("prices", prices);
    trigger("prices");
  };

  const handleRemoveWeekendPricesTag = (index: number) => {
    const weekendPrices = [...(getValues("weekendPrices") || [])];
    weekendPrices.splice(index, 1);
    setValue("weekendPrices", weekendPrices);
    trigger("weekendPrices");
  };

  const handleMaxChange = (value: number) => {
    setMaxVisitorsErrorMsg(null);
    setMaxVisitors(value);

    if (!isNaN(value) && data?.visitor !== undefined && (value < minVisitors || value > data.visitor)) {
      setMaxVisitorsErrorMsg(`La valeur ne peut pas dépasser la capacité d'accueil : [1 - ${data.visitor}]`);
    }
    trigger("prices");
  };

  const handleWeekendMaxChange = (value: number) => {
    setWeekendMaxVisitorsErrorMsg(null);
    setWeekendMaxVisitors(value);

    if (!isNaN(value) && data?.visitor !== undefined && (value < weekendMinVisitors || value > data.visitor)) {
      setWeekendMaxVisitorsErrorMsg(
        `La valeur ne peut pas dépasser la capacité d'accueil : [1 - ${data.visitor}]`
      );
    }
    trigger("weekendPrices");
  };

  const handlePriceChange = (value: number) => {
    setPriceVisitorsErrorMsg(null);
    setPriceVisitors(value);
    trigger("prices");
  };

  const handleWeekendPriceChange = (value: number) => {
    setWeekendPriceVisitorsErrorMsg(null);
    setWeekendPriceVisitors(value);
    trigger("weekendPrices");
  };

  const handleSelectAvailableDays = (dayDesignation: string, isChecked: boolean) => {
    const updatedselectedDays = isChecked
      ? [...(getValues("availableDays") ?? []), dayDesignation]
      : getValues("availableDays")?.filter((day: string) => day !== dayDesignation);

    setValue("availableDays", updatedselectedDays as string[]);
    trigger("availableDays");
  };

  const handleStartHourChange = (selectedStartHour: string) => {
    setValue("startHour", selectedStartHour);
    const updatedEndHourOptions = startHourOptions.slice(startHourOptions.indexOf(selectedStartHour) + 1);
    setEndHourOptions(updatedEndHourOptions);

    if (!updatedEndHourOptions.includes(getValues("endHour"))) {
      setValue("endHour", "");
    }

    setEnableEndHour(true);
    trigger("startHour");
  };

  const handleReservationTypeChange = (reservationType: string) => {
    setValue("reservation_type", reservationType);
    trigger("reservation_type");
  };

  const handleEndHourChange = (selectedEndHour: string) => {
    setValue("endHour", selectedEndHour);
    trigger("endHour");
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:w-3/4 md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Prix et disponibilité de votre espace</h2>
          </div>

          <div className="my-5">
            <div>
              <div className="flex flex-col">
                <FormItem label="Politique d'annulation *">
                  <div className="flex space-x-4">
                    <div className="relative flex-1">
                      <input
                        className="hidden peer"
                        id="radio_1"
                        type="radio"
                        {...register("reservation_type")}
                        name="reservation_type"
                        value={"Flexible"}
                        checked={getValues("reservation_type") === "Flexible"}
                        onChange={(e) => handleReservationTypeChange(e.target.value)}
                      />
                      <span className="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full right-4 top-1/2 peer-checked:border-indigo-500"></span>
                      <label
                        className="flex flex-col p-4 border border-gray-300 rounded-lg cursor-pointer peer-checked:border-4 peer-checked:border-indigo-700"
                        htmlFor="radio_1"
                      >
                        <span className="mt-2 text-xl font-bold">Flexible</span>
                      </label>
                    </div>
                    <div className="relative flex-1">
                      <input
                        className="hidden peer"
                        id="radio_2"
                        type="radio"
                        {...register("reservation_type")}
                        name="reservation_type"
                        value={"Stricte"}
                        checked={getValues("reservation_type") === "Stricte"}
                        onChange={(e) => handleReservationTypeChange(e.target.value)}
                      />
                      <span className="box-content absolute block w-3 h-3 -translate-y-1/2 bg-white border-8 border-gray-300 rounded-full right-4 top-1/2 peer-checked:border-indigo-500"></span>
                      <label
                        className="flex flex-col p-4 border border-gray-300 rounded-lg cursor-pointer peer-checked:border-4 peer-checked:border-indigo-700"
                        htmlFor="radio_2"
                      >
                        <span className="mt-2 text-xl font-bold">Stricte</span>
                      </label>
                    </div>
                  </div>
                  {errors.reservation_type?.message && (
                    <div className="text-xs text-red-500">{`${errors.reservation_type?.message}`}</div>
                  )}
                </FormItem>
              </div>

              <div className="mt-10">
                <label className="mb-3 font-semibold">Liste des prix horaires</label>
                <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
                  Mentionnez la liste des prix horaires en fonction du nombre de visiteurs.
                </span>
              </div>
              {errors.prices?.message && <div className="text-xs text-red-500">{`${errors.prices?.message}`}</div>}

              <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-5">
                <div className="md:col-span-3">
                  <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                    {getValues("prices")?.map((price: any, index: any) => (
                      <div key={index} className="flex items-center justify-between py-5">
                        <span>
                          {price.min} - {price.max} visiteurs
                        </span>
                        <span>{price.price} € /heure</span>

                        <i
                          className="ml-4 text-2xl cursor-pointer text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100"
                          onClick={() => handleRemoveTag(index)}
                        ></i>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="pt-2 space-y-4 md:space-y-0 md:flex md:col-span-4 md:gap-5">
                  <div className="block w-full">
                    <Input
                      className="cursor-not-allowed"
                      name={"minVisitors"}
                      type={"number"}
                      disabled={true}
                      value={minVisitors}
                    />
                  </div>

                  <div className="block w-full">
                    <Input
                      placeholder="Nombre de visiteurs"
                      name={"maxVisitors"}
                      type={"number"}
                      max={data?.visitor}
                      value={maxVisitors ?? ""}
                      onChange={(e) => handleMaxChange(parseInt(e.target.value))}
                    />

                    {maxVisitorsErrorMsg && <div className="pl-2 text-xs text-red-500">{maxVisitorsErrorMsg}</div>}
                  </div>

                  <div className="block w-full">
                    <Input
                      placeholder="Prix d'heure / € "
                      name={"priceVisitors"}
                      type={"number"}
                      value={priceVisitors ?? ""}
                      onChange={(e) => handlePriceChange(parseInt(e.target.value))}
                    />

                    {priceVisitorsErrorMsg && (
                      <div className="pl-2 text-xs text-red-500">{priceVisitorsErrorMsg}</div>
                    )}
                  </div>

                  <div className="block">
                    <ButtonPrimary className="flex-shrink-0 w-full" onClick={handleAddPrices} type={"button"}>
                      <i className="text-xl las la-plus"></i>
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </div>

            <FormItem label="Frais de ménage (€)" className="mt-3">
              <div className="md:w-1/2">
                <div className="relative">
                  <div className="absolute inset-y-0 z-10 flex items-center pl-1 pointer-events-none right-5">
                    <span className="text-gray-500">€</span>
                  </div>
                  <Input
                    className={`mt-1.5 ${errors.cleaning_costs?.message ? "border-red-500" : ""}`}
                    placeholder="Frais de ménage"
                    {...register("cleaning_costs", { setValueAs: (v) => (v === "" ? undefined : parseInt(v)) })}
                    type={"number"}
                  />
                </div>
              </div>
              {errors.cleaning_costs?.message && (
                <div className="text-xs text-red-500">{`${errors.cleaning_costs?.message}`}</div>
              )}
            </FormItem>

            <FormItem label="Montant du dépôt de garantie (€) *" className="mt-3">
              <div className="md:w-1/2">
                <div className="relative">
                  <div className="absolute inset-y-0 z-10 flex items-center pl-1 pointer-events-none right-5">
                    <span className="text-gray-500">€</span>
                  </div>
                  <Input
                    className={`mt-1.5 ${errors.guarantee?.message ? "border-red-500" : ""}`}
                    placeholder="Montant du dépôt de garantie"
                    {...register("guarantee", { valueAsNumber: true })}
                    type={"number"}
                  />
                </div>
              </div>
              {errors.guarantee?.message && (
                <div className="text-xs text-red-500">{`${errors.guarantee?.message}`}</div>
              )}
            </FormItem>

            <div>
              <h2 className="mt-5 font-semibold">Disponibilité *</h2>
            </div>

            <div className={`grid grid-cols-1 mt-6`}>
              {daysOfWeek.map((day) => (
                <Checkbox
                  label={<Typography className="text-dark dark:text-white">{day.label}</Typography>}
                  key={day.value}
                  name={day.label}
                  checked={!!getValues("availableDays")?.includes(day.label)}
                  onChange={(e) => handleSelectAvailableDays(day.label, e.target.checked)}
                />
              ))}
            </div>

            {errors.availableDays?.message && (
              <div className="text-xs text-red-500">{`${errors.availableDays?.message}`}</div>
            )}

            {getValues("availableDays")?.some((day) => ["Samedi", "Dimanche"].includes(day)) && (
              <div className="mt-10">
                <div>
                  <h2 className="mt-5 font-semibold">Prix week-end</h2>
                </div>
                <span className="block mt-2 text-sm text-neutral-500 dark:text-neutral-400">
                  Mentionnez la liste des prix horaires en fonction du nombre de visiteurs.
                </span>

                <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-5">
                  <div className="md:col-span-3">
                    <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                      {getValues("weekendPrices")?.map((weekend: any, index: any) => (
                        <div key={index} className="flex items-center justify-between py-5">
                          <span>
                            {weekend.weekendMin} - {weekend.weekendMax} visiteurs
                          </span>
                          <span>{weekend.weekendPrice} € /heure</span>

                          <i
                            className="ml-4 text-2xl cursor-pointer text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100"
                            onClick={() => handleRemoveWeekendPricesTag(index)}
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="pt-2 space-y-4 md:space-y-0 md:flex md:col-span-4 md:gap-5">
                    <div className="block w-full">
                      <Input
                        className="cursor-not-allowed"
                        name={"weekendMinVisitors"}
                        type={"number"}
                        disabled={true}
                        value={weekendMinVisitors}
                      />
                    </div>

                    <div className="block w-full">
                      <Input
                        placeholder="Nombre de visiteurs"
                        name={"weekendMaxVisitors"}
                        type={"number"}
                        max={data?.visitor}
                        value={weekendMaxVisitors ?? ""}
                        onChange={(e) => handleWeekendMaxChange(parseInt(e.target.value))}
                      />

                      {weekendMaxVisitorsErrorMsg && (
                        <div className="pl-2 text-xs text-red-500">{weekendMaxVisitorsErrorMsg}</div>
                      )}
                    </div>

                    <div className="block w-full">
                      <Input
                        placeholder="Prix d'heure / € "
                        name={"weekendPriceVisitors"}
                        type={"number"}
                        value={weekendPriceVisitors ?? ""}
                        onChange={(e) => handleWeekendPriceChange(parseInt(e.target.value))}
                      />

                      {weekendPriceVisitorsErrorMsg && (
                        <div className="pl-2 text-xs text-red-500">{weekendPriceVisitorsErrorMsg}</div>
                      )}
                    </div>

                    <div className="block">
                      <ButtonPrimary
                        className="flex-shrink-0 w-full"
                        onClick={handleAddWeekendPrices}
                        type={"button"}
                      >
                        <i className="text-xl las la-plus"></i>
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </div> // Wrap everything in this parent div.
            )}

            <br />

            <div>
              <h2 className="text-2xl font-semibold">Horaire</h2>
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-5">
              <FormItem label="Heure début *">
                <Select
                  className={`mt-1.5 ${errors.startHour?.message ? "border-red-500" : ""}`}
                  disabled={isLoading}
                  {...register("startHour")}
                  value={getValues("startHour")}
                  onChange={(e) => handleStartHourChange(e.target.value)}
                >
                  <option value={""}>Heure début</option>
                  {startHourOptions.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>

                {errors.startHour?.message && (
                  <div className="text-xs text-red-500">{`${errors.startHour?.message}`}</div>
                )}
              </FormItem>

              <FormItem label="Heure fin *">
                <Select
                  className={`mt-1.5 ${errors.endHour?.message ? "border-red-500" : ""}`}
                  disabled={!enableEndHour}
                  {...register("endHour")}
                  value={getValues("endHour")}
                  onChange={(e) => handleEndHourChange(e.target.value)}
                >
                  <option value={""}>Heure fin</option>
                  {endHourOptions.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Select>

                {errors.endHour?.message && (
                  <div className="text-xs text-red-500">{`${errors.endHour?.message}`}</div>
                )}
              </FormItem>
            </div>
          </div>
        </div>

        <div className="w-full mt-4 md:w-1/3 md:mt-0">
          <div className="p-4 border-2 border-indigo-800">
            <div className="flex items-center mt-5 ml-5">
              <CurrencyEuroIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">
                Type de réservation <span className="text-xs">(Flexible/Stricte)</span>
              </h2>
            </div>
            <div className="mt-2 ml-16">
              <ul className="mr-5 -ml-3 text-sm list-disc">
                <li>Stricte : Le client ne peut pas annuler la réservation après 48 heures de sa création</li>
                <li>
                  Flexible : Le client ne peut pas annuler la réservation moins de 5 jours avant la date réservée.
                </li>
              </ul>
            </div>

            <div className="flex items-center mt-5 ml-5">
              <CurrencyEuroIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">
                Montant de la location <span className="text-xs">(prix/heure)</span>
              </h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mr-5 -ml-10 text-sm">
                Indiquez le tarif de location par heure pour l'utilisation de l'espace. Cela permet aux
                utilisateurs de connaître le coût pour chaque heure d'utilisation de l'espace loué.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <CurrencyEuroIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Montant du dépôt de garantie</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mt-2 -ml-10 text-sm">
                Précisez le montant du dépôt de garantie requis lors de la location de l'espace. Ce dépôt de
                garantie est souvent remboursable et sert à couvrir d'éventuels dommages ou frais supplémentaires.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <CalendarDaysIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">Disponibilité</h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-5 -ml-10 text-sm">
                Définissez les jours où votre espace est disponible à la location. Indiquez les jours de la semaine
                ou les plages horaires spécifiques pendant lesquels les utilisateurs peuvent louer votre espace.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <ClockIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">
                Horaire <span className="text-xs">(Heure de début)</span>
              </h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-5 -ml-10 text-sm">
                Indiquez l'heure à laquelle la location de l'espace peut commencer.
              </p>
            </div>

            <div className="flex items-center mt-10 ml-5">
              <ClockIcon className="inline-block w-8" />
              <h2 className="inline-block mt-0 ml-2 text-2xl font-semibold">
                Horaire <span className="text-xs">(Heure de fin)</span>
              </h2>
            </div>
            <div className="mt-2 ml-16">
              <p className="mb-5 -ml-10 text-sm">
                Précisez l'heure à laquelle la location de l'espace doit être terminée.
              </p>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default LocationAvailibility;
