import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import WarningModal from "components/DeleteModal/WarningModal";
import { EntityCouponDialogForm } from "components/FormDialog/EntityCouponDialogForm";
import List from "components/NavMenu/List";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { createCoupons, deleteCoupons, getAllCoupons } from "redux/features/Admin/Stripe/stripeSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface CouponsPageProps {
  className?: string;
}

interface ModalState {
  isCoupon?: boolean;
  isDeleteModalOpen: boolean;
  selectedItem: any | null;
  loading: boolean;
}

const CouponsPage: FC<CouponsPageProps> = ({ className = "" }) => {
  const columns = [
    { title: "Code", accessor: (item: any) => item.code },
    { title: "Pourcentage de réduction", accessor: (item: any) => `${item.coupon?.percent_off || 0} %` },
    { title: "Max utilisations", accessor: (item: any) => item.coupon?.max_redemptions || "illimité" },
    { title: "Nombre de fois utilisées", accessor: (item: any) => item.coupon?.times_redeemed },
  ];
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [modalState, setModalState] = useState<ModalState>({
    loading: false,
    isCoupon: false,
    isDeleteModalOpen: false,
    selectedItem: null,
  });
  const coupons = useAppSelector((state) => state.stripe.coupons || { data: [], hasMore: false });
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    setModalState({ ...modalState, loading: true });
    const fetchCoupons = async () => {
      const res = await dispatch(getAllCoupons({ startAfter: "" }));
      setData(res.payload.data);
      setModalState({ ...modalState, loading: false });
    };

    fetchCoupons();
  }, [dispatch]);

  useEffect(() => {
    if (coupons.data) {
      setData(coupons.data);
    }
  }, [coupons]);

  const [loading, setLoading] = useState(false);

  const loadMoreCoupons = async () => {
    if (!coupons.hasMore) return;
    const lastCoupon = coupons.data[coupons.data.length - 1];
    const lastCouponId = lastCoupon?.coupon?.id;
    if (lastCouponId) {
      setLoading(true);
      const res = await dispatch(getAllCoupons({ startAfter: lastCoupon.id }));
      setLoading(false);
      setData(res.payload.data);
    } else {
      console.error("No valid last coupon ID found.");
    }
  };

  const handleOnDelete = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isDeleteModalOpen: true });
  };

  const handleConfirmDelete = async () => {
    if (!modalState.selectedItem) return;
    setModalState((prev) => ({ ...prev, loading: true }));
    await dispatch(deleteCoupons(modalState.selectedItem.coupon.id));
    setData((prevData) => prevData.filter((item) => item.coupon.id !== modalState.selectedItem.coupon.id));
    setModalState((prev) => ({ ...prev, isDeleteModalOpen: false, loading: false }));
  };

  useEffect(() => {
    const filteredData = searchTerm
      ? data.filter((item: any) => item.code.toLowerCase().includes(searchTerm.toLowerCase()))
      : data;
    setData(filteredData);
  }, [searchTerm, coupons.data]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex justify-between">
          <span className="">
            <ButtonPrimary className="mb-5" onClick={() => setModalState({ ...modalState, isCoupon: true })}>
              Ajouter coupon
            </ButtonPrimary>
          </span>

          <div className="relative flex items-center mb-3">
            <Input
              type="text"
              placeholder="Rechercher par désignation"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pr-10"
            />
            <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-400 right-2" />
          </div>
        </div>
        <List
          columns={columns}
          data={data}
          loading={modalState.loading}
          onDelete={handleOnDelete}
          showConsult={false}
          showEdit={false}
          showDelete={true}
        />

        {data.length > 1 && coupons.hasMore && (
          <div className="flex justify-center">
            <ButtonPrimary onClick={loadMoreCoupons} className="load-more-button" loading={loading}>
              charger plus
            </ButtonPrimary>
          </div>
        )}

        {modalState.isDeleteModalOpen && (
          <WarningModal
            open={modalState.isDeleteModalOpen}
            id={modalState.selectedItem?.code}
            title={modalState.selectedItem?.code}
            handleClose={() => setModalState({ ...modalState, isDeleteModalOpen: false })}
            handleDelete={handleConfirmDelete}
            isLoading={modalState.loading}
          />
        )}

        {modalState.isCoupon && (
          <EntityCouponDialogForm
            open={modalState.isCoupon}
            handleClose={() =>
              setModalState({ ...modalState, isCoupon: false, selectedItem: null, isDeleteModalOpen: false })
            }
            isLoading={modalState.loading}
            addfn={(payload: any) => dispatch(createCoupons(payload))}
            addfnTitle={"Générer un coupon"}
          />
        )}
      </DashboardMenu>
    </div>
  );
};

export default CouponsPage;
