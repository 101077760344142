import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchEvents } from "redux/features/Admin/Events/eventSlice";
import { fetchFields } from "redux/features/Admin/Fields/fieldSlice";
import { findValueInArray } from "utils/globalFunctions";

interface SelectFieldProps {
  type: "event" | "field";
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onSelectItem?: (value: string) => void;
  clear: boolean;
  propItemValue?: string | null;
  icon?: JSX.Element;
}

const SelectField: FC<SelectFieldProps> = ({
  type,
  autoFocus = false,
  placeHolder = "Select Field",
  desc = "Quels types  ?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectItem,
  clear,
  propItemValue,
  icon,
}) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");
  const [showPopover, setShowPopover] = useState<boolean>(autoFocus);
  const events = useAppSelector((state) => state.featureEvent.eventsList);
  const fields = useAppSelector((state) => state.featureField.fields);
  const location = useLocation();
  const pathname = location.pathname;
  const decodedPathname = decodeURIComponent(pathname);
  const afterItem = decodedPathname.split(`/${type}`)[1];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (type === "event" || pathname === "/locations") {
      dispatch(fetchEvents());
    } else if (type === "field" || pathname === "/prestations") {
      dispatch(fetchFields());
    }
  }, [type, pathname, autoFocus]);

  const handleFocus = () => {
    if (type === "event") {
      dispatch(fetchEvents());
    } else if (type === "field") {
      dispatch(fetchFields());
    }
  };

  useEffect(() => {
    if (clear) setValue("");
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    const eventClickOutsideDiv = (event: MouseEvent) => {
      if (!containerRef.current || containerRef.current.contains(event.target as Node)) {
        return;
      }
      setShowPopover(false);
    };

    if (showPopover) {
      document.addEventListener("click", eventClickOutsideDiv);
    } else {
      document.removeEventListener("click", eventClickOutsideDiv);
    }

    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    const selected =
      type === "event"
        ? findValueInArray(propItemValue, "designation", events)
        : findValueInArray(propItemValue, "designation", fields);

    setValue(selected ? selected?.designation : "");
  }, [propItemValue, events, fields, type]);

  const handleSelectItem = (item: string) => {
    setValue(item);
    setShowPopover(false);
    if (onSelectItem) onSelectItem(item);
  };

  const data = type === "event" ? events : fields;
  const filteredData = data.filter((item: any) =>
    item.designation
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        searchTerm
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      )
  );
  const renderSelectValue = () => {
    return (
      <>
        {filteredData.length > 0 ? (
          filteredData.map((item: any) => (
            <span
              key={item.id}
              onClick={() => handleSelectItem(item.designation)}
              className="flex items-center px-4 py-4 space-x-3 cursor-pointer sm:px-8 sm:space-x-4 hover:bg-neutral-100 dark:hover:bg-neutral-700"
            >
              <span className="block text-neutral-400">{icon}</span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">{item.designation}</span>
            </span>
          ))
        ) : (
          <span className="block p-4 text-neutral-500">Aucun résultat trouvé</span>
        )}
      </>
    );
  };

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  return (
    <div className={`flex relative ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        onFocus={handleFocus}
        tabIndex={0}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "nc-hero-field-focused" : ""}`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">{icon}</div>
        <div className="flex-grow">
          <div
            className={`block p-0 w-full font-semibold truncate bg-transparent border-none focus:ring-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg placeholder-neutral-800 dark:placeholder-neutral-200`}
            onClick={() => setShowPopover(true)}
          >
            {value ? (
              <span>{value}</span>
            ) : (
              <span className="dark:text-white">
                {afterItem !== "locations" ? afterItem : placeHolder}
                {afterItem === undefined && placeHolder}
              </span>
            )}
          </div>
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span>{value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>

      {showPopover && (
        <div
          className={`absolute top-1/2 z-0 self-center h-8 bg-white -translate-y-1/2 dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          <div className="px-4 py-2">
            <input
              ref={inputRef}
              type="text"
              className="w-full px-4 py-2 border rounded-lg border-neutral-300 dark:border-neutral-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400"
              placeholder="Recherche..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {renderSelectValue()}
        </div>
      )}
    </div>
  );
};

export default SelectField;
