import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import WarningModal from "components/DeleteModal/WarningModal";
import { EntityDetails } from "components/FormDialog/EntityDetails";
import List from "components/NavMenu/List";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import { formatDate, useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { adminGetAllContacts, deleteContact } from "redux/features/contact/contactSlice";
import Input from "shared/Input/Input";

export interface ReclamationsPageProps {
  className?: string;
}

interface ModalState {
  isCategory: boolean;
  isDeleteModalOpen: boolean;
  selectedItem: any | null;
  loading: boolean;
}

const ReclamationsPage: FC<ReclamationsPageProps> = ({ className = "" }) => {
  const columns = [
    {
      title: "Date",
      accessor: (item: any) => formatDate(item.createdAt),
    },
    { title: "Nom", accessor: "nom" },
    { title: "E-mail", accessor: "email" },
  ];
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [modalState, setModalState] = useState<ModalState>({
    loading: false,
    isCategory: false,
    isDeleteModalOpen: false,
    selectedItem: null,
  });
  const contacts = useAppSelector((state) => state.featureContact?.response);
  const totalPages = useAppSelector((state) => state.featureCategory?.totalPages);

  useEffect(() => {
    dispatch(adminGetAllContacts({ page: 1, email: "" }));
  }, []);

  const getDataAction = () => {
    return (page: number) => dispatch(adminGetAllContacts({ page: page, email: searchTerm }));
  };

  const handleConsult = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isCategory: true });
  };

  const handleOnDelete = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isDeleteModalOpen: true });
  };

  const handleConfirmDelete = async () => {
    if (!modalState.selectedItem) return;
    setModalState((prev) => ({ ...prev, loading: true }));
    await dispatch(deleteContact(modalState.selectedItem.id));
    dispatch(adminGetAllContacts({ page: 1, email: "" }));
    setModalState((prev) => ({ ...prev, isDeleteModalOpen: false, loading: false }));
  };

  useEffect(() => {
    dispatch(adminGetAllContacts({ page: 1, email: searchTerm })).then(() => {});
  }, [searchTerm, dispatch]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex justify-between">
          <span className=""></span>

          <div className="relative flex items-center mb-3">
            <Input
              type="text"
              placeholder="Rechercher par désignation"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pr-10"
            />
            <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-400 right-2" />
          </div>
        </div>
        <List
          columns={columns}
          getDataAction={getDataAction()}
          data={contacts}
          totalPages={totalPages}
          loading={modalState.loading}
          onDelete={handleOnDelete}
          onConsult={handleConsult}
          showConsult={true}
          showEdit={false}
        />

        {modalState.isDeleteModalOpen && (
          <WarningModal
            open={modalState.isDeleteModalOpen}
            id={modalState.selectedItem?.id}
            title={modalState.selectedItem?.email}
            handleClose={() => setModalState({ ...modalState, isDeleteModalOpen: false })}
            handleDelete={handleConfirmDelete}
            isLoading={modalState.loading}
          />
        )}

        {modalState.isCategory && (
          <EntityDetails
            open={modalState.isCategory}
            handleClose={() =>
              setModalState({ ...modalState, isCategory: false, selectedItem: null, isDeleteModalOpen: false })
            }
            title={`Détails du réclamation`}
            id={modalState.selectedItem?.id}
            value={modalState.selectedItem}
            details={[
              { label: "Nom", value: modalState.selectedItem?.nom },
              { label: "E-mail", value: modalState.selectedItem?.email },
              { label: "Message", value: modalState.selectedItem?.message },
            ]}
          />
        )}
      </DashboardMenu>
    </div>
  );
};

export default ReclamationsPage;
