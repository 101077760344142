import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { categoryApi } from "./featureAPI";

const initialState = {
  designation: "",
  loading: "idle",
  categories: [],
  announcesCategories: [],
  categorie: { designation: "" },
  totalPages: 0,
};

export const addCategory = createAsyncThunk("featureCategory/addCategory", async (data: any) => {
  return await categoryApi.add(data);
});

export const fetchCategorys = createAsyncThunk("featureCategory/fetchCategory", async () => {
  return await categoryApi.verify();
});

export const deleteCategory = createAsyncThunk("featureCategory/deleteCategory", async (id: number) => {
  return await categoryApi.delete(id);
});

export const updateCategory = createAsyncThunk(
  "featureCategory/updateCategory",
  async ({ data, id }: { data: any; id: any }) => {
    return await categoryApi.update(data, id);
  }
);
export const getCategoryById = createAsyncThunk("featureCategory/getCategoryById", async (id: any) => {
  return await categoryApi.getById(id);
});

export const addAnnounceCategory = createAsyncThunk("addAnnounceCategory", async (data: any) => {
  return await categoryApi.addAnnounceCategory(data);
});

export const fetchAnnounceCategorys = createAsyncThunk(
  "fetchAnnounceCategorys",
  async ({ page, designation }: { page: number; designation: string }) => {
    return await categoryApi.fetchAnnounceCategorys(page, designation);
  }
);

export const deleteAnnounceCategory = createAsyncThunk("deleteAnnounceCategory", async (id: number) => {
  return await categoryApi.deleteAnnounceCategory(id);
});

export const updateAnnounceCategory = createAsyncThunk(
  "updateAnnounceCategory",
  async ({ data, id }: { data: { designation: string }; id: any }) => {
    return await categoryApi.updateAnnounceCategory(data, id);
  }
);

export const listAnnounceCategories = createAsyncThunk("listCategories", async () => {
  return await categoryApi.listAnnounceCategories();
});

export const adminGetAllLocationCategories = createAsyncThunk(
  "adminGetAllLocationCategories",
  async ({ page, designation }: { page: number; designation: string }) => {
    return await categoryApi.adminGetAllLocationCategories(page, designation);
  }
);

const createCategorySlice = createSlice({
  name: "featureCategory",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addCategory.rejected, (state) => {
        state.loading = "failed";
      })

      .addCase(fetchCategorys.fulfilled, (state, action) => {
        state.categories = action.payload;
      })

      .addCase(listAnnounceCategories.fulfilled, (state, action) => {
        state.announcesCategories = action.payload;
      })

      .addCase(adminGetAllLocationCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(deleteCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteCategory.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = "failed";
      })

      .addCase(updateCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateCategory.rejected, (state) => {
        state.loading = "failed";
      })

      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.categorie = action.payload;
      })

      .addCase(addAnnounceCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addAnnounceCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addAnnounceCategory.rejected, (state) => {
        state.loading = "failed";
      })

      .addCase(fetchAnnounceCategorys.fulfilled, (state, action) => {
        state.totalPages = action.payload.totalPages;
        state.announcesCategories = action.payload.categories;
      })

      .addCase(deleteAnnounceCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteAnnounceCategory.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteAnnounceCategory.rejected, (state) => {
        state.loading = "failed";
      })

      .addCase(updateAnnounceCategory.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateAnnounceCategory.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateAnnounceCategory.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createCategorySlice.actions;
export default createCategorySlice.reducer;
