import WarningModal from "components/DeleteModal/WarningModal";
import List from "components/NavMenu/List";
import TabNavigation from "components/NavMenu/TabNavigation";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteAnnounce, getAnnouncesByAdmin } from "redux/features/announce/announceSlice";
import DashboardMenu from "../DashboardPage/DashboardMenu";
import {
  adminGetApprovedLocations,
  adminGetIncompletedLocations,
  adminGetUnapprovedLocations,
} from "redux/features/location/locationSlice";
import Input from "shared/Input/Input";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import ValidateLocationsModal from "./ValidateLocationsModal";

const navLinks = [
  { to: "/admin/dashboard/locations/unapproved", label: "Espaces en cours de traitement", color: "yellow" },
  { to: "/admin/dashboard/locations/approuved", label: "Espaces publié", color: "green" },
  { to: "/admin/dashboard/locations/incompleted", label: "Espaces non complétés", color: "red" },
];

const columns = [
  { title: "Title", accessor: "title" },
  { title: "Status", accessor: "validation" },
];

if (navLinks.some((link) => link.to === "/dashboard/annonces/approuved")) {
  columns[1] = { title: "Status", accessor: "status" };
}

const AdminAllLocationsPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(navLinks[0].to);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const locations = useAppSelector((state) => state?.location?.allLocations);
  const totalPages = useAppSelector((state) => state.location?.totalPages);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (location.pathname === "/admin/dashboard/locations/unapproved") {
          await dispatch(adminGetUnapprovedLocations({ page: page, title: searchTerm }));
        } else if (location.pathname === "/admin/dashboard/locations/approuved") {
          await dispatch(adminGetApprovedLocations({ page: page, title: searchTerm }));
        } else if (location.pathname === "/admin/dashboard/locations/incompleted") {
          await dispatch(adminGetIncompletedLocations({ page: page, title: searchTerm }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.pathname, page, dispatch, searchTerm]);

  useEffect(() => {
    switch (currentTab) {
      case "/admin/dashboard/locations/unapproved":
        setData(locations || []);
        break;
      case "/admin/dashboard/locations/approuved":
        setData(locations || []);

        break;
      case "/admin/dashboard/locations/incompleted":
        setData(locations || []);

        break;
      default:
        break;
    }
  }, [currentTab, locations]);

  const handleTabChange = (tab: any) => {
    setCurrentTab(tab);
    setPage(1);
  };

  const getDataAction = () => {
    switch (currentTab) {
      case "/admin/dashboard/locations/unapproved":
        return (page: number) => dispatch(adminGetUnapprovedLocations({ page: page, title: searchTerm }));
      case "/admin/dashboard/locations/approuved":
        return (page: number) => dispatch(adminGetApprovedLocations({ page: page, title: searchTerm }));
      case "/admin/dashboard/locations/incompleted":
        return (page: number) => dispatch(adminGetIncompletedLocations({ page: page, title: searchTerm }));

      default:
        return () => {};
    }
  };

  const handleEdit = (item: any) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleOnDelete = (item: any) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    await dispatch(deleteAnnounce(selectedItem.id)).then(() => {
      dispatch(getAnnouncesByAdmin({ page: page, status: "inProgress" }));
      setIsDeleteModalOpen(false);
    });
  };

  const navigate = useNavigate();
  const handleConsult = (item: any) => {
    navigate(`/dashboard/location/${item.id}`);
  };

  return (
    <div>
      <DashboardMenu>
        <TabNavigation navLinks={navLinks.map((link) => ({ ...link, onClick: () => handleTabChange(link.to) }))} />
        <div className="flex justify-end mb-2">
          <div className="relative flex items-center">
            <Input
              type="text"
              placeholder="Rechercher par titre"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pr-10"
            />
            <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-400 right-2" />
          </div>
        </div>
        <List
          navLinks={navLinks}
          columns={columns}
          getDataAction={getDataAction()}
          data={data}
          totalPages={totalPages}
          loading={loading}
          onEdit={handleEdit}
          onDelete={handleOnDelete}
          onConsult={handleConsult}
        />

        {/* {isModalOpen && (
          <ValidateAnnounceModal
            onClose={() => setModalOpen(false)}
            id={selectedItem?.id}
            title={selectedItem?.title}
          />
        )} */}
        {isDeleteModalOpen && (
          <WarningModal
            open={isDeleteModalOpen}
            id={selectedItem?.id}
            title={selectedItem?.title}
            handleClose={() => setIsDeleteModalOpen(false)}
            handleDelete={handleConfirmDelete}
          />
        )}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50"></div>

            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="flex flex-col items-center p-6 overflow-auto bg-white rounded-lg dark:bg-neutral-900">
                <ValidateLocationsModal
                  onClose={(value) => {
                    setModalOpen(value);
                    setSelectedItem(null);
                  }}
                  id={selectedItem.id}
                  title={selectedItem.title}
                  locationData={selectedItem}
                />
              </div>
            </div>
          </div>
        )}
      </DashboardMenu>
    </div>
  );
};

export default AdminAllLocationsPage;
