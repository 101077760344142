import { FC, useContext, useEffect, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import ClientReservationDetailedCard from "components/ClientReservationCard/ClientReservationDetailedCard";
import ReservationsDetailedCard from "components/FlightCard/ReservationsDetailedCard";
import Pagination from "shared/Pagination/Pagination";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {
  fetchReservationByClient,
  fetchReservationByOwner,
  fetchReservationByOwnerAsClient,
} from "redux/features/reservations/reservationSlice";
import { AuthContext } from "context/appContext";
import { Spinner } from "@material-tailwind/react";
import ClientReservation from "../ClientReservation";
import { itemPerDashboradClient } from "data/constants";
import { Reservation } from "data/types";

export interface SectionGridFilterCardProps {
  className?: string;
}

const MadeReservationGridDetailedCard: FC<SectionGridFilterCardProps> = ({ className = "" }) => {
  const authContext = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false);
  const profilId = authContext?.user?.profil?.id;
  const dispatch = useAppDispatch();
  let reservationState: Reservation[] = useAppSelector((state) => state.featureReservation.reservations);

  let reservationStateOwner: Reservation[] = useAppSelector((state) => state.featureReservation.reservationOwner);

  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(fetchReservationByOwner(profilId)).then(() => setIsLoading(false));
    }
  }, [profilId]);

  useEffect(() => {
    if (profilId) {
      setIsLoading(true);
      dispatch(fetchReservationByOwnerAsClient(profilId)).then(() => setIsLoading(false));
    }
  }, [profilId]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = itemPerDashboradClient;
  const totalPages = Math.ceil((reservationState.length + reservationStateOwner.length) / itemsPerPage);
  const combinedReservations = [...reservationState, ...reservationStateOwner];
  const paginatedReservations = combinedReservations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const paginatedReservation = reservationState?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };

  return (
    <ClientReservation>
      <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
        <div className="grid grid-cols-1 gap-6 rounded-3xl lg:bg-neutral-50 lg:dark:bg-black/20">
          {reservationState.length > 0 &&
            reservationState.map((item, index) => (
              <ReservationsDetailedCard defaultOpen={!index} key={index} data={item} />
            ))}
          {loading && (
            <div className="flex items-center justify-center mt-16">
              <p>
                <Spinner color="blue" className="w-12 h-12" />
              </p>
            </div>
          )}
          {reservationStateOwner.length > 0 &&
            reservationStateOwner.map((item, index) => (
              <ClientReservationDetailedCard defaultOpen={!index} key={index} data={item} />
            ))}
          {loading && (
            <div className="flex items-center justify-center mt-16">
              <p>
                <Spinner color="blue" className="w-12 h-12" />
              </p>
            </div>
          )}
          <div className="flex items-center justify-center mt-16">
            {typeof totalPages === "number" && (
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            )}
          </div>
          {reservationState?.length === 0 && (
            <div className="flex items-center justify-center mt-16">
              <p>Pas de reservations disponibles</p>
            </div>
          )}
        </div>
      </div>
    </ClientReservation>
  );
};

export default MadeReservationGridDetailedCard;
