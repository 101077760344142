import React, { HTMLAttributes, ReactNode } from "react";
import NextPrev from "shared/NextPrev/NextPrev";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = " ",
  className = "mb-3 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  ...args
}) => {
  const customStyle = {
    fontSize: "0.95rem",
    fontWeight: 500,
  };
  return (
    <div
      className={`flex relative flex-col justify-between nc-Section-Heading sm:flex-row sm:items-end ${className}`}
    >
      <div className={isCenter ? "mx-auto w-full max-w-2xl text-center" : "max-w-2xl"}>
        <h2 className={`text-3xl font-semibold md:text-xl !leading-[114%]`} style={{ fontSize: "3rem" }} {...args}>
          {children}
        </h2>
        <br />
        {desc && (
          <span
            className="block mt-4 text-base font-normal md:mt-3 sm:text-sm text-neutral-500 dark:text-neutral-400"
            style={customStyle}
          >
            {desc}
          </span>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="flex justify-end flex-shrink-0 mt-4 sm:ml-2 sm:mt-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading;
