import AlertModalDelete from "components/AlertModal/AlertModalDelete";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useContext, useEffect, useState } from "react";
import {
  cancelReservation,
  fetchReservationByClient,
  fetchReservationByOwner,
  fetchReservationByOwnerAsClient,
  repayReservationFee,
} from "redux/features/reservations/reservationSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FaUser } from "react-icons/fa";
import { PiUsersFourFill } from "react-icons/pi";
import { IoTimeSharp } from "react-icons/io5";
import { MdAccessTimeFilled } from "react-icons/md";
import { RiQrScanFill } from "react-icons/ri";
import { socket } from "../../utils/socket";
import toast from "react-hot-toast";
import { AuthContext } from "context/appContext";
import { BsFillCreditCardFill } from "react-icons/bs";

export interface ClientReservationDetailedCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: any;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientReservationDetailedCard: FC<ClientReservationDetailedCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const dispatch = useAppDispatch();

  let reservationState = useAppSelector((state) => state.featureReservation);
  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;
  const profil_status = authContext?.user?.profil?.validation;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const currentDate = new Date();
  const selectedDate = new Date(data?.selectedDate);
  const fiveDaysAhead = new Date();
  fiveDaysAhead.setDate(currentDate.getDate() + 5);
  const isDateNotPassed = selectedDate > currentDate;
  const currentHour = currentDate.getHours();
  const endHour = (data?.endHour).slice(0, -3);

  const isTerminated = currentHour > endHour;
  useEffect(() => {
    if (reservationState.sessionUrl) {
      window.location.href = reservationState.sessionUrl;
    }
  }, [reservationState.sessionUrl]);

  useEffect(() => {
    if (reservationState.status === "failed") {
      toast.error("🔔 Quelque chose ne fonctionne pas, veuillez réessayer!");
    }
  }, [reservationState.status]);

  function formatDateShort(dateString: any) {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleClick = async () => {
    if (setShowModal) {
      setShowModal(true);
    }
  };

  const handleConfirm = async () => {
    setShowModal(false);
    setLoading(true);
    await dispatch(cancelReservation({ reservation: data.id }));
    setCanceled(true);
    setLoading(false);

    if (profilId) {
      if (profil_status !== "en cours") {
        await dispatch(fetchReservationByOwner(profilId));

        await dispatch(fetchReservationByOwnerAsClient(profilId));
      }
      await dispatch(fetchReservationByClient(profilId));
    }

    if (data) {
      socket.emit("send-notification", {
        senderId: data.Client.userId,
        receiverId: data.Owner.userId,
        action: "cancel_reservation",
        reservationId: data.id,
        reservation: data,
      });
    }
  };

  const handleSubmit = async () => {
    dispatch(repayReservationFee(data.id));
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    const currentDate = new Date();
    const selectedDate = new Date(data?.selectedDate);
    const fiveDaysAhead = new Date();
    fiveDaysAhead.setDate(currentDate.getDate() + 5);
    const isAtLeast5DaysAhead = selectedDate > fiveDaysAhead;
    const isDateNotPassed = selectedDate > currentDate;

    const createdAt = new Date(data?.createdAt);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - createdAt.getTime();
    const flexibilityTime = timeDifference / (1000 * 60 * 60);

    return (
      <div className="p-4 border rounded-2xl md:p-8 border-neutral-200 dark:border-neutral-700">
        <div>
          <AlertModalDelete
            open={showModal}
            handleDelete={() => handleConfirm()}
            handleClose={() => setShowModal(false)}
            message={`Êtes-vous sûr(e) de vouloir annuler votre réservation ?`}
            alertText="Annulation de Réservation"
          />
          <div className="flex flex-col justify-center md:flex-row">
            <div className="flex my-5 md:my-0">
              <div className="flex flex-col items-center flex-shrink-0 py-2"></div>
              <div className="ml-4 space-y-10 text-sm">
                <div className="flex flex-col space-y-1">
                  <span className="font-semibold text-neutral-950">
                    <div className="flex space-x-2">
                      <FaUser className="w-4 h-4 dark:text-white" />
                      <span className="dark:text-white">Propriétaire:</span>
                      <span className="text-neutral-500 dark:text-neutral-400">
                        {data?.Owner?.name ? `${data?.Owner?.name}  ` : data?.Owner?.companyName}
                      </span>
                    </div>
                  </span>
                </div>

                <div className="flex flex-col space-y-1">
                  <span className="font-semibold text-neutral-950">
                    <div className="flex space-x-2">
                      <IoTimeSharp className="w-5 h-5 dark:text-white" />
                      <span className="dark:text-white">Heure d'entrée:</span>
                      <span className="text-neutral-500 dark:text-neutral-400">{data?.startHour}</span>
                    </div>
                  </span>
                </div>

                <div className="flex flex-col space-y-1">
                  <span className="font-semibold text-neutral-950">
                    <div className="flex space-x-2">
                      <MdAccessTimeFilled className="w-5 h-5 dark:text-white" />
                      <span className="dark:text-white">Heure de sortie:</span>
                      <span className="text-neutral-500 dark:text-neutral-400">{data?.endHour}</span>
                    </div>
                  </span>
                </div>

                <div className="flex flex-col space-y-1">
                  <span className="font-semibold text-neutral-950">
                    <div className="flex space-x-2">
                      <PiUsersFourFill className="w-5 h-5 dark:text-white" />
                      <span className="dark:text-white">Personnes autorisées:</span>
                      <span className="text-neutral-500 dark:text-neutral-400">{data?.visitor}</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
            <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
              <li className="font-semibold text-neutral-950 dark:text-white">
                <div className="flex space-x-2">
                  <BsFillCreditCardFill className="w-5 h-5 dark:text-white" />
                  <span className="dark:text-white"> Détail paiement:</span>
                </div>{" "}
              </li>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Sous-total </span>
                <span> {data?.halfTotal} €</span>
              </div>
              {data?.extra && data?.extra !== 0 ? (
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>Extra </span>
                  <span>{data?.extra} €</span>
                </div>
              ) : null}
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>Service </span>
                <span> {data?.service} €</span>
              </div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>{data?.price} €</span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="font-semibold text-neutral-950 dark:text-white">
                  <div className="flex space-x-2">
                    <RiQrScanFill className="w-5 h-5 dark:text-white" />
                    <span className="dark:text-white">Code réservation:</span>
                    <span className="text-neutral-500 dark:text-neutral-400">{data?.reference}</span>
                  </div>
                </span>
              </div>
            </ul>
            <br />
          </div>
        </div>

        <div className="flex justify-center space-y-5 my-7 md:my-10 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          {data?.Location?.reservation_type === "Stricte" && flexibilityTime <= 48 && (
            <>
              {isDateNotPassed && data.payment_status !== "canceled" && !canceled && data.approuved != false && (
                <div className="text-sm text-center text-neutral-700 dark:text-neutral-300 md:text-base">
                  <ButtonPrimary loading={loading} className="bg-red-500" onClick={handleClick}>
                    Annuler réservation
                  </ButtonPrimary>
                </div>
              )}
            </>
          )}
          {data?.Location?.reservation_type !== "Stricte" && (
            <>
              {isAtLeast5DaysAhead &&
                isDateNotPassed &&
                data.payment_status !== "canceled" &&
                !canceled &&
                data.approuved != false && (
                  <div className="text-sm text-center text-neutral-700 dark:text-neutral-300 md:text-base">
                    <ButtonPrimary loading={loading} className="bg-red-500" onClick={handleClick}>
                      Annuler réservation
                    </ButtonPrimary>
                  </div>
                )}
            </>
          )}
          {data.validation !== true && isDateNotPassed && data.payment_status !== "canceled" && !canceled && (
            <div className="ml-2 text-sm text-center text-neutral-700 dark:text-neutral-300 md:text-base">
              <ButtonPrimary className="bg-yellow-500" type="button" onClick={handleSubmit}>
                Terminer réservation
              </ButtonPrimary>
            </div>
          )}
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`overflow-hidden relative p-4 space-y-6 bg-white rounded-2xl border transition-shadow nc-FlightCardgroup sm:p-6 dark:bg-neutral-900 border-neutral-100 dark:border-neutral-800 hover:shadow-lg ${className}`}
      data-nc-id="FlightCard"
    >
      <div className={`relative sm:pr-20 ${className}`} data-nc-id="FlightCard">
        <a
          href={
            data.payment_status === "succeeded" && isDateNotPassed && !canceled && data.validation == true
              ? `/location/${data?.Location?.id}?reservationId=${data.id}` + "&openconverstaion=true"
              : `/location/${data?.Location?.id}`
          }
          className="absolute inset-0"
        />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex flex-col justify-between">
          {/* FOR MOBILE RESPONSIVE */}
          <div className="block ml-12 space-y-1 lg:hidden">
            <div className="flex items-center font-semibold">
              <div className="flex-1">{data?.Location?.title}</div>
              <div className="flex justify-center w-12">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm font-normal text-neutral-500">{formatDateShort(data?.selectedDate)}</span>
              </div>
            </div>

            <div className="flex items-center font-semibold">
              <div className="flex-1">Prix:</div>
              <div className="flex justify-center w-12">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </div>
              <div className="flex-1 text-right">
                <span className="text-sm font-normal text-neutral-500">{(data?.price / 100).toFixed(2)} €</span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}

          <div className="flex justify-between ml-12 space-y-1">
            <div className="flex-shrink-0 w-24 lg:w-32 hidden lg:block  mr- flex-[4] ">
              <img
                src={`${process.env.REACT_APP_CLOUD_FRONT}${data?.Location?.images[0]}`}
                className="mb-4 w-25"
                alt=""
              />
            </div>
            <div className="hidden lg:block min-w-[150px] flex-[4] ml-12">
              <div className="text-lg font-medium"> {data?.Location?.title}</div>
            </div>

            <div className="hidden lg:block flex-[4] whitespace-nowrap ml-4">
              <div className="text-lg font-medium">Date</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {formatDateShort(data?.selectedDate)}
              </div>
            </div>

            <div className="hidden lg:block flex-[4] whitespace-nowrap ml-4">
              <div className="text-lg font-medium">Prix</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">{data?.price} €</div>
            </div>

            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div>
                {data.payment_status === "succeeded" && data.approuved === true && isDateNotPassed && (
                  <span className="text-xl font-semibold text-secondary-6000">En cours</span>
                )}
                {data?.Location?.reservation_type === "Stricte" &&
                  data.payment_status === "requires_capture" &&
                  data.approuved == null && (
                    <span className="text-xl font-semibold text-yellow-600">En attente </span>
                  )}
                {data?.Location?.reservation_type === "Flexible" &&
                  data.payment_status === "requires_capture" &&
                  data.approuved == null && (
                    <span className="text-xl font-semibold text-yellow-600">En attente </span>
                  )}
                {/* {data.payment_status == "canceled" && data.approuved == false && !canceled && data.validation == true && (
                  <span className="text-xl font-semibold text-red-600">Annulée</span>
                )} */}
                {data.payment_status === "canceled" && !canceled && (
                  <span className="text-xl font-semibold text-red-600">Annulée</span>
                )}
                {data.validation === false && data.detail !== "annulée" && !canceled && isDateNotPassed && (
                  <span className="text-xl font-semibold text-yellow-600">Non complété</span>
                )}
                {canceled && <span className="text-xl font-semibold text-red-600">Annulée</span>}
                {!isDateNotPassed &&
                  isTerminated &&
                  data.payment_status !== "canceled" &&
                  data.approuved === true && <span className="text-xl font-semibold text-gray-600">Terminée</span>}
                {!isDateNotPassed &&
                  !isTerminated &&
                  data.payment_status === "succeeded" &&
                  data.approuved === true && (
                    <span className="text-xl font-semibold text-gray-600">Obsolète </span>
                  )}
              </div>
            </div>

            {data.payment_status === "succeeded" &&
              data.approuved === true &&
              isDateNotPassed &&
              !canceled &&
              data.validation == true && (
                <div className="flex-[4] whitespace-nowrap sm:text-right">
                  <div>
                    <span style={{ cursor: "pointer" }} className="text-xl font-semibold text-secondary-6000">
                      Message
                    </span>
                  </div>
                </div>
              )}
          </div>
          {data.payment_status === "succeeded" &&
            data.approuved == true &&
            isDateNotPassed &&
            !canceled &&
            data.validation == true && (
              <div className="flex justify-between pl-20">
                <div className="text-sm">
                  {" "}
                  <span className="font-bold">Adresse :</span>{" "}
                  {data?.Location?.city + ", " + data?.Location?.address}
                </div>
                <div className="text-sm">
                  {" "}
                  <span className="font-bold">Numéro Téléphone :</span> +33 {data?.Owner?.phone}
                </div>
              </div>
            )}
        </div>
      </div>

      {renderDetail()}
    </div>
  );
};

export default ClientReservationDetailedCard;
