import { zodResolver } from "@hookform/resolvers/zod";
import { CardFooter, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { z } from "zod";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import { getHomeVideoById, updateHomeVideo } from "redux/features/Admin/homeVideo/homeVideoSlice";

export interface AddVideoPageProps {
  className?: string;
}
const MAX_FILE_SIZE = 1024 * 1024 * 2000;
const ACCEPTED_VIDEO_MIME_TYPES = ["video/mp4", "video/mpeg", "video/webm", "video/mov"];
const formSchema = z.object({
  title: z.string().optional(),
  url: z.optional(
    z
      .instanceof(File, {
        message: "video est obligatoire.",
      })
      .refine((file) => file?.size <= MAX_FILE_SIZE, `La taille maximale video est de 2000MB.`)
      .refine(
        (file) => ACCEPTED_VIDEO_MIME_TYPES.includes(file?.type),
        "Le fichier doit être un video au format MP4, MPEG , WEBM et MOV ."
      )
  ),
});

const UpdateVideoHomePage: FC<AddVideoPageProps> = ({ className = "" }) => {
  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const videoState = useAppSelector((state) => state.videoHome);
  useEffect(() => {
    if (id) {
      dispatch(getHomeVideoById(id));
    }
  }, []);

  useEffect(() => {
    if (videoState) {
      if (videoState && videoState.videoData?.url) {
        const file = new File([videoState.videoData.url], "video.mp4", { type: "video/mp4" });
        setValue("url", file);
      }
    }
  }, [videoState]);
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(updateHomeVideo({ data: data, id: id })).then(() => {
      navigate("/admin/dashboard/settings/home-videos");
    });
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    switch (videoState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [videoState.loading]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="grid place-items-center mb-4 h-28"></span>
          <br />
          <Label>Titre du vidéo</Label>
          <br />
          <Input {...register("title")} defaultValue={videoState?.videoData?.title} />

          <div className="pt-3">
            <Label>Vidéo</Label>
          </div>

          {videoState?.videoData ? (
            <div className="mt-2">
              <p className="text-xs">
                Video précédemment :{" "}
                <a
                  href={process.env.REACT_APP_CLOUD_FRONT + videoState?.videoData?.url}
                  className="text-blue-600"
                  target="_blank"
                >
                  {" "}
                  {videoState?.videoData?.title}
                </a>
              </p>
            </div>
          ) : (
            <div className="mt-2">
              <p>Aucun video précédemment téléchargé</p>
            </div>
          )}
          <div className="mt-3">
            <input
              className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
              type="file"
              id="formFile"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setValue("url", file);
                  trigger("url");
                }
              }}
              accept="video/mp4, video/mpeg, video/webm, video/mov"
            />
            {errors.url?.message && <div className="text-xs text-red-500">{errors.url?.message.toString()}</div>}
          </div>

          <CardFooter className="pt-0">
            <Typography variant="small" className="flex justify-center mt-6">
              <ButtonPrimary type="submit" loading={isLoading}>
                Mise à jour
              </ButtonPrimary>
            </Typography>
          </CardFooter>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default UpdateVideoHomePage;
