import { Card, CardBody, CardFooter, Dialog } from "@material-tailwind/react";
import Label from "components/Label/Label";

import ButtonPrimary from "shared/Button/ButtonPrimary";

interface EntityDialogFormProps {
  open: boolean;
  handleClose?: () => void;
  handleDelete?: () => void;
  onSubmitSuccess?: () => void;
  details: { label: string; value: string | number | boolean }[];
  id?: any;
  value?: string;
  title?: string;
}

export const EntityDetails: React.FC<EntityDialogFormProps> = ({
  id,
  open,
  value,
  title,
  details,
  handleClose = () => {},
}) => {
  return (
    <>
      <Dialog size="xs" open={open} handler={handleClose} className="bg-transparent shadow-none">
        <div>
          <Card className="mx-auto w-full max-w-[24rem]">
            <CardBody className="flex flex-col gap-4">
              <div className="font-semibold text-center text-lg">{title}</div>
              <div className="border-b w-full border-neutral-400 dark:border-neutral-700"></div>

              <table className="w-full border-collapse">
                <tbody>
                  {details.map((detail, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 text-gray-600 font-semibold">{detail.label}</td>
                      <td className="py-2 px-4">{detail.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
            <CardFooter className="flex justify-center ">
              <ButtonPrimary
                onClick={handleClose}
                className="bg-red-500 rounded-sm hover:bg-red-500"
                sizeClass="px-4 py-2   sm:px-6"
              >
                Quitter
              </ButtonPrimary>
            </CardFooter>
          </Card>
        </div>
      </Dialog>
    </>
  );
};
