import { zodResolver } from "@hookform/resolvers/zod";
import FormItem from "components/FormItem/FormItem";
import Label from "components/Label/Label";
import __cities from "data/jsons/cities.json";
import { LocationFormProps } from "data/reduxInterfaces";
import { useAppSelector } from "hooks/hooks";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import OnboardingTour, { stepStyle } from "utils/tours/OnboardingTour";
import { z } from "zod";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import toast from "react-hot-toast";
import { Card, List, ListItem } from "@material-tailwind/react";

const formSchema = z.object({
  bank: z.string({
    required_error: "Le compte bancaire est obligatoire.",
    invalid_type_error: "Le compte bancaire est obligatoire.",
  }),
  rib: z
    .string()
    .min(27, { message: "Le RIB doit contenir exactement 27 caractères." })
    .max(27, { message: "Le RIB doit contenir exactement 27 caractères." })
    .refine((value) => value.startsWith("FR"), {
      message: "Le RIB doit commencer par 'FR'.",
    }),

  city: z.string().min(1, { message: "Le ville est obligatoire." }),
  address: z.string().min(1, { message: 'L"addresse est obligatoire.' }),
  address2: z.string().optional(),
  country: z.string().min(1, { message: "Le Pays est obligatoire." }),
  codePostale: z.number({
    required_error: "Le code postale est obligatoire.",
    invalid_type_error: "Le code postale doit étre un nombre.",
  }),
  stripe_url: z.string().optional(),
});

const LocationParticularDetailedData: FC<LocationFormProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  isLoading,
  handleForm,
  data,
  errorLocationForm,
  FormData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      city: data?.city ?? "",
      address: data?.address ?? "",
      address2: data?.address2 ?? "",
      country: data?.country ?? "",
      codePostale: data?.codePostale ?? undefined,
      stripe_url: "eventtobee.com",
    },
    mode: "onSubmit",
  });
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  const steps = [
    {
      selector: ".adresse",
      content: "Ensemble d'informations permettant d'indiquer le lieu de résidence.",
      stepStyle,
    },
    {
      selector: ".ville",
      content: "Région de logements éparpillés dans la campagne..",
      stepStyle,
    },

    {
      selector: ".code-postal",
      content: "Code facilitant le tri et l'acheminement automatique du courrier.",
      stepStyle,
    },

    {
      selector: ".bank ",
      content:
        "Établissement financier qui, en recevant des fonds du public, les utilise pour réaliser des opérations de crédit et d'autres activités financières. ",
    },
    {
      selector: ".iban ",
      content:
        "IBAN signifie « International Bank Account Number ». Il s'agit de l'identifiant international de votre compte bancaire .",
    },
  ];

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFourteenParticular") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const updatelocationStates = useAppSelector((state) => state.location.locationData);
  useEffect(() => {
    if (updatelocationStates) {
      setValue("bank", updatelocationStates.bank);
      setValue("rib", updatelocationStates.rib);
      setValue("stripe_url", updatelocationStates.stripe_url);
    }
  }, [updatelocationStates]);

  const { placesService, placePredictions, isPlacePredictionsLoading, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_MAPS_API_KEY + "&loading=async",
    options: {
      input: "",
      componentRestrictions: {
        country: "fr",
      },
    },
  });

  useEffect(() => {
    if (data) {
      setValue("city", data.city);
      setValue("address", data.address);
      setValue("address2", data.address2);
      setValue("codePostale", data.codePostale);
    }
  }, [data]);

  const [isload, setIload] = useState(isLoading);

  const holdeSelectAddress = async (place_id: string) => {
    setIload(true);
    placesService?.getDetails({ placeId: place_id }, (placeDetails: any, placeStatus: any) => {
      if (placeStatus !== "OK") {
        toast.error("Quelque chose ne fonctionne pas, veuillez réessayer!");
      }
      const parser = new DOMParser();
      const adr_address = parser.parseFromString(placeDetails?.adr_address ?? "", "text/html");
      const streetAddress = adr_address.querySelector(".street-address")?.textContent || "";
      const postalCode = adr_address.querySelector(".postal-code")?.textContent || "";
      const locality = adr_address.querySelector(".locality")?.textContent || "";
      const country_name = adr_address.querySelector(".country-name")?.textContent || "";

      if (streetAddress !== placeDetails?.name) {
        setValue("address", `${placeDetails?.name}, ${streetAddress}`);
      } else {
        setValue("address", streetAddress);
      }
      setValue("codePostale", Number(postalCode));
      setValue("city", locality);
      setValue("country", country_name);

      setIload(false);
    });

    getPlacePredictions({ input: "" });
  };

  return (
    <div className={className}>
      <form
        className="flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div>
          <h2 className="mb-10 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Informations personnelles
          </h2>

          <div className="">
            <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
              <FormItem label="Adresse *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("address")}
                    onChange={(e) => {
                      setValue("address", e.target.value);
                      getPlacePredictions({ input: e.target.value });
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Escape") {
                        getPlacePredictions({ input: "" });
                      }
                    }}
                    loading={isPlacePredictionsLoading || isload}
                  />

                  {placePredictions.length > 0 && (
                    <div className="flex flex-col flex-1 w-auto mt-0 mb-24 h-50">
                      <Card className="w-96">
                        <List>
                          {placePredictions.map((item, index) => (
                            <ListItem key={index} onClick={() => holdeSelectAddress(item.place_id)}>
                              {item.description}
                            </ListItem>
                          ))}
                        </List>
                      </Card>
                    </div>
                  )}

                  {errors.address?.message && (
                    <div className="text-xs text-red-500">{`${errors.address?.message}`}</div>
                  )}
                </div>
              </FormItem>

              <FormItem label="Complément d'adresse">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("address2")}
                  />
                </div>
              </FormItem>
            </div>
            <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
              <FormItem label="Ville *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("city")}
                  />
                </div>
                {errors.city?.message && <div className="text-xs text-red-500">{`${errors.city?.message}`}</div>}
              </FormItem>

              <FormItem label="Code postale *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.codePostale?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    type={"number"}
                    {...register("codePostale", { valueAsNumber: true })}
                  />
                </div>
                {errors.codePostale?.message && (
                  <div className="text-xs text-red-500">{`${errors.codePostale?.message}`}</div>
                )}
              </FormItem>
            </div>

            <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
              <FormItem label="Pays *">
                <div className="md:w-1/2">
                  <Input
                    style={{ minWidth: "350px" }}
                    className={`mt-1.5 ${errors.address?.message ? "border-red-500" : ""}`}
                    disabled={isLoading || isload}
                    placeholder="..."
                    {...register("country")}
                  />
                </div>
                {errors.country?.message && (
                  <div className="text-xs text-red-500">{`${errors.country?.message}`}</div>
                )}
              </FormItem>
            </div>
          </div>
          <h2 className="mb-10 mt-5 flex items-center text-xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Informations de compte bancaire
          </h2>

          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="bank">
              <Label>BIC </Label>
              <Input
                {...register("bank")}
                disabled={isLoading}
                type="text"
                name="bank"
                placeholder="Nom du bank"
                style={{ minWidth: "350px" }}
              />
              {errors.bank?.message && <div className="text-xs text-red-500">{errors.bank?.message}</div>}
            </div>
          </div>
          <div className="flex-row justify-center gap-8 mt-4 md:flex md:gap-7">
            <div className="iban">
              <Label>RIB</Label>
              <Input
                {...register("rib")}
                disabled={isLoading}
                type="text"
                name="rib"
                placeholder="FR11 XXXX XXXX XXXX XXXX XXXX XXXX "
                style={{ minWidth: "350px" }}
              />
              {errors.rib?.message && <div className="mt-3 text-xs text-red-500">{errors.rib?.message}</div>}
            </div>
          </div>
        </div>
      </form>
      <OnboardingTour steps={steps} page_name={"CompanyDetailedDataForm"}></OnboardingTour>
    </div>
  );
};

export default LocationParticularDetailedData;
