import { useAppSelector } from "hooks/hooks";
import { useEffect, useState } from "react";
import { socket } from "utils/socket";

export interface NotificationData {
  notifications: any[];
  unreadCount: number;
  totalNotifications: number;
}

const useNotifications = () => {
  const userState = useAppSelector((state) => state?.auth?.user);

  const [notifications, setNotifications] = useState<NotificationData>({
    notifications: [],
    unreadCount: 0,
    totalNotifications: 0,
  });
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [notification, setNotification] = useState<any>(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    if (userState) {
      socket.emit("load-notifications", { userId: userState?.id, limit: 3, offset: 0 });
      socket.on("fetched-notifications", (data) => {
        setNotifications(data);
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [userState]);

  useEffect(() => {
    socket.on("get-notification", (data: any) => {
      if (data) {
        setNotification(data);
        setNotifications((prevData) => {
          return {
            notifications: [data, ...prevData.notifications],
            unreadCount: prevData.unreadCount + 1,
            totalNotifications: data.totalNotifications,
          };
        });

        setTimeout(() => {
          setNotification(null);
        }, 3000);
      }
    });
  }, []);

  const loadNotifications = (userId: string, limit: number, offset: number) => {
    setLoading(true);

    socket.emit("load-more-notifications", { userId, limit, offset });

    socket.on("notifications-loaded", (data) => {
      setNotifications((prev) => {
        const newNotifications = Array.isArray(data.notifications) ? data.notifications : [];

        return {
          notifications: [...prev.notifications, ...newNotifications],
          unreadCount: data.unreadCount || 0,
          totalNotifications: data.totalNotifications ?? prev.totalNotifications,
        };
      });

      setOffset((prev) => prev + 10);
      setLoading(false);
    });
  };

  const loadMoreNotifications = () => {
    if (userState?.id) {
      loadNotifications(String(userState.id), 10, offset);
    }
  };

  return {
    notifications,
    notification,
    loadMoreNotifications,
    loading,
    setNotification,
    isSmallScreen,
  };
};

export default useNotifications;
