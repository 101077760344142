import { MapPinIcon } from "@heroicons/react/24/outline";
import { FC, useEffect, useRef, useState } from "react";

export interface InputFieldProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onChange?: (valu: string) => void;
  onSelectEvent?: (value: any) => void;
  clear: boolean;
  propEventValue?: string | null;
  name?: string;
}

const InputField: FC<InputFieldProps> = ({
  autoFocus = false,
  placeHolder = "text",
  desc = "Saitit votre text",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectEvent,
  name = "text",
  clear,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (clear) {
      setValue("");
    }
  }, [clear]);

  const eventClickOutsideDiv = (region: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(region.target as Node)) {
      return;
    }
    setShowPopover(false);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (onSelectEvent) {
      onSelectEvent(e.target.value);
    }
  };
  return (
    <div className={`flex relative ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            name={name}
            autoComplete={name}
            ref={inputRef}
            type="text"
            value={value}
            onChange={handleInputChange}
            className="block font-semibold truncate bg-transparent border-none focus:outline-none focus:ring-0 focus:bg-transparent focus:placeholder-neutral-300 xl:text-lg placeholder-neutral-800 dark:placeholder-neutral-200"
            placeholder={placeHolder}
            autoFocus={showPopover}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light">
            <span className="">{value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>

      {showPopover && (
        <div
          className={`absolute top-1/2 z-0 self-center h-8 bg-white -translate-y-1/2 dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}
    </div>
  );
};

export default InputField;
