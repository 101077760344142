import { Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface PrestationModalProps {
  open: boolean;
  onClose: () => void;
}
export function PrestationModal({ open = false, onClose }: PrestationModalProps) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/add-service");
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <>
      <Dialog
        open={open}
        handler={handleClose}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader className="flex justify-center"> Félicitation 🎉</DialogHeader>
        <hr></hr>
        <DialogBody className="flex justify-center">
          Vous pouvez désormais ajouter votre prestation en cliquant sur le bouton .
        </DialogBody>

        <DialogFooter className="flex justify-center">
          <ButtonPrimary onClick={handleNavigate}>Ajouter prestation</ButtonPrimary>
        </DialogFooter>
      </Dialog>
    </>
  );
}
