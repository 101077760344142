import { FC, MutableRefObject, useEffect, useRef, useState } from "react";

import AlertModalMessage from "components/AlertModal/AlertModalMessage";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

import { z } from "zod";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  checkTokenForgetPassword,
  resetPassword,
  setCheckTokenForgetPasswordError,
  setResetPasswordError,
} from "redux/features/forgetPassword/forgetPasswordSlice";
import { Spinner } from "@material-tailwind/react";
import { initState } from "redux/features/signUp/signUpSlice";

const formSchema = z
  .object({
    password: z
      .string()
      .regex(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]).{8,26}$/,
        "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character"
      ),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Le mot de passe semble erroné. Veuillez réessayer.",
    path: ["passwordConfirm"],
  });

const ResetPassword: FC<{}> = () => {
  const { token } = useParams();

  const dispatch = useAppDispatch();

  const forgotPasswordState = useAppSelector((state) => state.forgetPassword);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);

  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
    mode: "onSubmit",
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    dispatch(
      resetPassword({
        token,
        newPassword: data.password,
      })
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassowrd);
  };

  useEffect(() => {
    dispatch(initState());
    dispatch(setCheckTokenForgetPasswordError());
    dispatch(setResetPasswordError());

    dispatch(checkTokenForgetPassword(token));
  }, []);

  useEffect(() => {
    const updatedRequirements = {
      minLength: getValues("password")?.length >= 8,
      hasLowercase: /[a-z]/.test(getValues("password")),
      hasUppercase: /[A-Z]/.test(getValues("password")),
      hasNumber: /[0-9]/.test(getValues("password")),
      hasSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]/.test(getValues("password")),
    };

    setPasswordRequirements(updatedRequirements);
  }, [watch("password")]);

  useEffect(() => {
    switch (forgotPasswordState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoadingPage(false);
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoadingPage(false);
        setIsLoading(false);
        break;
      }
    }
  }, [forgotPasswordState.loading]);

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Nouveau mot de passe </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Saisissez votre nouveau mot de passe
        </h2>

        {isLoadingPage && (
          <div className="flex items-center justify-center mb-80">
            <Spinner color="blue" className="w-24 h-24" />
          </div>
        )}

        {!isLoadingPage && !forgotPasswordState.isTokenValid && (
          <div className="flex flex-col items-center justify-center pb-20">
            <p className="text-lg text-center">Votre session de réinitialisation de mot de passe a expiré.</p>
            <p className="pb-10 text-lg text-center">Souhaitez-vous recommencer le processus ?</p>

            <ButtonPrimary href={"/forgot-password"}>Mot de passe oublié</ButtonPrimary>
          </div>
        )}

        {!isLoadingPage && forgotPasswordState.passwordHasChanged && (
          <div className="flex flex-col items-center justify-center pb-20">
            <p className="pb-10 text-lg text-center">Nous avons réinitialisé votre mot de passe avec succès.</p>

            <ButtonPrimary href={"/login"}>Login</ButtonPrimary>
          </div>
        )}

        {!isLoadingPage && forgotPasswordState.resetPasswordError && (
          <div className="flex flex-col items-center justify-center pb-20">
            <p className="pb-10 text-lg text-center text-red-500">Quelque chose ne fonctionne pas</p>
          </div>
        )}

        {!isLoadingPage && forgotPasswordState.isTokenValid && !forgotPasswordState.passwordHasChanged && (
          <div className="max-w-md mx-auto">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
              <label className="relative block">
                <span className="text-neutral-800 dark:text-neutral-200">Nouveau mot de passe</span>
                <div className="relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Saisir votre nouveau mot de passe"
                    className={`w-full pr-3 mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                    disabled={isLoading}
                    {...register("password")}
                    onCopy={(e) => e.preventDefault()}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>

                <div className="flex items-center">
                  {passwordRequirements.minLength ? (
                    <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                  ) : (
                    <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                  )}
                  <span className={`mt-1 text-sm ${passwordRequirements.minLength ?? "text-green-700"}`}>
                    8 caractères minimum
                  </span>
                </div>

                <div className="flex items-center">
                  {passwordRequirements.hasLowercase ? (
                    <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                  ) : (
                    <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                  )}
                  <span className={`mt-1 text-sm ${passwordRequirements.hasLowercase ?? "text-green-700"}`}>
                    1 minuscule
                  </span>
                </div>

                <div className="flex items-center">
                  {passwordRequirements.hasUppercase ? (
                    <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                  ) : (
                    <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                  )}
                  <span className={`mt-1 text-sm ${passwordRequirements.hasUppercase ?? "text-green-700"}`}>
                    1 majuscule
                  </span>
                </div>

                <div className="flex items-center">
                  {passwordRequirements.hasNumber ? (
                    <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                  ) : (
                    <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                  )}
                  <span className={`mt-1 text-sm ${passwordRequirements.hasNumber ?? "text-green-700"}`}>
                    1 chiffre
                  </span>
                </div>

                <div className="flex items-center">
                  {passwordRequirements.hasSpecialChar ? (
                    <CheckIcon className="w-4 h-4 mt-1 mr-1 text-green-700" />
                  ) : (
                    <XMarkIcon className="w-4 h-4 mt-1 mr-1" />
                  )}
                  <span className={`mt-1 text-sm ${passwordRequirements.hasSpecialChar ?? "text-green-700"}`}>
                    1 caractère spécial
                  </span>
                </div>

                {errors.password?.message && (
                  <div className="mt-1 text-xs text-red-500">{errors.password?.message}</div>
                )}
              </label>

              <label className="relative block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Confirmation du nouveau mot de passe
                </span>
                <div className="relative">
                  <Input
                    type={showConfirmPassowrd ? "text" : "password"}
                    placeholder="Saisir à nouveau votre mot de passe"
                    className={`w-full pr-3 mt-1 ${errors.password?.message ? "border-red-500" : ""}`}
                    disabled={isLoading}
                    {...register("passwordConfirm")}
                    onCopy={(e) => e.preventDefault()}
                  />
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassowrd ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {errors.passwordConfirm?.message && (
                  <div className="mt-1 text-xs text-red-500">{errors.passwordConfirm?.message}</div>
                )}
              </label>

              <ButtonPrimary type="submit" disabled={isLoading} loading={isLoading}>
                Confirmer
              </ButtonPrimary>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
