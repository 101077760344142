import { FC, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import TableData from "./TableData";
import { Spinner } from "@material-tailwind/react";

interface ListProps {
  navLinks?: { to: string; label: string }[];
  getDataAction?: (page: number) => void;
  loading?: boolean;
  data?: any[];
  columns?: { title: string; accessor: string | ((item: any) => any) }[];
  onEdit?: (item: any) => void;
  onDelete?: (item: any) => void;
  onConsult?: (item: any) => void;
  showConsult?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  title?: string;
  totalPages?: number;
}

const List: FC<ListProps> = ({
  getDataAction,
  loading,
  data = [],
  columns = [],
  onEdit = () => {},
  onDelete = () => {},
  onConsult = () => {},
  showConsult = true,
  showEdit = true,
  showDelete = true,
  totalPages,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (getDataAction) {
      getDataAction(pageNumber);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <Spinner style={{ width: "48px", height: "48px" }} color="blue" />
        </div>
      ) : data.length === 0 ? (
        <div className="flex items-center justify-center h-64">
          <p className="text-gray-500">Aucune donnée disponible</p>
        </div>
      ) : (
        <>
          <TableData
            columns={columns}
            data={data}
            onEdit={onEdit}
            onDelete={onDelete}
            onConsult={onConsult}
            showConsult={showConsult}
            showEdit={showEdit}
            showDelete={showDelete}
          />

          <div className="flex justify-center mt-4">
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
        </>
      )}
    </div>
  );
};

export default List;
