import { GrAnnounce } from "react-icons/gr";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";

export const statisticsSubItems = [
  {
    to: "/admin/dashboard/statistiques/clients",
    icon: <i className="las la-user text-1xl dark:text-white"></i>,
    label: "Clients",
  },
  {
    to: "/admin/dashboard/statistiques/agents",
    icon: <i className="las la-hands-helping text-1xl dark:text-white"></i>,
    label: "Prestataires",
  },
  {
    to: "/admin/dashboard/statistiques/owner",
    icon: <i className="las la-home text-1xl dark:text-white"></i>,
    label: "Propriétaires",
  },
];

export const locationsSubItems = [
  {
    to: "/admin/dashboard/locations/unapproved",
    icon: <i className="las la-place-of-worship text-1xl dark:text-white"></i>,
    label: "Liste des espaces",
  },
  {
    to: "/admin/dashboard/locations/categories",
    icon: <HiOutlineSquare3Stack3D strokeWidth={3} className="w-5 h-3 dark:text-white" />,
    label: "Categories",
  },
  {
    to: "/admin/dashboard/locations/equipments",
    icon: <i className="las la-tools text-1xl dark:text-white"></i>,
    label: "Équipements ",
  },
  {
    to: "/admin/dashboard/locations/events",
    icon: <i className="las la-glass-cheers text-1xl dark:text-white"></i>,
    label: "Évènements ",
  },
  {
    to: "/admin/dashboard/locations/atmospheres",
    icon: <i className="las la-laugh-beam text-1xl dark:text-white"></i>,
    label: "Ambiances ",
  },
  {
    to: "/admin/dashboard/locations/playgrounds",
    icon: <i className="las la-hand-scissors text-1xl dark:text-white"></i>,
    label: "Aire de jeux ",
  },
  {
    to: "/admin/dashboard/locations/rules",
    icon: <i className="las la-exclamation-triangle text-1xl dark:text-white"></i>,
    label: "Régles ",
  },
];

export const usersSubItems = [
  {
    to: "/admin/dashboard/users/creators",
    icon: <i className="las la-blog text-1xl dark:text-white"></i>,
    label: "Rédacteur web",
  },

  {
    to: "/admin/dashboard/users/owners",
    icon: <i className="las la-home text-1xl dark:text-white"></i>,
    label: "Propriétaire ",
  },
  {
    to: "/admin/dashboard/users/agents",
    icon: <i className="las la-hands-helping text-1xl dark:text-white"></i>,
    label: "Prestataire",
  },
  {
    to: "/admin/dashboard/users/clients",
    icon: <i className="las la-user text-1xl dark:text-white"></i>,
    label: "Client ",
  },
];

export const servicesSubItems = [
  {
    to: "/admin/dashboard/prestations",
    icon: <i className="las la-hands-helping dark:text-white"></i>,
    label: "Prestations",
  },

  {
    to: "/admin/dashboard/prestations/fields",
    icon: <i className="las la-stream dark:text-white"></i>,
    label: "Types de prestation ",
  },
];

export const configSubItems = [
  {
    to: "/admin/dashboard/settings/slider",
    icon: <i className="las la-images dark:text-white"></i>,
    label: "Slider",
  },
  {
    to: "/admin/dashboard/settings/videos",
    icon: <i className="las la-play-circle dark:text-white"></i>,
    label: "Vidéos",
  },
  {
    to: "/admin/dashboard/settings/home-videos",
    icon: <i className="las la-play-circle dark:text-white"></i>,
    label: "Home vidéo ",
  },
  {
    to: "/admin/dashboard/settings/emails",
    icon: <i className="las la-envelope dark:text-white"></i>,
    label: "Emails ",
  },
  {
    to: "/admin/dashboard/settings/stripe-connect",
    icon: <i className="lab la-stripe-s dark:text-white"></i>,
    label: "Stripe connect ",
  },
];

export const reservationsSubItems = [
  {
    to: "/admin/dashboard/reservations/tentatives",
    icon: <i className="las la-calendar-check dark:text-white"></i>,
    label: "Tentatives",
  },
  {
    to: "/admin/dashboard/reservations/pending",
    icon: <i className="las la-calendar-check dark:text-white"></i>,
    label: "En attentes",
  },
  {
    to: "/admin/dashboard/reservations/confirmed",
    icon: <i className="las la-calendar-check dark:text-white"></i>,
    label: "En cours ",
  },
  {
    to: "/admin/dashboard/reservations/canceled/owner",
    icon: <i className="las la-calendar-plus dark:text-white"></i>,
    label: "Demandes annulées ",
  },
  {
    to: "/admin/dashboard/reservations/canceled/client",
    icon: <i className="las la-calendar-plus dark:text-white"></i>,
    label: "Annulées par clients ",
  },
];

export const stripeSubItems = [
  {
    to: "/admin/dashboard/stripe/connected-accounts",
    icon: <i className="las la-calendar-check dark:text-white"></i>,
    label: "Comptes connects",
  },

  {
    to: "/admin/dashboard/stripe/transactions",
    icon: <i style={{ fontSize: "20px" }} className="las la-exchange-alt dark:text-white"></i>,
    label: "Transactions ",
  },
  {
    to: "/admin/dashboard/stripe/coupons",
    icon: <i style={{ fontSize: "20px" }} className="las la-exchange-alt dark:text-white"></i>,
    label: "Code promo ",
  },
];
