import { Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface WarningModalProps {
  open: boolean;
  handleClose?: () => void;
  handleDelete?: () => void;
  id?: any;
  title?: string;
  isLoading?: boolean;
}
const WarningModal: React.FC<WarningModalProps> = ({
  open,
  handleClose = () => {},
  handleDelete,
  isLoading,
  title,
}) => {
  return (
    <>
      <Dialog open={open} handler={handleClose} className="w-11/12 ">
        <DialogHeader>
          <Typography variant="h5" color="blue-gray">
            Votre attention est requise !
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid gap-4 place-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-16 h-16 text-red-500"
          >
            <path
              fillRule="evenodd"
              d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
              clipRule="evenodd"
            />
          </svg>
          <Typography color="black" variant="h4">
            Êtes-vous sûr de vouloir supprimer?
          </Typography>
          <Typography className="font-normal text-center">
            Confirmez-vous la suppression de cet élément <span className="text-red-600"> {title} </span> ? Cette
            action ne peut pas être annulée.
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <ButtonSecondary onClick={handleClose} className="dark:bg-white dark:text-black">
            Annuler
          </ButtonSecondary>
          <ButtonPrimary className="bg-red-600" onClick={handleDelete} loading={isLoading}>
            Confirmer{" "}
          </ButtonPrimary>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default WarningModal;
