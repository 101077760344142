import React from "react";
import Badge from "shared/Badge/Badge";

interface Column {
  title: string;
  accessor: string | ((item: any) => string);
}

interface TableDataProps {
  columns: Column[];
  data: any;
  onEdit: (item: any) => void;
  onDelete: (item: any) => void;
  onConsult: (item: any) => void;
  showConsult?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
}

const TableData: React.FC<TableDataProps> = ({
  columns,
  showEdit,
  showConsult,
  showDelete,
  data,
  onConsult,
  onEdit,
  onDelete,
}) => {
  return (
    <table className="min-w-full text-sm font-light text-center bg-white shadow-sm">
      <thead className="font-medium text-white border-b bg-neutral-800 dark:border-neutral-500 dark:bg-neutral-900">
        <tr>
          {columns.map((col, index) => (
            <th key={index} className="px-6 py-4">
              {col.title}
            </th>
          ))}
          <th scope="col" className="px-6 py-4">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item: any, index: number) => (
          <tr className="border-b dark:border-neutral-500" key={index}>
            {columns.map((col, colIndex) => (
              <td key={colIndex} className="font-medium whitespace-nowrap">
                {typeof col.accessor === "function" ? col.accessor(item) : item[col.accessor]}
              </td>
            ))}
            <td className="px-6 py-4 space-x-3 whitespace-nowrap">
              {showConsult && (
                <Badge
                  name={<i className="text-2xl cursor-pointer las la-eye" onClick={() => onConsult(item)}></i>}
                />
              )}
              {showEdit && (
                <Badge
                  color="green"
                  name={<i className="text-2xl cursor-pointer las la-edit" onClick={() => onEdit(item)}></i>}
                />
              )}

              {showDelete && (
                <Badge
                  color="red"
                  name={
                    <i className="text-2xl cursor-pointer las la-trash-alt" onClick={() => onDelete(item)}></i>
                  }
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableData;
