import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Announce } from "data/types";
import { RootState } from "redux/store";
import { announceApi } from "./announceAPI";

interface ErrorValidation {
  path: string;
  msg: string;
}

interface AnnounceState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  announceError: string;
  error: SerializedError | "";
  announceErrorValidation: ErrorValidation[];
  announceNotExist?: boolean | undefined;
  allAnnounces?: Announce[];
  totalPages: number;
  totalSteps: number;
  currentStep: number;
  announceData?: Announce;
  isUpdated?: Announce;
}

const initialState: AnnounceState = {
  loading: "idle",
  announceError: "",
  error: "",
  announceErrorValidation: [],
  allAnnounces: undefined,
  announceNotExist: undefined,
  totalPages: 0,
  totalSteps: 4,
  currentStep: 1,
  announceData: undefined,
  isUpdated: undefined,
};

export const getAnnouncesByAdmin = createAsyncThunk(
  "getApprovedAnnounces",
  async ({ page, status }: { page: number; status: string }) => {
    return await announceApi.getAnnouncesByAdmin(page, status);
  }
);

export const createAnnounce = createAsyncThunk("createAnnounce", async (data: any) => {
  return await announceApi.createAnnounce(data);
});

export const updateAnnounce = createAsyncThunk("updateAnnounce", async ({ data, id }: { data: any; id: any }) => {
  return await announceApi.updateAnnounce(data, id);
});

export const getAnnounceAllDataById = createAsyncThunk("getAnnounceAllDataById", async (id: string) => {
  return await announceApi.getAnnounceAllDataById(id);
});

export const updateAnnounceStatus = createAsyncThunk(
  "updateAnnounceStatus",
  async ({ data, id }: { data: any; id: any }) => {
    return await announceApi.updateAnnounceStatus(data, id);
  }
);

export const getAnnouncesByUserId = createAsyncThunk("getAnnouncesByUserId", async (page: number) => {
  return await announceApi.getAnnouncesByUserId(page);
});

export const updateAnnounceStatusByUserId = createAsyncThunk(
  "updateAnnounceStatusByUserId",
  async ({ data, id }: { data: any; id: any }) => {
    return await announceApi.updateAnnounceStatusByUserId(data, id);
  }
);

export const deleteAnnounce = createAsyncThunk("deleteAnnounce", async (id: number) => {
  return await announceApi.deleteAnnounce(id);
});

export const getAnnounces = createAsyncThunk("getAnnounces", async (page: number) => {
  return await announceApi.getAnnounces(page);
});

export const getAnnouncesBySlug = createAsyncThunk("getAnnouncesBySlug", async (slug: string) => {
  return await announceApi.getAnnouncesBySlug(slug);
});

export const getFilteredAnnounces = createAsyncThunk(
  "getFilteredAnnounces",
  async ({ page, filters }: { page: number; filters: any }) => {
    return await announceApi.getFilteredAnnounces(page, filters);
  }
);

export const announceSlice = createSlice({
  name: "Announce",
  initialState,
  reducers: {
    initState(state) {
      return {
        ...state,
        loading: "idle",
        serviceError: "",
        error: "",
        totalSteps: 4,
        currentStep: 1,
        isUpdated: undefined,
        oldImages: undefined,
      };
    },
    setAnnounceData(state, action) {
      state.announceData = action.payload;
      state.isUpdated = action.payload;
    },
    nextStep(state) {
      state.currentStep++;
    },
    previousStep(state) {
      state.currentStep--;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnouncesByAdmin.fulfilled, (state, action) => {
      state.allAnnounces = action.payload.announces;
      state.totalPages = action.payload.totalPages;
    });

    builder.addCase(createAnnounce.pending, (state, action) => {
      state.loading = "pending";
      state.announceError = "";
    });
    builder.addCase(createAnnounce.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.announceData = action.payload.data;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.announceErrorValidation = action.payload.error;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.announceError = action.payload.error;
      }
    });
    builder.addCase(createAnnounce.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(updateAnnounce.pending, (state, action) => {
      state.loading = "pending";
      state.announceError = "";
    });
    builder.addCase(updateAnnounce.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.announceData = action.payload.data;
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.announceErrorValidation = action.payload.error;
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.announceError = action.payload.error;
      }
    });
    builder.addCase(updateAnnounce.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(getAnnounceAllDataById.pending, (state, action) => {
      state.loading = "pending";
      state.announceError = "";
    });
    builder.addCase(getAnnounceAllDataById.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
        state.announceData = action.payload.data.announce;
      }
      if (action.payload.status === "NOT_FOUND") {
        state.announceError = action.payload.message;
        state.announceNotExist = true;
      }
      if (action.payload.status === "ERROR") {
        state.announceError = action.payload.error;
      }
    });
    builder.addCase(getAnnounceAllDataById.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(updateAnnounceStatus.pending, (state, action) => {
      state.loading = "pending";
      state.announceError = "";
    });
    builder.addCase(updateAnnounceStatus.fulfilled, (state, action) => {
      if (action.payload.status === "OK") {
        state.loading = "succeeded";
      }
      if (action.payload.status === "VALIDATION") {
        state.loading = "failed";
        state.announceError = action.payload.error[0].msg ?? "failed";
      }
      if (action.payload.status === "ERROR") {
        state.loading = "failed";
        state.announceError = action.payload.error;
      }
    });
    builder.addCase(updateAnnounceStatus.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(getAnnouncesByUserId.fulfilled, (state, action) => {
      state.allAnnounces = action.payload.announces;
      state.totalPages = action.payload.totalPages;
    });

    builder.addCase(updateAnnounceStatusByUserId.pending, (state, action) => {
      state.loading = "pending";
      state.announceError = "";
    });
    builder.addCase(updateAnnounceStatusByUserId.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });
    builder.addCase(updateAnnounceStatusByUserId.rejected, (state, action) => {
      state.loading = "failed";
    });

    builder.addCase(getAnnounces.fulfilled, (state, action) => {
      state.allAnnounces = action.payload.announces;
      state.totalPages = action.payload.totalPages;
    });

    builder.addCase(getAnnouncesBySlug.fulfilled, (state, action) => {
      state.announceData = action.payload;
    });

    builder.addCase(getFilteredAnnounces.fulfilled, (state, action) => {
      state.allAnnounces = action.payload.announces;
      state.totalPages = action.payload.totalPages;
    });
  },
});

export const { initState, setAnnounceData, nextStep, previousStep } = announceSlice.actions;

export const selectCount = (state: RootState) => state.announce;
export default announceSlice.reducer;
