import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardBody, CardFooter, Dialog, Input, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { DataSchemaType, schema } from "data/validation";
import { useAppDispatch } from "hooks/hooks";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface EntityDialogFormProps {
  open: boolean;
  handleClose?: () => void;
  handleDelete?: () => void;
  onSubmitSuccess?: () => void;
  id?: any;
  value?: string;
  isLoading?: boolean;
  addfn?: (payload: { designation: string }) => void;
  updatefn?: (id: any, payload: { designation: any }) => void;
  addfnTitle?: string;
  updatefnTitle?: string;
}

export const EntityDialogForm: React.FC<EntityDialogFormProps> = ({
  id,
  addfn,
  updatefn,
  open,
  value,
  addfnTitle,
  onSubmitSuccess,
  updatefnTitle,
  handleClose = () => {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataSchemaType>({ resolver: zodResolver(schema) });
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: { designation: string }) => {
    setLoading(true);
    const { designation } = data;

    if (id && updatefn) {
      await updatefn({ designation }, id);
    } else if (addfn) {
      await addfn({ designation });
    }
    setLoading(false);
    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
    handleClose();
  };

  return (
    <>
      <Dialog size="xs" open={open} handler={handleClose} className="bg-transparent shadow-none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto w-full max-w-[24rem]">
            <CardBody className="flex flex-col gap-4">
              <Typography variant="h4" color="blue-gray">
                {id ? updatefnTitle : addfnTitle}
              </Typography>
              <Label className="font-normal">Désignation</Label>
              <Input size="lg" defaultValue={id ? value : "exemple"} {...register("designation")} />
              {errors.designation && <div className="text-xs text-red-500 ">{errors.designation.message}</div>}
            </CardBody>
            <CardFooter className="flex justify-between ">
              <ButtonPrimary
                onClick={handleClose}
                className="bg-red-500 rounded-sm hover:bg-red-500"
                sizeClass="px-4 py-2   sm:px-6"
              >
                Annuler
              </ButtonPrimary>

              <ButtonPrimary type="submit" loading={loading} className="rounded-sm " sizeClass="px-4 py-2 sm:px-6">
                {id ? "Modifier" : " Confirmer"}
              </ButtonPrimary>
            </CardFooter>
          </Card>
        </form>
      </Dialog>
    </>
  );
};
