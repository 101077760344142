import SearchForm from "components/SearchFilters/SearchForm";
import React, { useEffect, useState } from "react";

interface FiltersPageProps {
  onApplyFilters: (filters: any) => void;
  fields?: string | null;
  region?: string | null;
  handleDelete?: any;
}

const ServiceFiltersPage: React.FC<FiltersPageProps> = ({ onApplyFilters, fields, region, handleDelete }) => {
  const [availability, setAvailability] = useState(!!region ? region : "");
  const [field, setField] = useState(!!fields ? fields : "");
  const [searchClicked, setSearchClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleApplyFields = (selectedField: any) => {
    setField(selectedField);
  };

  const handleApplyRegion = (field: string, selectedRegion: string) => {
    setAvailability(selectedRegion);
  };

  useEffect(() => {
    if (searchClicked) {
      onApplyFilters({ field, region: availability });
      setSearchClicked(false);
    }
    if (clearClicked) {
      setField("");
      setAvailability("");
      onApplyFilters({});
      setClearClicked(false);
    }
  }, [searchClicked, clearClicked, availability]);

  return (
    <div>
      <div className="flex">
        <SearchForm
          fields={{ region: true, field: true }}
          onSelect={handleApplyRegion}
          onSelectField={handleApplyFields}
          searchClicked={searchClicked}
          handleSearchClick={() => setSearchClicked(true)}
          handleDelete={() => setClearClicked(true)}
        />
      </div>
      <div style={containerStyle}></div>
    </div>
  );
};

export default ServiceFiltersPage;
