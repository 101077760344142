import { Squares2X2Icon } from "@heroicons/react/24/outline";
import StartRating from "components/StartRating/StartRating";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchConditionById,
  fetchEquipmentById,
  fetchExtraById,
  fetchLocationById,
  fetchOwnerLocations,
  fetchPricesByIdLocation,
} from "redux/features/locations/locationSlice";
import { fetchOwnerRating } from "redux/features/owners/ownersSlice";
import { fetchReservationByLocation, fetchfullyDays } from "redux/features/reservations/reservationSlice";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import DetailPagetLayout from "../Layout";
import Modal from "../../../../../components/ListingImageGallery/components/Modal";
import { getMinPriceByIdLocation } from "redux/features/location/locationSlice";
import { Location } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FaFileDownload } from "react-icons/fa";
import { getOwnerById } from "redux/features/auth/authSlice";

interface LocationMinPrice {
  locationId: string;
  minPrice?: number;
}

interface Client {
  adresseImg_url?: string;
  iban_url?: string;
  identityBImg_url?: string;
  identityFImg_url?: string;

  companyCertifImg_url?: string;
}

// Define the type for LocationState
interface LocationState {
  Client?: Client;
}

const StayDetailPageContainer: FC<{}> = () => {
  const [locationMap, setLocationMap] = useState<any[]>([]);
  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  let locationState = useAppSelector((state) => state.featureLocation.locationDetail);
  let ratingOwnerState = useAppSelector((state) => state.featureOwner.rating);
  let ownerState = useAppSelector((state) => state.auth.owner);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await dispatch(fetchLocationById(id));

        if (locationState?.userId) {
          await Promise.all([dispatch(getOwnerById(locationState?.userId))]);
        }
      }
    };

    fetchData();
  }, [id, locationState.Client.id]);

  const [fileTypes, setFileTypes] = useState<Record<string, "image" | "pdf">>({});

  useEffect(() => {
    const determineFileType = (url: string): "image" | "pdf" => {
      if (url.endsWith(".pdf")) {
        return "pdf";
      } else {
        return "image";
      }
    };

    const urls: Array<keyof Client> = [
      "identityFImg_url",
      "identityBImg_url",
      "iban_url",
      "adresseImg_url",
      "companyCertifImg_url",
    ];

    const types = urls.reduce(
      (acc, key) => {
        const url = locationState?.Client?.[key];
        if (url) {
          acc[key] = determineFileType(url);
        }
        return acc;
      },
      {} as Record<keyof Client, "image" | "pdf">
    );

    setFileTypes(types);
  }, [locationState]);

  // const [lat, lng] = locationMap.map((item) => {
  //     const match = item.match(/(\d+\.\d+)/);
  //     return match ? match[0] : '';
  // });
  const lat = 48.8584;
  const lng = 2.2945;
  const googleMapsURL = `https://www.google.com/maps/embed/v1/place?key=${
    process.env.REACT_APP_MAPS_API_KEY
  }&q=${Number(lat)},${Number(lng)}`;

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [clickedImages, setClickedImages] = useState<string[]>([]);
  const handleImageClick = (imageUrl: string) => {
    setClickedImages((prevImages) => [...prevImages, imageUrl]);
    setModalOpen(true);
  };

  const priceData = useAppSelector((state) => state.location?.locationMinPrice) as LocationMinPrice | undefined;

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex items-center justify-between">
          <Badge
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">{locationState && locationState?.CategoryLocation?.designation}</span>
              </div>
            }
          />
          <Badge
            name={
              <div className="flex items-center">
                <i className="las la-map-marker"></i>
                <span className="ml-1">{locationState?.city}</span>
              </div>
            }
            color="yellow"
          />
        </div>

        <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">{locationState?.title}</h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating point={locationState?.averageLikes} reviewCount={locationState?.commentCount} />

          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {locationState?.address}, {locationState?.address2}
            </span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <a href={`/owners/${locationState?.Client?.id}`}>
              <Avatar
                hasChecked
                sizeClass="h-10 w-10"
                radius="rounded-full"
                imgUrl={
                  locationState?.Client?.client_url
                    ? process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.client_url
                    : ""
                }
              />
            </a>
            <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
              Publié par :{" "}
              <span className="font-medium text-neutral-900 dark:text-neutral-200">
                <span>
                  {locationState?.Client?.name} {locationState?.Client?.lastName}
                </span>
              </span>
            </span>
          </div>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between space-x-8 text-sm xl:justify-start xl:space-x-12 text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-expand"></i>
            <span className="">
              {locationState?.surface} <span className="hidden sm:inline-block">Superficie</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-user"></i>
            <span className="">
              {locationState?.visitor} <span className="hidden sm:inline-block">Capacité d'accueil</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-clock"></i>
            <span className="">
              {locationState?.startHour}-{locationState?.endHour}
            </span>
          </div>
          {locationState?.handicap && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl lab la-accessible-icon"></i>
              <span className="">Accessible </span>
            </div>
          )}
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-toilet"></i>
            <span className="">WC {locationState?.wc}</span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-parking"></i>
            <span className="">
              Parking {locationState?.parking} <span className="hidden sm:inline-block"></span>
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between space-x-8 text-sm xl:justify-start xl:space-x-12 text-neutral-700 dark:text-neutral-300">
          {locationState?.water && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl las la-shower"></i>
              <span className="">Point d'eau </span>
            </div>
          )}

          {locationState?.light && (
            <div className="flex items-center space-x-3">
              <i className="text-2xl las la-lightbulb"></i>
              <span className="">Eclairage nocturne </span>
            </div>
          )}

          <div className="flex items-center space-x-3">
            <i className="text-2xl las la-money-bill-wave"></i>
            <span className="">
              A partir de {priceData && priceData?.minPrice} €/heure{" "}
              <span className="hidden sm:inline-block"></span>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const [allDescs, setAllDesc] = useState(true);
  const renderSection2 = () => {
    const desc = locationState?.description.slice(0, 675);
    const allDesc = locationState?.description;
    const handleClickVoirPlus = () => {
      setAllDesc(false);
    };
    const handleClickVoirMoins = () => {
      setAllDesc(true);
    };

    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        <div className="flex">
          {locationState.atmosphere.length > 0 &&
            locationState?.atmosphere.map((item: any, index: number) => (
              <div key={index} className="flex items-center space-x-1">
                <div className=""></div>
                <Badge href="##" color="purple" name={item} />
              </div>
            ))}
        </div>

        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{allDescs ? desc : locationState?.description}</span>
          {locationState?.description.length >= 675 && allDescs && (
            <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirPlus}>
              {" "}
              Voir plus
            </span>
          )}

          {allDesc && allDescs === false && (
            <span className="text-xs text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirMoins}>
              {" "}
              Voir moins
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Équipements Disponibles</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` Vous pouvez profiter de ces équipements gratuits pour rendre votre expérience encore plus agréable`}
          </span>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.equipments &&
            locationState?.equipments?.map((item: any) => (
              <div key={item?.id} className="flex items-center space-x-3">
                <i className="text-2xl las la-check"></i>
                <span className="">{item?.equipment}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };
  const renderEventsSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Événements</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.events.map((item: any, index: number) => (
            <div key={index} className="flex items-center space-x-3">
              <i className="text-2xl las la-handshake"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderPlayGroundsSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Aire de jeux</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState?.playground.map((item: any, index: number) => (
            <div key={index} className="flex items-center space-x-3">
              <i className="text-2xl las la-dice"></i>
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDaysSection = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Les jours de disponibilité</h2>
        </div>
        <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
          {locationState &&
            locationState.availableDays.map((item: any, index: number) => (
              <div key={index} className="flex items-center space-x-3">
                <i className="text-2xl las la-calendar-day"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection10 = () => {
    if (locationState?.extras && locationState?.extras?.length === 0) {
      return null;
    }
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Extra équipements</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2">
            {locationState?.extras &&
              locationState?.extras?.map((item: any) => (
                <div key={item?.designation} className="flex items-center space-x-3">
                  <i className="text-2xl las la-check"></i>
                  <span className="">
                    {item.price !== 0 ? `${item.designation}: ${item.price} €` : `${item.designation}: Gratuit`}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };
  const renderSection9 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Les règles de cet espace</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {locationState.conditions &&
            locationState.conditions?.map((item: any) => (
              <div key={item.designation} className="flex items-center space-x-3">
                <i className="text-2xl las la-exclamation-triangle"></i>
                <span>{item.designation}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {
    const prices = locationState.prices;

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Tarif semaine</h2>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="-mb-4 text-sm sm:text-base text-neutral-6000 dark:text-neutral-300">
            {locationState &&
              locationState.prices &&
              prices.map((price: any, index) => (
                <div
                  key={index}
                  className={`p-4 ${
                    index % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""
                  } flex justify-between items-center space-x-4 rounded-lg`}
                >
                  <span>
                    {price.min} - {price.max} visiteurs
                  </span>
                  <span>{price.price} € /heure</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderWeekendSection = () => {
    const weekendPrices = locationState.WeekendPrices;
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Tarif weekend</h2>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="mb-4 text-sm sm:text-base text-neutral-6000 dark:text-neutral-300">
            {weekendPrices.length > 0 &&
              weekendPrices.map((price: any, index: any) => (
                <div
                  key={price.id}
                  className={`p-4 ${
                    index % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""
                  } flex justify-between items-center space-x-4 rounded-lg`}
                >
                  <span>
                    {price.min} - {price.max} visiteurs
                  </span>
                  <span>{price.price} € /heure</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    const generateId = () => {
      return parseInt(Math.random().toString(10).substr(2, 9));
    };

    const handleDownload = (pdfLink: any) => {
      const link = document.createElement("a");
      link.href = process.env.REACT_APP_CLOUD_FRONT + pdfLink;
      link.target = "_blank";
      link.download = "file.pdf";
      link.click();
    };

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Proposé par</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <a href={`/owners/${ownerState?.id}`}>
            <Avatar
              hasChecked
              hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
              sizeClass="h-14 w-14"
              radius="rounded-full"
              imgUrl={ownerState?.client_url ? process.env.REACT_APP_CLOUD_FRONT + ownerState?.client_url : ""}
            />
          </a>
          <div>
            <span>
              {ownerState?.name} {ownerState?.lastName}
            </span>

            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating point={ownerState?.overallAverageLikes} />
              <span className="mx-2">·</span>
              {ownerState?.totalLocations} Espaces{" "}
            </div>
          </div>
        </div>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Inscrit le {new Date(ownerState?.createdAt ?? "").toLocaleDateString()}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Temps de réponse - 100%</span>
          </div>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        {locationState?.Client?.account_type === "company" && (
          <>
            <span>Nom d'entreprise: {locationState?.Client?.companyName}</span>
            <span>SIREN d'entreprise: {locationState?.Client?.companySiren}</span>
            <span>Addresse E-mail d'entrepise: {locationState?.Client?.companyEmail}</span>
            <span>Numéro téléphone d'entreprise: {locationState?.Client?.companyPhone}</span>

            <div className="flex items-center justify-between">
              <div>
                <span> Compte Bancaire (IBAN) </span>

                <div>
                  {fileTypes["iban_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.identityFImg_url}`}
                      alt="Compte Bancaire (IBAN)"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.iban_url!)}
                    />
                  ) : fileTypes["iban_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.iban_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>

              <div>
                <span> K-Bis d'entreprise </span>
                <div>
                  {fileTypes["companyCertifImg_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.companyCertifImg_url}`}
                      alt="K-Bis d'entreprise"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.companyCertifImg_url!)}
                    />
                  ) : fileTypes["companyCertifImg_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.companyCertifImg_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>

              {locationState && locationState?.Client.adresseImg_url && (
                <div>
                  <span> Justificatif a domicile </span>
                  <div>
                    {fileTypes["adresseImg_url"] === "image" ? (
                      <img
                        className="w-48 h-48 rounded-md sm:rounded-xl"
                        src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.adresseImg_url}`}
                        alt="Justificatif a domicile"
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        onClick={() => handleImageClick(locationState?.Client?.adresseImg_url!)}
                      />
                    ) : fileTypes["adresseImg_url"] === "pdf" ? (
                      <ButtonPrimary
                        onClick={() => handleDownload(locationState?.Client?.adresseImg_url!)}
                        icon={<FaFileDownload />}
                      >
                        Télécharger
                      </ButtonPrimary>
                    ) : (
                      <p>Unsupported file type</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {locationState?.Client?.account_type === "particular" && (
          <>
            <span>Nom du bank: {locationState?.Client?.bank}</span>
            <span>RIB: {locationState?.Client?.rib}</span>
            <div className="flex items-center justify-between">
              <div>
                <span> Carte d'identité : face avant </span>

                <div>
                  {fileTypes["identityFImg_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.identityFImg_url}`}
                      alt="Carte d'identité : face avant"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.identityFImg_url!)}
                    />
                  ) : fileTypes["identityFImg_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.identityFImg_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>

              <div>
                <span> Carte d'identité : face arriere </span>
                <div>
                  {fileTypes["identityBImg_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.identityBImg_url}`}
                      alt="Carte d'identité : face arriere"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.identityBImg_url!)}
                    />
                  ) : fileTypes["identityBImg_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.identityBImg_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>

              <div>
                <span> Compte Bancaire (IBAN) </span>
                <div>
                  {fileTypes["iban_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.iban_url}`}
                      alt="Compte Bancaire (IBAN)"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.iban_url!)}
                    />
                  ) : fileTypes["iban_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.iban_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>
              <div>
                <span> Justificatif a domicile </span>
                <div>
                  {fileTypes["adresseImg_url"] === "image" ? (
                    <img
                      className="w-48 h-48 rounded-md sm:rounded-xl"
                      src={`${process.env.REACT_APP_CLOUD_FRONT + locationState?.Client?.adresseImg_url}`}
                      alt="Justificatif a domicile"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      onClick={() => handleImageClick(locationState?.Client?.adresseImg_url!)}
                    />
                  ) : fileTypes["adresseImg_url"] === "pdf" ? (
                    <ButtonPrimary
                      onClick={() => handleDownload(locationState?.Client?.adresseImg_url!)}
                      icon={<FaFileDownload />}
                    >
                      Télécharger
                    </ButtonPrimary>
                  ) : (
                    <p>Unsupported file type</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <>
          {modalOpen && (
            <Modal
              images={clickedImages.map((imageUrl) => ({
                id: generateId(),
                url: imageUrl,
              }))}
              onClose={() => {
                setModalOpen(false);
                setClickedImages([]);
              }}
            />
          )}
        </>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Localisation</h2>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="z-0 rounded-xl ring-1 aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-black/10">
          <div className="z-0 overflow-hidden rounded-xl">
            {lat && lng && (
              <iframe
                title="x"
                width="100%"
                height="100%"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={googleMapsURL}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Informations suplémentaires</h2>
        {/* CONTENT */}
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          - Dêpot de garantie : {locationState?.guarantee} € <br />
        </span>
        <div></div>
      </div>
    );
  };

  const renderSection11 = () => {
    return (
      <div className=" listingSection__wrap">
        {/* HEADING */}
        <div className="flex justify-between ">
          <h2 className="text-2xl font-semibold">Politiques d'annulation</h2>
        </div>
        {/* CONTENT */}
        {locationState?.reservation_type === "Stricte" ? (
          <>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              <p>
                {" "}
                Stricte : Les clients peuvent annuler dans les 48 heures suivant la réservation pour un
                remboursement complet. Passé ce délai, aucune annulation n'est possible et aucun remboursement ne
                sera effectué.
              </p>
            </span>
          </>
        ) : (
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            <p>
              {" "}
              Flexible : Les clients peuvent annuler jusqu'à 5 jours avant la date d'arrivée prévue pour un
              remboursement de 70 % du montant total. Les annulations effectuées moins de 5 jours avant la date
              d'arrivée ne sont pas remboursables.
            </p>
          </span>
        )}
        <div></div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 gap-1 sm:grid-cols-4 sm:gap-2">
          <div
            className="relative col-span-2 row-span-3 overflow-hidden rounded-md cursor-pointer sm:row-span-2 sm:rounded-xl"
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
              src={process.env.REACT_APP_CLOUD_FRONT + locationState?.images[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 transition-opacity opacity-0 bg-opacity-20 bg-neutral-900 hover:opacity-100"></div>
          </div>
          {locationState?.images.map((item: any, index: number) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 3 ? "hidden sm:block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={process.env.REACT_APP_CLOUD_FRONT + locationState?.images[index]}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 transition-opacity opacity-0 cursor-pointer bg-opacity-20 bg-neutral-900 hover:opacity-100"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute z-10 hidden px-4 py-2 bottom-3 left-3 rounded-xl md:flex md:items-center md:justify-center bg-neutral-100 text-neutral-500 hover:bg-neutral-200"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-sm font-medium text-neutral-800">Show all photos</span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className="relative z-10 flex flex-col mt-11 lg:flex-row">
        {/* CONTENT */}
        <div className="w-full space-y-8">
          {renderSection1()}
          {renderSection2()}
          {renderSection4()}
          {locationState && locationState.WeekendPrices?.length > 0 && renderWeekendSection()}
          {renderEventsSection()}
          {locationState.playground?.length > 0 && renderPlayGroundsSection()}
          {renderDaysSection()}
          {locationState?.equipments?.length > 0 && renderSection3()}
          {locationState?.extras?.length > 0 && renderSection10()}

          {locationState?.conditions?.length > 0 && renderSection9()}
          {renderSection5()}

          {renderSection7()}
          {renderSection8()}
          {renderSection11()}
        </div>

        <div className="nc-ListingStayDetailPage">
          <div className="fixed-sidebar"></div>
          <br />
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
