import InputField from "components/SearchFilters/InputField";
import SelectField from "components/SearchFilters/SelectField";
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";

interface RealEstateSearchFormProps {
  onSelectFromRegionEvent?: (field: string, value: string) => void;
  searchClicked: boolean;
  handleSearchClick: () => void;
  handleDelete: () => void;
  propEventValue?: string;
  propRegionValue?: string;
}
const RealEstateSearchForm: FC<any> = ({
  onSelectFromRegionEvent,
  handleSearchClick,
  handleDelete,
  propRegionValue,
  propEventValue,
}) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const queryevent = searchParams.get("event");
  const queryregion = searchParams.get("region");

  const [event, setEvent] = useState(false);
  const [region, setRegion] = useState(false);

  const handleEventSelection = (events: string) => {
    onSelectFromRegionEvent("events", events);
    setEvent(false);
  };
  const handleRegionSelection = (region: string) => {
    onSelectFromRegionEvent("region", region);
    setRegion(false);
  };
  const clearEvent = () => {
    setEvent(true);
  };
  const clearRegion = () => {
    setRegion(true);
  };

  const renderForm = () => {
    return (
      <>
        <form className="relative flex flex-col w-full -mt-8 bg-white divide-y shadow-xl xl: lg:flex-row lg:items-center rounded-3xl lg:rounded-full dark:shadow-2xl dark:bg-neutral-800 divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 ">
          <InputField
            propEventValue={queryregion}
            onSelectEvent={handleRegionSelection}
            clear={region}
            name="region"
            placeHolder="Région"
            desc="Saisit votre région"
          />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <SelectField
            type="event"
            placeHolder="Evènement"
            desc="Quels types d'événement ?"
            clear={event}
            propItemValue={queryevent}
            onSelectItem={handleEventSelection}
            icon={<i className="text-3xl las la-glass-cheers"></i>}
          />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <div className="flex [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none   ">
            <ButtonCircle
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleDelete();
                clearEvent();
                clearRegion();
              }}
            >
              <i className="las la-times"></i>
            </ButtonCircle>
            <ButtonCircle type="button" className="btn btn-primary" onClick={handleSearchClick}>
              <i className="las la-search"></i>
            </ButtonCircle>
          </div>
        </form>
      </>
    );
  };

  return renderForm();
};

export default RealEstateSearchForm;
