import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const stripeApi = {
  getUserStats: async (userId: number, account_id: string, status: string) => {
    const result = await fetch(`${baseURL}/api/user-stats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ userId, account_id, status }),
    });

    return result.json();
  },

  getAllTransactions: async () => {
    const response = await fetch(`${baseURL}/api/transfers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  getOwnerAllTransactions: async (account_id: string) => {
    const response = await fetch(`${baseURL}/api/owner-transfers?account_id=${account_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });
    const data = await response.json();
    return data;
  },

  getAllAccounts: async (pageNumber: number, startAfter: string) => {
    const result = await fetch(`${baseURL}/api/connected-accounts?page=${pageNumber}&startAfter=${startAfter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  deleteAccount: async (id: number, owner_id: any) => {
    const result = await fetch(`${baseURL}/api/connected-accounts/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: JSON.stringify({ id, owner_id }),
    });
    if (result.ok) {
      toast.success("Le compte a été supprimé avec succès.");
    }

    return result.json();
  },

  getAllCoupons: async (startAfter: string) => {
    const result = await fetch(`${baseURL}/api/admin/coupons?startAfter=${startAfter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  deleteCoupons: async (id: any) => {
    const response = await fetch(`${baseURL}/api/admin/coupons/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred while deleting rule .");
    }
    const res = await response.json();
    toast.success(res.message);
  },

  addCoupon: async (formData: any) => {
    try {
      const response = await fetch(`${baseURL}/api/admin/coupons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.msg);
        return data;
      } else {
        toast.error(data.error);
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
};
