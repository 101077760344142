import { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import LocationInput from "containers/Locations/AllLocationsPage/LocationInput";
import { useLocation } from "react-router-dom";
import SelectField from "components/SearchFilters/SelectField";

interface RealEstateSearchFormProps {
  onSelectField?: () => void;
  onSelectFromRegionEvent?: (field: string, value: string) => void;
  searchClicked: boolean;
  handleSearchClick: () => void;
  handleDelete: () => void;
  propTypeValue?: string;
  propRegionValue?: string;
}
const RealEstateSearchFormService: FC<any> = ({
  onSelectFromRegionEvent,
  handleSearchClick,
  handleDelete,
  onSelectField,
  propTypeValue,
  propRegionValue,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const querytype = searchParams.get("field");
  const queryregion = searchParams.get("region");

  const [event, setEvent] = useState(false);
  const [region, setRegion] = useState(false);

  const handleEventSelection = (events: any) => {
    onSelectFromRegionEvent("events", events);
  };
  const handleRegionSelection = (region: string) => {
    onSelectFromRegionEvent(region);
    setRegion(false);
  };
  const handleField = (field: string) => {
    onSelectField(field);
    setEvent(false);
  };
  const clearEvent = () => {
    setEvent(true);
  };
  const clearRegion = () => {
    setRegion(true);
  };

  const renderForm = () => {
    return (
      <>
        <form className="relative flex flex-col w-full -mt-8 bg-white divide-y shadow-xl xl: lg:flex-row lg:items-center rounded-3xl lg:rounded-full dark:shadow-2xl dark:bg-neutral-800 divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0 ">
          <LocationInput
            propRegionValue={queryregion}
            onSelectRegion={handleRegionSelection}
            clear={region}
            className="flex-[1]"
          />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>

          <SelectField
            type="field"
            placeHolder="Type de prestation"
            desc="Quels types de prestation ?"
            clear={event}
            propItemValue={querytype}
            onSelectItem={handleField}
            icon={<i className="text-3xl las la-user-tie"></i>}
          />
          <div className="self-center h-4 border-r border-slate-200 dark:border-slate-700"></div>
          <div className="flex [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none   ">
            <ButtonCircle
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleDelete();
                clearEvent();
                clearRegion();
              }}
            >
              <i className="las la-times"></i>
            </ButtonCircle>
            <ButtonCircle type="button" className="btn btn-primary" onClick={handleSearchClick}>
              <i className="las la-search"></i>
            </ButtonCircle>
          </div>
        </form>
      </>
    );
  };

  return renderForm();
};

export default RealEstateSearchFormService;
