import { Popover, Transition } from "@headlessui/react";
import { useAppSelector } from "hooks/hooks";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { socket } from "utils/socket";

interface NotifyDropdownProps {
  className?: string;
  open?: boolean;
  notifications: {
    notifications: Notification[];
    unreadCount: number;
    totalNotifications: number;
  };
  loadMoreNotifications: () => void;
  loading: boolean;
}

const NotifyDropdown: React.FC<NotifyDropdownProps> = ({
  className = "",
  notifications,
  loadMoreNotifications,
  loading,
}) => {
  const open = true;
  const getNotificationLink = (item: any): string => {
    const isUserAdmin = userState?.role === "admin";
    const locationLink = "/location/" + item.locationId;
    const prestationLink = "/prestations/" + item.prestationId;
    switch (item.operation) {
      case "create_reservation":
        return "/client/list-pending-reservations";
      case "confirm_reservation":
        return isUserAdmin
          ? "/admin/dashboard/reservations/confirmed"
          : `${locationLink}?reservationId=${item.reservationId}&openconverstaion=true`;
      case "cancel_reservation":
        return isUserAdmin ? "/admin/dashboard/reservations/canceled/client" : "/locations";
      case "nagate_reservation":
        return isUserAdmin ? "/admin/dashboard/reservations/canceled/owner" : "/locations";
      case "send_message":
        return `${locationLink}?reservationId=${item?.reservationId}&openconversation=true`;
      case "confirm_owner_espace":
        return locationLink;
      case "negate_owner_espace":
        return "/client/locations";
      case "send_avis":
        return `${locationLink}#commentaires`;
      case "send_contat_request":
        return isUserAdmin ? "/admin/dashboard/reclamations" : "";
      case "create_espace":
        return isUserAdmin ? "/admin/dashboard/locations/unapproved" : "";
      case "create_prestation":
        return isUserAdmin ? "/admin/dashboard/prestations" : "";
      case "send_presta_avis":
        return `${prestationLink}#commentaires`;
      case "create_annonce":
        return isUserAdmin ? "/admin/dashboard/annonces/unapproved" : "";
      case "confirm_user_announce":
        return "/client/announces";
      case "block_user_announce":
        return "/client/announces";
      case "send_presta_devis":
        return "/prestataire/pricing";
      default:
        return "";
    }
  };

  const userState = useAppSelector((state) => state?.auth?.user);
  const user_avatar = "static-files/user_profile_2.png";
  const admin_avatar = "static-files/EVENT TO BEE-17.png";
  const getAvatarUrl = (item: any): string => {
    if (!item.senderId) {
      return process.env.REACT_APP_CLOUD_FRONT + user_avatar;
    }
    if (item?.sender === "ADMIN") {
      return process.env.REACT_APP_CLOUD_FRONT + admin_avatar;
    }
    const clientUrl = item?.senderData?.Clients[0]?.client_url?.trim();
    return clientUrl
      ? process.env.REACT_APP_CLOUD_FRONT + clientUrl
      : process.env.REACT_APP_CLOUD_FRONT + user_avatar;
  };

  const getSenderName = (item: any): string => {
    if (item?.sender !== "ADMIN") {
      return item?.sender;
    } else if (item?.sender === "ADMIN") {
      return "Eventtobee";
    } else {
      return item?.sender?.email || "Unknown ";
    }
  };
  const updateNotificationSeen = (item: any) => {
    if (userState) {
      socket.emit("update-notification", {
        userId: userState.id,
        notificationId: item.id,
      });
    }
  };

  useEffect(() => {}, []);
  useEffect(() => {}, [open]);

  return (
    <div className={className}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={` ${
                open ? "" : "text-opacity-90"
              } group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              {notifications.unreadCount >= 1 && (
                <span className="absolute flex items-center justify-center w-4 h-4 bg-blue-500 rounded-full top-3 right-3">
                  <span className="text-xs text-center text-white">{notifications.unreadCount}</span>
                </span>
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute z-10 w-screen max-w-xs px-4 mt-3 sm:max-w-sm -right-28 sm:right-0 sm:px-0"
                style={{
                  display: "block",
                  maxHeight: "440px",
                  overflowY: "scroll",
                }}
              >
                <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 p-4 bg-white dark:bg-neutral-800">
                    {notifications.notifications &&
                      notifications.notifications.map((item: any, index: any) => {
                        return (
                          item && (
                            <Link
                              key={index}
                              to={getNotificationLink(item)}
                              className={`${item?.vue === false ? "bg-blue-200" : ""} relative flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50
                               }`}
                              onClick={() => {
                                close();
                                updateNotificationSeen(item);
                              }}
                            >
                              <Avatar sizeClass="w-8 h-8 sm:w-12 sm:h-12" imgUrl={getAvatarUrl(item)} />
                              <div className="ml-3 space-y-1 sm:ml-4">
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                                  {getSenderName(item)}
                                </p>
                                <p className="text-xs text-gray-500 sm:text-sm dark:text-gray-400">
                                  {item.message}
                                </p>

                                <div className="flow-root mt-1 ">
                                  <p className="float-left text-xs text-gray-500 ">
                                    {new Date(item.createdAt).toISOString().split("T")[0]}
                                  </p>

                                  <p className="float-right text-xs text-gray-500 ">
                                    {new Date(new Date(item.createdAt).getTime()).toLocaleTimeString("fr-FR", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}{" "}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          )
                        );
                      })}

                    {notifications.notifications.length < notifications.totalNotifications && (
                      <ButtonSecondary loading={loading} onClick={loadMoreNotifications}>
                        Voir plus
                      </ButtonSecondary>
                    )}
                    {notifications.totalNotifications === 0 && (
                      <h3 className="text-center">Actuellement, il n'y a aucune notification en cours.</h3>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default NotifyDropdown;
