import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import Badge from "shared/Badge/Badge";

export interface NavLinkProps {
  to: string;
  label: string;
  color?: any;
}

export interface TabNavigationProps {
  children?: React.ReactNode;
  navLinks: NavLinkProps[];
}

const TabNavigation: FC<TabNavigationProps> = ({ children, navLinks }) => {
  return (
    <div className="bg-neutral-50 dark:bg-neutral-900">
      <div className="pt-1 bg-white border-b border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
        <div className="container">
          <div className="flex justify-center space-x-8 overflow-x-auto text-center md:space-x-14 hiddenScrollbar">
            {navLinks.map(({ to, label, color }) => (
              <NavLink
                key={to}
                to={to}
                className={({ isActive }) =>
                  `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                    !isActive ? "border-transparent" : "border-primary-500"
                  }`
                }
              >
                <Badge
                  name={label}
                  color={color || "gray"}
                  className={`flex items-center justify-center w-full h-8 text-center `}
                  href={to}
                ></Badge>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5">{children}</div>
    </div>
  );
};

export default TabNavigation;
