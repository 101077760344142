import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardBody, CardFooter, Dialog, Input, Radio, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { CouponDataSchemaType, Couponschema } from "data/validation";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface EntityDialogFormProps {
  open: boolean;
  handleClose?: () => void;
  handleDelete?: () => void;
  isLoading?: boolean;
  addfn?: (payload: any) => void;
  addfnTitle?: string;
  updatefnTitle?: string;
}

export const EntityCouponDialogForm: React.FC<EntityDialogFormProps> = ({
  addfn,
  open,
  addfnTitle,
  handleClose = () => {},
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CouponDataSchemaType>({ resolver: zodResolver(Couponschema) });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);

    if (addfn) {
      await addfn(data);
      window.location.reload();
    }
    handleClose();
  };

  return (
    <>
      <Dialog size="xs" open={open} handler={handleClose} className="bg-transparent shadow-none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto w-full max-w-[24rem]">
            <CardBody className="flex flex-col gap-4">
              <Typography variant="h4" color="blue-gray">
                {addfnTitle}
              </Typography>
              <Label className="font-normal">Pourcentage *</Label>
              <Input
                size="lg"
                placeholder="10%"
                {...register("percent", { setValueAs: (v) => (v === "" ? undefined : parseFloat(v)) })}
              />
              {errors.percent && <div className="text-xs text-red-500 ">{errors.percent.message}</div>}

              <Label className="font-normal">Nombre d'utilisation</Label>
              <div className="flex justify-between">
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Illimté</Typography>}
                  name={"max_redemptions"}
                  checked={getValues("max_redemptions") === true}
                  onChange={() => {
                    setValue("max_redemptions", true);
                    trigger("max_redemptions");
                    setValue("number_of_redemptions", 0);
                    trigger("max_redemptions");
                    trigger("number_of_redemptions");
                  }}
                />
                <Radio
                  color="blue"
                  label={<Typography className="text-dark dark:text-white">Limité</Typography>}
                  name={"max_redemptions"}
                  checked={getValues("max_redemptions") === false}
                  onChange={() => {
                    setValue("max_redemptions", false);
                    trigger("max_redemptions");
                  }}
                />
              </div>

              {errors.max_redemptions?.message && (
                <div className="text-xs text-red-500">{`${errors.max_redemptions?.message}`}</div>
              )}

              {getValues("max_redemptions") === false && (
                <Input
                  size="lg"
                  placeholder="10"
                  {...register("number_of_redemptions", {
                    setValueAs: (v) => (v === "" ? undefined : parseInt(v)),
                  })}
                  name="number_of_redemptions"
                />
              )}
              {errors.number_of_redemptions && (
                <div className="text-xs text-red-500 ">{errors.number_of_redemptions.message}</div>
              )}
            </CardBody>
            <CardFooter className="flex justify-between ">
              <ButtonPrimary
                onClick={handleClose}
                className="bg-red-500 rounded-sm hover:bg-red-500"
                sizeClass="px-4 py-2   sm:px-6"
              >
                Annuler
              </ButtonPrimary>

              <ButtonPrimary type="submit" loading={loading} className="rounded-sm " sizeClass="px-4 py-2 sm:px-6">
                {"Confirmer"}
              </ButtonPrimary>
            </CardFooter>
          </Card>
        </form>
      </Dialog>
    </>
  );
};
