import toast from "react-hot-toast";

const server = process.env.REACT_APP_SERVER_URL || "";

export const categoryApi = {
  add: async (formData: any) => {
    try {
      const { designation } = formData;

      const response = await fetch(`${server}/api/admin/category-location`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ designation }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.msg);
        return data;
      } else {
        toast.error(data.error);
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },

  verify: async () => {
    try {
      const response = await fetch(`${server}/api/category-location`);
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  delete: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/admin/category-location/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred while deleting rule .");
      }
      const res = await response.json();
      toast.success(res.message);
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      throw new Error("Error deleting rule.");
    }
  },
  update: async (data: any, id: any) => {
    try {
      const { designation } = data;

      const response = await fetch(`${server}/api/admin/category-location/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({ designation }),
      });
      if (response.ok) {
        const data = await response.json();

        return data;
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      throw error;
    }
  },
  getById: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/category-location/${id}`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to delete category");
      }

      const data = await response.json();
      return data;
    } catch (error) {}
  },

  addAnnounceCategory: async (data: any) => {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "images") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.images) {
      formData.append(`images`, data.images);
    }

    const result = await fetch(`${server}/api/admin/category-announces`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });

    if (result.ok) {
      toast.success("Catégorie créée avec succès");
      return data;
    } else {
      toast.error("La catégorie existe déjà");
      throw new Error(result.statusText);
    }
  },

  fetchAnnounceCategorys: async (page: number, designation: string) => {
    const result = await fetch(
      `${server}/api/admin/categories-announces?page=${page}&designation=${designation}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  },

  deleteAnnounceCategory: async (id: number) => {
    try {
      const response = await fetch(`${server}/api/admin/categories-announces/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred while deleting announce .");
      }
      const res = await response.json();
      toast.success(res.message);
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      throw new Error("Error updating Annonce validation.");
    }
  },

  updateAnnounceCategory: async (data: any, id: any) => {
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "images") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.images) {
      formData.append(`images`, data.images);
    }

    const result = await fetch(`${server}/api/admin/categories-announces/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    const res = await result.json();
    if (result.ok) {
      toast.success(res.msg);
      return data;
    } else {
      toast.error(res.msg);
      throw new Error(result.statusText);
    }
  },
  listAnnounceCategories: async () => {
    try {
      const response = await fetch(`${server}/api/list-categories-announces`);
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  adminGetAllLocationCategories: async (page: number, designation: string) => {
    const result = await fetch(`${server}/api/admin/category-location?page=${page}&designation=${designation}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },
};
