import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createEquipment } from "./featureAPI";
const initialState = {
  designation: "",
  loading: "idle",
  equipments: [],
  equipment: { designation: "" },
  totalPages: 0,
};

export const addEquipment = createAsyncThunk("featureEquipment/addEquipment", async (data: any) => {
  return await createEquipment.add(data);
});

export const fetchEquipments = createAsyncThunk("featureEquipment/fetchEquipment", async () => {
  return await createEquipment.verify();
});

export const deleteEquipment = createAsyncThunk("featureEquipment/deleteEquipment", async (id: number) => {
  return await createEquipment.delete(id);
});

export const updateEquipment = createAsyncThunk(
  "featureEquipment/updateEquipment",
  async ({ data, id }: { data: any; id: any }) => {
    return await createEquipment.update(data, id);
  }
);
export const getEquipmentById = createAsyncThunk("featureEquipment/getEquipmentById", async (id: any) => {
  return await createEquipment.getById(id);
});

export const adminGetAllEquipments = createAsyncThunk(
  "adminGetAllEquipments",
  async ({ page, designation }: { page: number; designation: string }) => {
    return await createEquipment.adminGetAllEquipments(page, designation);
  }
);

const createEquipmentSlice = createSlice({
  name: "featureEquipment",
  initialState,
  reducers: {
    setDesignation(state, action) {
      state.designation = action.payload;
    },
    resetDesignation(state) {
      state.designation = "";
    },
    resetValues(state) {
      state.designation = "";
      state.loading = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(addEquipment.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addEquipment.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(addEquipment.rejected, (state) => {
        state.loading = "failed";
      });
    builder.addCase(fetchEquipments.fulfilled, (state, action) => {
      state.equipments = action.payload;
    });
    builder.addCase(adminGetAllEquipments.fulfilled, (state, action) => {
      state.equipments = action.payload.equipments;
      state.totalPages = action.payload.totalPages;
    });
    builder
      .addCase(deleteEquipment.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteEquipment.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(deleteEquipment.rejected, (state) => {
        state.loading = "failed";
      });
    builder
      .addCase(updateEquipment.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateEquipment.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.designation = action.payload;
      })
      .addCase(updateEquipment.rejected, (state) => {
        state.loading = "failed";
      });
    builder.addCase(getEquipmentById.fulfilled, (state, action) => {
      state.equipment = action.payload;
    });
  },
});

export const { setDesignation, resetDesignation, resetValues } = createEquipmentSlice.actions;
export default createEquipmentSlice.reducer;
