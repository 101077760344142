import Glide from "@glidejs/glide";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import CommentListingService from "components/CommentListing/CommentListingService";
import LikeSaveBtnsService from "components/LikeSaveBtnsService";
import LoginModal from "components/LoginModal/LoginModal";
import SocialMediaShare from "components/SocialMediaShare/SocialMediaShare";
import StartRating from "components/StartRating/StartRating";
import DetailPagetLayout from "containers/Locations/LocationDetailPage/Layout";
import { AuthContext } from "context/appContext";
import { useAppDispatch, useAppSelector, useScrollToHash } from "hooks/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaFileDownload, FaRegWindowClose } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@material-tailwind/react";
import {
  addServiceToFavoris,
  checkServiceFavoriByUserId,
  deleteServiceFromFavoris,
  fetchSimilareService,
  getCommentByServiceId,
  getService,
  getServiceFavorisByClientId,
} from "redux/features/service/serviceSlice";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import PricingModal from "./PricingModal";
import RatingModal from "./RatingModal";

export const ServiceDetailPage = () => {
  const { id } = useParams();

  const router = useNavigate();
  const dispatch = useAppDispatch();

  const authContext = useContext(AuthContext);
  const profilId = authContext?.user?.profil?.id;

  const serviceState = useAppSelector((state) => state.service);
  const similarState = useAppSelector((state) => state.service.allServices);
  const [pointAgent, setPointAgent] = useState<number>(0);
  const [showRatingModal, setShowRatingModal] = useState<any>(false);
  const [showPricingModal, setShowPricingModal] = useState<any>(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [profilDescs, setProfilDesc] = useState(true);

  useEffect(() => {
    if (similarState && similarState.length >= 4 && glideRef.current) {
      const glide = new Glide(glideRef.current, {
        type: "carousel",
        perView: 4,
        gap: 20,
        autoplay: 1500,
      });

      glide?.mount();
      return () => glide?.destroy();
    }
  }, [similarState]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setLoading(true);
        await dispatch(getService(id));
        await dispatch(fetchSimilareService(id));
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (profilId) {
      dispatch(getServiceFavorisByClientId({ clientId: profilId }));
    }
  }, [profilId]);

  useEffect(() => {
    if (id && serviceState.serviceData) {
      dispatch(getCommentByServiceId(id));
    }
    if (id && profilId && serviceState.serviceData) {
      dispatch(checkServiceFavoriByUserId({ serviceId: id, userId: profilId }));
    }
  }, [serviceState.serviceData]);

  const [showLogin, setShowLogin] = useState(false);
  const glideRef = useRef(null);
  const handleClickLike = async () => {
    if (!profilId) {
      setShowLogin(true);
    }
    if (id && profilId && serviceState.serviceData) {
      !serviceState?.isLiked
        ? await dispatch(addServiceToFavoris({ serviceId: id, userId: profilId }))
        : await dispatch(deleteServiceFromFavoris({ serviceId: id, userId: profilId }));
      await dispatch(checkServiceFavoriByUserId({ serviceId: id, userId: profilId }));
    }
  };

  const handleDownload = (pdfLink: any) => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_CLOUD_FRONT + pdfLink;
    link.target = "_blank";
    link.download = "file.pdf";
    link.click();
  };

  useEffect(() => {
    if (serviceState.serviceComments?.length > 0) {
      let totalLikes = 0;
      serviceState.serviceComments.forEach((comment: any) => {
        totalLikes += comment.like;
      });
      const moyenne = totalLikes / serviceState.serviceComments.length;
      const roundedMoyenne = Math.round(moyenne);
      setPointAgent(roundedMoyenne);
    }
  }, [serviceState.serviceComments]);

  const handleRatingModal = () => {
    !profilId ? setShowLogin(true) : setShowRatingModal(true);
  };
  const handlePricingModal = () => {
    !profilId ? setShowLogin(true) : setShowPricingModal(true);
  };

  const handleModalClose = () => {
    setShowRatingModal(false);
  };

  const handleModalPricingClose = () => {
    setShowPricingModal(false);
  };

  const [showSocialMediaShare, setShowSocialMediaShare] = useState(false);

  const handleShareButtonClick = () => {
    setShowSocialMediaShare(!showSocialMediaShare);
  };
  const [allDescs, setAllDesc] = useState(true);

  const renderSidebar = () => {
    return (
      <div className="flex flex-col items-center w-full px-0 space-y-6 text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 sm:space-y-7 sm:p-6 xl:p-8">
        <div className="flex items-center justify-between w-full">
          <button onClick={handleShareButtonClick} className="flex items-center ml-2.5 mt-1.5 text-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
            <span>Partager</span>
          </button>
          <div className="flex-grow"></div>
          <LikeSaveBtnsService onClick={handleClickLike} isFavorited={serviceState.isLiked} />
        </div>

        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={
            serviceState?.serviceData?.ServiceAgent?.service_url
              ? process.env.REACT_APP_CLOUD_FRONT + serviceState?.serviceData?.ServiceAgent?.service_url
              : ""
          }
        />
        <div className="flex flex-col items-center space-y-3 text-center">
          <h2 className="text-3xl font-semibold">{serviceState?.serviceData?.ServiceAgent?.designation}</h2>
          <StartRating
            reviewCount={serviceState.serviceComments?.length}
            point={Number(pointAgent)}
            className="!text-base"
          />
        </div>

        <p className="text-neutral-500 dark:text-neutral-400">
          <i className="las la-envelope"></i>
          {serviceState.serviceData?.ServiceAgent?.contact}
        </p>
        <p className="text-neutral-500 dark:text-neutral-400">
          <i className="lab la-whatsapp"></i>
          {serviceState.serviceData?.ServiceAgent?.phone}
        </p>

        <div className="flex !space-x-3">
          {serviceState.serviceData?.ServiceAgent?.facebook && (
            <a href={`${serviceState.serviceData?.ServiceAgent?.facebook}`} target="_blank" rel="noreferrer">
              <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-neutral-800">
                <i className="lab la-facebook-square"></i>
              </div>
            </a>
          )}

          {serviceState.serviceData?.ServiceAgent?.youtube && (
            <a href={`${serviceState.serviceData?.ServiceAgent?.youtube}`} target="_blank" rel="noreferrer">
              <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-neutral-800">
                <i className="lab la-youtube"></i>
              </div>
            </a>
          )}

          {serviceState.serviceData?.ServiceAgent?.instagram && (
            <a href={`${serviceState.serviceData?.ServiceAgent?.instagram}`} target="_blank" rel="noreferrer">
              <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-neutral-800">
                <i className="lab la-instagram"></i>
              </div>
            </a>
          )}

          {serviceState.serviceData?.ServiceAgent?.link && (
            <a href={`${serviceState.serviceData?.ServiceAgent?.link}`} target="_blank" rel="noreferrer">
              <div className="flex items-center justify-center text-xl rounded-full w-9 h-9 bg-neutral-100 dark:bg-neutral-800">
                <i className="lab la-chrome"></i>
              </div>
            </a>
          )}
        </div>

        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {serviceState.serviceData?.ServiceAgent?.region}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Inscrit le{" "}
              {new Date(serviceState.serviceData?.ServiceAgent?.createdAt).toLocaleDateString("fr-FR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </div>
          {profilId != serviceState.serviceData?.ServiceAgent?.id && (
            <div className="pt-8">
              <ButtonSecondary onClick={handleRatingModal}>Ajouter un avis</ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    const desc = serviceState?.serviceData?.description?.slice(0, 400);
    const allDesc = serviceState?.serviceData?.description;
    const handleClickVoirPlus = () => {
      setAllDesc(false);
    };
    const handleClickVoirMoins = () => {
      setAllDesc(true);
    };
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Détails</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            <span>{allDescs ? desc : serviceState?.serviceData?.description}</span>
            {serviceState?.serviceData && serviceState.serviceData?.description?.length >= 400 && allDescs && (
              <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirPlus}>
                {" "}
                Voir plus
              </span>
            )}

            {allDesc && allDescs === false && (
              <span
                className="text-sm text-blue-500 cursor-pointer hover:underline"
                onClick={handleClickVoirMoins}
              >
                {" "}
                Voir moins
              </span>
            )}
          </span>
        </div>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
      </div>
    );
  };

  const renderSection10 = () => {
    const Profildesc = serviceState?.serviceData?.ServiceAgent?.description.slice(0, 400);
    const allProfilDesc = serviceState?.serviceData?.ServiceAgent?.description;
    const handleClickVoirPlus = () => {
      setProfilDesc(false);
    };
    const handleClickVoirMoins = () => {
      setProfilDesc(true);
    };
    return (
      /*  */
      <div className="listingSection__wrap">
        <div className="flex justify-between">
          <h2 className="text-2xl font-semibold">À propos </h2>

          <Badge
            color="blue"
            name={
              <div className="flex items-center">
                <span className="ml-1">{serviceState?.serviceData?.ServiceAgent?.fields}</span>
              </div>
            }
          />
        </div>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          <span>{profilDescs ? Profildesc : allProfilDesc}</span>
          {serviceState?.serviceData?.ServiceAgent?.description.length >= 400 && profilDescs && (
            <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirPlus}>
              {" "}
              Voir plus
            </span>
          )}

          {allProfilDesc && profilDescs === false && (
            <span className="text-sm text-blue-500 cursor-pointer hover:underline" onClick={handleClickVoirMoins}>
              {" "}
              Voir moins
            </span>
          )}
        </span>

        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
      </div>
    );
  };
  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold"> Événement</h2>
        </div>
        {serviceState.serviceData?.events && (
          <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
            {serviceState.serviceData?.events.map((item: any) => (
              <div key={item} className="flex items-center space-x-3">
                <i className="text-2xl las la-check"></i>
                <span className="">{item}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderSection9 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Région</h2>
        </div>
        {Array.isArray(serviceState.serviceData?.region?.split(",")) && (
          <div className="grid grid-cols-1 gap-6 text-sm xl:grid-cols-3 text-neutral-700 dark:text-neutral-300">
            {serviceState?.serviceData?.region?.split(",").map((item: any) => (
              <div key={item} className="flex items-center space-x-3">
                <i className="text-2xl las la-map-marker"></i>
                <span className="">{item}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderPostRelated = (service: any) => {
    return (
      <div key={service.id} className="flex overflow-hidden aspect-w-13 aspect-h-1 rounded-3xl group">
        <a href={`/prestations/${service.id}`} />
        <NcImage
          className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
          src={process.env.REACT_APP_CLOUD_FRONT + service.images[0]}
        />
        <div>
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <h2 className="block max-w-full text-lg font-semibold text-white">
            <span className="line-clamp-2">{service?.title}</span>
          </h2>
        </div>
        <a href={`/prestations/${service.id}`} />
      </div>
    );
  };
  const renderSectionTr = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Tarifs</h2>
        </div>

        {serviceState.serviceData?.tarif ? (
          <div className="flex flex-col items-center">
            <span>
              Parcourez le PDF des tarifs de <strong>{serviceState.serviceData?.title}</strong> pour obtenir des
              exemples de prestations et de devis.
            </span>

            <div className="mt-2">
              <ButtonPrimary
                onClick={() => handleDownload(serviceState.serviceData?.tarif)}
                icon={<FaFileDownload />}
              >
                Télécharger tarifs
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <div style={{ padding: "10px" }}>
            <span>Curieux d'en savoir plus ?</span>
            <br />
            <span className="text-blue-600 cursor-pointer" onClick={handlePricingModal}>
              Renseignez-vous sur les prix
            </span>
          </div>
        )}
      </div>
    );
  };

  useScrollToHash("#commentaires", "commentaire");

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap" id="commentaire">
        <h2 className="text-2xl font-semibold">Avis ({serviceState?.serviceComments?.length})</h2>
        <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {Array.isArray(serviceState?.serviceComments) &&
            serviceState?.serviceComments.map((comment: any, index: number) =>
              !showAllComments && index < serviceState?.serviceComments?.length - 5 ? null : (
                <CommentListingService key={comment.id} data={comment} className="py-8" />
              )
            )}

          <div className="pt-8">
            {Array.isArray(serviceState?.serviceComments) && serviceState?.serviceComments?.length > 5 && (
              <ButtonSecondary onClick={() => setShowAllComments(!showAllComments)}>
                {showAllComments ? "Cacher les avis" : "Voir plus d'avis"}
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>
    );
  };

  const thisPathname = useLocation().pathname;
  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}?gallery=images`);
  };
  return (
    <>
      <Helmet>
        <title>{`Event To Bee - ${serviceState?.serviceData?.title}`}</title>
        <meta
          name="description"
          content="Découvrez les détails du prestation à louer pour votre évènement sur Event To Bee ."
        />
      </Helmet>
      {!loading ? (
        <DetailPagetLayout>
          <div className="nc-AuthorPage" data-nc-id="AuthorPage">
            <div className="nc-ListingStayDetailPage"></div>
            <main className="container flex flex-col mt-12 mb-24 lg:mb-32 lg:flex-row">
              <div className="flex-grow block mb-24 lg:mb-0">
                <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
              </div>
              {/*  HEADER */}
              <div className="flex-shrink-0 w-full space-y-8 lg:w-3/5 xl:w-2/3 lg:space-y-10 lg:pl-10">
                <header className="rounded-md sm:rounded-xl">
                  {profilId != serviceState.serviceData?.ServiceAgent?.id && (
                    <div className="flex justify-end">
                      <ButtonPrimary onClick={handlePricingModal}>Demander un devis</ButtonPrimary>
                    </div>
                  )}
                  <br />
                  <div className="relative grid grid-cols-3 gap-1 sm:grid-cols-4 sm:gap-2 w-170">
                    <div
                      className="relative col-span-2 row-span-3 overflow-hidden rounded-md cursor-pointer sm:row-span-2 sm:rounded-xl"
                      onClick={handleOpenModalImageGallery}
                    >
                      <img
                        className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                        src={`${
                          serviceState?.serviceData?.images &&
                          process.env.REACT_APP_CLOUD_FRONT + serviceState?.serviceData?.images[0]
                        }`}
                        alt=""
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                      />
                      <div className="absolute inset-0 transition-opacity opacity-0 bg-opacity-20 bg-neutral-900 hover:opacity-100"></div>
                    </div>
                    {serviceState?.serviceData?.images?.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                          index >= 3 ? "hidden sm:block" : ""
                        }`}
                      >
                        <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                          <img
                            className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                            src={`${process.env.REACT_APP_CLOUD_FRONT + serviceState?.serviceData?.images[index]}`}
                            alt=""
                            sizes="400px"
                          />
                        </div>

                        {/* OVERLAY  tes st*/}
                        <div
                          className="absolute inset-0 transition-opacity opacity-0 cursor-pointer bg-opacity-20 bg-neutral-900 hover:opacity-100"
                          onClick={handleOpenModalImageGallery}
                        />
                      </div>
                    ))}

                    <button
                      className="absolute z-10 hidden px-4 py-2 bottom-3 left-3 rounded-xl md:flex md:items-center md:justify-center bg-neutral-100 text-neutral-500 hover:bg-neutral-200"
                      onClick={handleOpenModalImageGallery}
                    >
                      <Squares2X2Icon className="w-5 h-5" />
                      <span className="ml-2 text-sm font-medium text-neutral-800">Afficher toutes les photos</span>
                    </button>
                  </div>
                  {/* SHARE BUTTONS */}

                  {/*  <SocialMediaShare url={window.location.href} /> */}
                  {showSocialMediaShare && <SocialMediaShare url={window.location.href} />}
                </header>
                {renderSection10()}
                {renderSection1()}
                {renderSection4()}

                {showLogin && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-50"></div>

                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="flex flex-col items-center p-6 bg-white rounded-lg dark:bg-neutral-900">
                        <div className="self-end mb-4">
                          <FaRegWindowClose
                            className="text-xl cursor-pointer"
                            onClick={() => setShowLogin(false)}
                          />
                        </div>
                        <LoginModal LoginModal={(e) => setShowLogin(e)} />
                      </div>
                    </div>
                  </div>
                )}

                {showRatingModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-50"></div>

                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="flex flex-col items-center p-6 overflow-auto bg-white rounded-lg dark:bg-neutral-900">
                        {/*  <RatingModal id={id} onClose={handleModalClose} />  */}
                        <RatingModal id={id} onClose={handleModalClose} />
                      </div>
                    </div>
                  </div>
                )}
                {showPricingModal && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-50"></div>

                    <div className="fixed inset-0 z-50 flex items-center justify-center">
                      <div className="flex flex-col items-center p-6 overflow-auto bg-white rounded-lg dark:bg-neutral-900">
                        <PricingModal id={id} onClose={handleModalPricingClose} />
                      </div>
                    </div>
                  </div>
                )}
                {renderSection9()}
                {renderSectionTr()}

                {renderSection2()}
              </div>
            </main>
          </div>
        </DetailPagetLayout>
      ) : (
        <>
          <div className="flex items-center justify-center h-screen">
            <Spinner className=" w-28 h-28" color="blue" />
          </div>
        </>
      )}
      {similarState && similarState?.length >= 4 && (
        <div className="relative py-16 mt-16 bg-neutral-100 dark:bg-neutral-800 lg:py-28 lg:mt-24">
          <div className="container glide" ref={glideRef}>
            <h2 className="text-3xl font-semibold text-center">Découvrez d'autres prestations similaires</h2>
            <div
              className="grid gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 glide__track"
              data-glide-el="track"
            >
              <div
                className="glide__slides"
                style={{
                  transition: " height: 4755px !important",
                }}
              >
                {Array.isArray(similarState) && similarState.map(renderPostRelated)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
