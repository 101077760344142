import { TrashIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnnounceFormProps } from "data/reduxInterfaces";
import { FC, Fragment, HTMLAttributes, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

const formSchema = z.object({
  newImages: z
    .array(
      z.instanceof(File, {
        message: "L'image est obligatoire.",
      })
    )
    .refine((files) => {
      return files.every((file) => file?.size <= MAX_FILE_SIZE);
    }, "Le fichier ne doit pas dépasser 10MB.")
    .refine((files) => {
      return files.every((file) => ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type));
    }, "Le fichier doit être une image au format JPEG, PNG ou GIF."),
});

const AnnounceImages: FC<AnnounceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      newImages: data?.newImages ?? [],
    },
    mode: "onSubmit",
  });

  const [images, setImages] = useState<string[]>([]);
  const [oldImages, setOldImages] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      setImages(data.images);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormFour") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const handleAddImage = (files: FileList | null) => {
    if (files) {
      const fileArray = Array.from(files);
      setValue("newImages", [...getValues("newImages"), ...fileArray]);
      trigger("newImages");
    }
  };

  const handleDeleteImage = (index: number) => {
    const images = [...(getValues("newImages") || [])];
    images.splice(index, 1);
    setValue("newImages", images);
    trigger("newImages");
  };

  const handleDeleteOldImage = (index: number) => {
    setImages(data?.images.filter((_: any, i: any) => i !== index) ?? []);

    const imageToRemove = data?.images.filter((_: any, i: any) => i === index) ?? [];
    setOldImages([...oldImages, ...imageToRemove]);
  };

  const validateTotalFileSize = (files: File[]) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    return totalSize <= MAX_FILE_SIZE;
  };

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    const newImages = getValues("newImages");
    if (!validateTotalFileSize(newImages)) {
      toast.error("La taille totale des fichiers ne doit pas dépasser 10 Mo.");
      return;
    }

    FormData({ oldImages: oldImages, ...data });
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Images de l'espace</h2>
          </div>

          <div>
            <div>
              <div
                className="mb-8"
                onDrop={(e) => handleAddImage(e.dataTransfer.files)}
                onDragOver={(e) => handleAddImage(e.dataTransfer.files)}
              >
                <div className="mt-5">
                  <div className="mt-3">
                    <p className="text-sm text-green-500">Vos images:</p>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {images?.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={process.env.REACT_APP_CLOUD_FRONT + image}
                            alt={`Uploaded ${index}`}
                            className="h-auto max-w-xs max-h-40"
                          />

                          <TrashIcon
                            className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                            onClick={() => handleDeleteOldImage(index)}
                          />
                        </div>
                      ))}

                      {getValues("newImages")?.map((image: File, index: any) => (
                        <div key={index} className="relative">
                          <div className="relative">
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Newly uploaded ${index}`}
                              className="h-auto max-w-xs max-h-40"
                            />
                            <TrashIcon
                              className="absolute w-5 text-white bg-black rounded-full cursor-pointer top-2 right-2 h-7 las la-trash-alt"
                              onClick={() => handleDeleteImage(index)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                    <div className="space-y-1 text-center">
                      <svg
                        className="w-12 h-12 mx-auto text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="fileInput"
                          className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Ajouter</span>
                          <input
                            id="fileInput"
                            name="images"
                            type="file"
                            multiple
                            className="sr-only"
                            onChange={(e) => handleAddImage(e.target.files)}
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                          />
                        </label>
                        <p className="pl-1">ou déposer</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
              {errors.newImages?.message && (
                <div className="text-xs text-red-500">{`${errors.newImages?.message}`}</div>
              )}

              <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AnnounceImages;
