import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { OwnerStats, Transaction, TransactionList } from "data/types";

import { RootState } from "redux/store";
import { stripeApi } from "./stripeApi";

interface StripeState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  error?: SerializedError;

  transactionsList?: Transaction[];
  totalPages?: number;
  totalItems?: number;
  accounts?: any;
  coupons?: any;
  ownerStats?: OwnerStats;
}

const initialState: StripeState = {
  loading: "idle",
  transactionsList: undefined,
  totalPages: 0,
  totalItems: 0,
};

export const getUserStats = createAsyncThunk(
  "getUserStats",
  async ({ userId, account_id, status }: { userId: number; account_id: string; status: string }) => {
    return await stripeApi.getUserStats(userId, account_id, status);
  }
);

export const getAllTransactions = createAsyncThunk("getAllTransactions", async () => {
  const data: TransactionList = await stripeApi.getAllTransactions();
  return data;
});

export const getOwnerAllTransactions = createAsyncThunk(
  "getOwnerAllTransactions",
  async ({ account_id }: { account_id: string }) => {
    const data: TransactionList = await stripeApi.getOwnerAllTransactions(account_id);
    return data;
  }
);

export const getAllConnectedAccounts = createAsyncThunk(
  "getAllConnectedAccounts",
  async ({ pageNumber, startAfter }: { pageNumber: number; startAfter: string }) => {
    return await stripeApi.getAllAccounts(pageNumber, startAfter);
  }
);

export const getAllCoupons = createAsyncThunk("getAllCoupons", async ({ startAfter }: { startAfter: string }) => {
  return await stripeApi.getAllCoupons(startAfter);
});

export const deleteConnectedAccount = createAsyncThunk(
  "deleteConnectedAccount",
  async ({ id, owner_id }: { id: number; owner_id: any }) => {
    return await stripeApi.deleteAccount(id, owner_id);
  }
);

export const deleteCoupons = createAsyncThunk("deleteCoupons", async (id: number) => {
  return await stripeApi.deleteCoupons(id);
});

export const createCoupons = createAsyncThunk("createCoupons", async (data: any) => {
  return await stripeApi.addCoupon(data);
});

export const stripeSlice = createSlice({
  name: "Stripe",
  initialState,
  reducers: {
    initStates(state) {
      return {
        ...state,
        loading: "idle",
        transactionsList: [],
        totalPages: 0,
        totalItems: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserStats.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getUserStats.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.ownerStats = action.payload;
      })
      .addCase(getUserStats.rejected, (state) => {
        state.loading = "failed";
      });

    builder
      .addCase(getAllTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactionsList = action.payload.data;
        state.totalPages = Math.ceil(action.payload.data.length / 10);
        state.totalItems = action.payload.data.length;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loading = "failed";
      });

    builder
      .addCase(getOwnerAllTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getOwnerAllTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactionsList = action.payload.data;
        state.totalPages = Math.ceil(action.payload.data.length / 10);
        state.totalItems = action.payload.data.length;
      })
      .addCase(getOwnerAllTransactions.rejected, (state) => {
        state.loading = "failed";
      });

    builder
      .addCase(getAllConnectedAccounts.fulfilled, (state, action) => {
        if (action.payload) {
          state.accounts = action.payload;
        } else {
          state.accounts = undefined;
        }
      })
      .addCase(getAllConnectedAccounts.rejected, (state, action) => {
        state.error = action.error;
      });

    builder
      .addCase(deleteConnectedAccount.fulfilled, (state, action) => {
        if (action.payload) {
          state.accounts = action.payload;
        } else {
          state.accounts = undefined;
        }
      })
      .addCase(deleteConnectedAccount.rejected, (state, action) => {
        state.error = action.error;
      });

    builder
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        if (action.payload) {
          state.coupons = {
            data: [...(state.coupons?.data || []), ...action.payload.data],
            totalDiscounts: action.payload.totalDiscounts,
            hasMore: action.payload.has_more,
          };
        } else {
          state.accounts = undefined;
        }
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export const { initStates } = stripeSlice.actions;

export const selectCount = (state: RootState) => state.stripe;
export default stripeSlice.reducer;
