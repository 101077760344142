// CustomAccordion.tsx
import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface SubItem {
  to: string;
  icon: React.ReactNode;
  label: string;
}

interface CustomAccordionProps {
  open: boolean;
  handleOpen: () => void;
  title: string;
  icon: React.ReactNode;
  subItems: SubItem[];
  customIcon?: React.ReactNode;
  activeSubItem: string | null;
  handleSubItemClick: (path: string) => void;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({
  open,
  handleOpen,
  title,
  icon,
  subItems,
  customIcon,
  activeSubItem,
  handleSubItemClick,
}) => {
  return (
    <Accordion
      open={open}
      icon={
        customIcon || (
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`mx-auto h-4 w-4 transition-transform dark:text-white ${open ? "rotate-180" : ""}`}
          />
        )
      }
    >
      <ListItem className="p-0" selected={open}>
        <AccordionHeader onClick={handleOpen} className="p-3 border-b-0">
          <ListItemPrefix>{icon}</ListItemPrefix>
          <Typography color="blue-gray" className="mr-auto font-normal dark:text-white">
            {title}
          </Typography>
        </AccordionHeader>
      </ListItem>
      <AccordionBody className="py-1 max-h-[calc(100vh-300px)] overflow-y-auto crollbar-thin scrollbar-thumb-scroll-thumb scrollbar-track-scroll-track">
        <List className="p-0">
          {subItems.map((item, index) => (
            <Link to={item.to} key={index} onClick={() => handleSubItemClick(item.to)}>
              <ListItem>
                <ListItemPrefix>{item.icon}</ListItemPrefix>
                <span className={activeSubItem === item.to ? "font-bold p-1 dark:text-white" : "dark:text-white"}>
                  {item.label}
                </span>
              </ListItem>
            </Link>
          ))}
        </List>
      </AccordionBody>
    </Accordion>
  );
};

export default CustomAccordion;
