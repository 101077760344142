import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "../redux/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const formatDate = (dateString: any) => {
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString("fr-FR", options);
};

export const RIBDisplay = (rib: string) => {
  const firstFour = rib.slice(0, 4);
  const lastFour = rib.slice(-4);
  const maskedPart = "*".repeat(rib.length - 8);
  const newRIB = `${firstFour}${maskedPart}${lastFour}`;

  return newRIB;
};

export const useScrollToHash = (targetHash: string, targetId: string) => {
  useEffect(() => {
    if (window.location.hash === targetHash) {
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [targetHash, targetId]);
};
