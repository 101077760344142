import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];

export const schema = z.object({
  designation: z.string().nonempty("Ce champs est obligatoire."),
});

export const Couponschema = z
  .object({
    percent: z
      .number()
      .min(1, "l nombre doit étre supérieur à 0 ")
      .max(100, "Le nombre doit être inférieur ou égal à 100")
      .nonnegative("Ce champ est obligatoire."),
    max_redemptions: z.boolean({ required_error: "Ce champs est obligatoire." }),
    number_of_redemptions: z
      .number()
      .min(0, "le nombre doit être supérieur à 0")
      .nonnegative("Ce champ est obligatoire.")
      .optional(),
  })
  .refine(
    (data) => {
      return (
        data.max_redemptions === false ||
        (data.max_redemptions === true && data.number_of_redemptions !== undefined)
      );
    },
    {
      message: "number_of_redemptions est obligatoire lorsque max_redemptions est faux.",
      path: ["number_of_redemptions"],
    }
  );

export const announceCategorySchema = z.object({
  designation: z.string().nonempty("Ce champs est obligatoire."),
  images: z
    .instanceof(File, {
      message: "L'image  est obligatoire",
    })
    .refine(
      (file) => !file || (file.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file.type)),
      "Le fichier doit être une image au format JPEG, PNG , GIF "
    ),
});
export type CouponDataSchemaType = z.infer<typeof Couponschema>;

export type DataSchemaType = z.infer<typeof schema>;
export type AnnounceCategoryDataSchemaType = z.infer<typeof announceCategorySchema>;
