import { CardFooter, Typography } from "@material-tailwind/react";
import Label from "components/Label/Label";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import DashboardMenu from "../../DashboardPage/DashboardMenu";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addVideo } from "redux/features/Admin/videos/videoSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export interface AddVideoPageProps {
  className?: string;
}
const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
const formSchema = z.object({
  title: z.string().min(1, { message: "Le Prénom est obligatoire." }),
  youtubeId: z.string().min(1, { message: "Le nom  est obligatoire." }),
  thumbnail: z
    .instanceof(File, {
      message: "L'image est obligatoire.",
    })
    .refine((file) => file?.size <= MAX_FILE_SIZE, `La taille maximale d'image est de 10MB.`)
    .refine(
      (file) => ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type),
      "Le fichier doit être une image au format JPEG, PNG ou GIF."
    ),
});

const AddVideoPage: FC<AddVideoPageProps> = ({ className = "" }) => {
  const {
    register,
    handleSubmit: handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const videoState = useAppSelector((state) => state.video || []);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    await dispatch(addVideo(data)).then(() => {
      navigate("/admin/dashboard/settings/videos");
    });
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    switch (videoState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [videoState.loading]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <form onSubmit={handleSubmit(onSubmit)}>
          <span className="grid mb-4 h-28 place-items-center "></span>
          <br />
          <Label>Titre du vidéo</Label>
          <br />
          <Input {...register("title")} name="title" placeholder="Titre du video" />

          {errors.title?.message && <div className="text-xs text-red-500">{errors.title?.message}</div>}

          <Label>Youtube vidéo ID</Label>
          <br />
          <Input {...register("youtubeId")} placeholder="youtube video ID" name="youtubeId" />
          {errors.youtubeId?.message && <div className="text-xs text-red-500">{errors.youtubeId?.message}</div>}
          {getValues("thumbnail") && (
            <div className="mt-3">
              <p className="text-sm text-green-500">Vos images:</p>
              <div className="flex flex-wrap gap-2 mt-2">
                {getValues("thumbnail") && (
                  <div className="relative">
                    <img src={URL.createObjectURL(getValues("thumbnail"))} className="h-auto max-w-xs max-h-40" />

                    <TrashIcon
                      className="absolute w-5 text-white bg-black rounded-full cursor-pointer h-7 las la-trash-alt top-2 right-2"
                      //onClick={() => handleDeleteImage(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
            <div className="space-y-1 text-center">
              <svg
                className="w-12 h-12 mx-auto text-neutral-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                <label
                  htmlFor="fileInput"
                  className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                >
                  <span>Ajouter</span>
                  <input
                    id="fileInput"
                    name="images"
                    type="file"
                    multiple
                    className="sr-only"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        setValue("thumbnail", file);
                        trigger("thumbnail");
                      }
                    }}
                    accept="image/jpeg, image/jpg, image/png, image/webp"
                  />
                </label>
                <p className="pl-1">ou déposer</p>
              </div>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
            </div>
          </div>

          {errors.thumbnail?.message && <div className="text-xs text-red-500">{errors.thumbnail?.message}</div>}

          <CardFooter className="pt-0">
            <Typography variant="small" className="flex justify-center mt-6">
              <ButtonPrimary type="submit" loading={isLoading}>
                Ajouter
              </ButtonPrimary>
            </Typography>
          </CardFooter>
        </form>
      </DashboardMenu>
    </div>
  );
};

export default AddVideoPage;
