import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import WarningModal from "components/DeleteModal/WarningModal";
import { EntityDetails } from "components/FormDialog/EntityDetails";
import List from "components/NavMenu/List";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import { formatDate, useAppDispatch, useAppSelector } from "hooks/hooks";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { deleteContact } from "redux/features/contact/contactSlice";
import { deleteReservation, fetchTentativeReservation } from "redux/features/reservations/reservationSlice";
import Input from "shared/Input/Input";

export interface TentativeReservationsProps {
  className?: string;
}

interface ModalState {
  isCategory: boolean;
  isDeleteModalOpen: boolean;
  selectedItem: any | null;
  loading: boolean;
}

const TentativeReservations: FC<TentativeReservationsProps> = ({ className = "" }) => {
  const columns = [
    {
      title: "Date",
      accessor: (item: any) => formatDate(item.createdAt),
    },
    { title: "Réference", accessor: "reference" },
  ];
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [modalState, setModalState] = useState<ModalState>({
    loading: false,
    isCategory: false,
    isDeleteModalOpen: false,
    selectedItem: null,
  });
  const reservations = useAppSelector((state) => state.featureReservation?.tentative);
  const totalPages = useAppSelector((state) => state.featureReservation?.totalPages);

  useEffect(() => {
    dispatch(fetchTentativeReservation({ page: 1, reference: "" }));
  }, []);

  const getDataAction = () => {
    return (page: number) => dispatch(fetchTentativeReservation({ page: page, reference: searchTerm }));
  };

  const handleConsult = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isCategory: true });
  };

  const handleOnDelete = (item: any) => {
    setModalState({ ...modalState, selectedItem: item, isDeleteModalOpen: true });
  };

  const handleConfirmDelete = async () => {
    if (!modalState.selectedItem) return;
    setModalState((prev) => ({ ...prev, loading: true }));
    await dispatch(deleteReservation(modalState.selectedItem.id));
    dispatch(fetchTentativeReservation({ page: 1, reference: "" }));
    setModalState((prev) => ({ ...prev, isDeleteModalOpen: false, loading: false }));
  };

  useEffect(() => {
    dispatch(fetchTentativeReservation({ page: 1, reference: searchTerm })).then(() => {});
  }, [searchTerm, dispatch]);

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event To Bee</title>
      </Helmet>
      <DashboardMenu>
        <div className="flex items-center justify-between p-3 mb-5">
          <span className=""></span>
          <div className="text-center">
            <h2 className="font-semibold">Liste des tentatives</h2>
          </div>
          <div className="relative flex items-center mb-3">
            <Input
              type="text"
              placeholder="Rechercher par désignation"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pr-10"
            />
            <MagnifyingGlassCircleIcon className="absolute w-6 h-6 text-gray-400 right-2" />
          </div>
        </div>
        <List
          columns={columns}
          getDataAction={getDataAction()}
          data={reservations}
          totalPages={totalPages}
          loading={modalState.loading}
          onDelete={handleOnDelete}
          onConsult={handleConsult}
          showConsult={true}
          showEdit={false}
        />

        {modalState.isDeleteModalOpen && (
          <WarningModal
            open={modalState.isDeleteModalOpen}
            id={modalState.selectedItem?.id}
            title={modalState.selectedItem?.reference}
            handleClose={() => setModalState({ ...modalState, isDeleteModalOpen: false })}
            handleDelete={handleConfirmDelete}
            isLoading={modalState.loading}
          />
        )}

        {modalState.isCategory && (
          <EntityDetails
            open={modalState.isCategory}
            handleClose={() =>
              setModalState({ ...modalState, isCategory: false, selectedItem: null, isDeleteModalOpen: false })
            }
            title={`Détails du réservation : ${modalState?.selectedItem?.reference}`}
            id={modalState.selectedItem?.id}
            value={modalState.selectedItem}
            details={[
              { label: "Espace", value: modalState.selectedItem?.Location?.title },
              {
                label: "Client",
                value: modalState.selectedItem?.Client?.name + " " + modalState.selectedItem?.Client?.lastName,
              },
              {
                label: "Propriétaire",
                value:
                  modalState.selectedItem?.Owner?.name + " " + modalState.selectedItem?.Owner?.lastName ||
                  modalState.selectedItem?.Owner?.companyName,
              },
              { label: "Montant", value: modalState.selectedItem?.price + " € " },
              { label: "Journée", value: formatDate(modalState.selectedItem?.selectedDate) },
              {
                label: "Durée",
                value: modalState.selectedItem?.startHour + " - " + modalState.selectedItem?.endHour,
              },
            ]}
          />
        )}
      </DashboardMenu>
    </div>
  );
};

export default TentativeReservations;
