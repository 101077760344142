import { PencilIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnnounceFormProps } from "data/reduxInterfaces";
import { FC, Fragment, HTMLAttributes, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Textarea from "shared/Textarea/Textarea";
import { z } from "zod";

const formSchema = z.object({
  description: z.string().min(10, {
    message: "La description est obligatoire et doit contenir au minimum 10 caractères.",
  }),
});

const AnnounceDescription: FC<AnnounceFormProps & HTMLAttributes<HTMLDivElement>> = ({
  isLoading,
  handleForm,
  data,
  FormData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },

    setValue,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: data?.description ?? "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (data) {
      setValue("description", data.description);
    }
  }, [data]);

  useEffect(() => {
    if (handleForm.type === "chekLocationFormThree") {
      handleSubmit(onSubmit)();
    }
  }, [handleForm]);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (data) => {
    FormData(data);
  };

  return (
    <Fragment>
      <form className="flex flex-col md:flex-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full md:mr-4">
          <div>
            <h2 className="text-2xl font-semibold">Description de votre annonce </h2>
          </div>

          <div className="border-b w-14 border-neutral-200 dark:border-neutral-700"></div>

          <div className="space-y-8">
            <div></div>
            <Textarea disabled={isLoading} placeholder="..." rows={14} {...register("description")} />
            {errors.description?.message && (
              <div className="text-xs text-red-500">{`${errors.description?.message}`}</div>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AnnounceDescription;
