import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_SERVER_URL;

export const announceApi = {
  getAnnouncesByAdmin: async (page: number, status: string) => {
    const result = await fetch(`${baseURL}/api/admin/announces?page=${page}&status=${status}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return result.json();
  },

  createAnnounce: async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "newImages") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.newImages && Array.isArray(data.newImages)) {
      data.newImages.forEach((image: any, index: any) => {
        formData.append(`newImages`, image);
      });
    }

    const result = await fetch(`${baseURL}/api/announces`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  updateAnnounce: async (data: any, id: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "newImages") {
        if (data[key] !== undefined && data[key] !== null) {
          if (Array.isArray(data[key])) {
            formData.append(key, JSON.stringify(data[key]));
          } else {
            formData.append(key, data[key]);
          }
        }
      }
    }

    if (data.newImages && Array.isArray(data.newImages)) {
      data.newImages.forEach((image: any, index: any) => {
        formData.append(`newImages`, image);
      });
    }

    const result = await fetch(`${baseURL}/api/announces/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
      body: formData,
    });
    return result.json();
  },

  getAnnounceAllDataById: async (id: string) => {
    const result = await fetch(`${baseURL}/api/announces-data/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  updateAnnounceStatus: async (data: any, id: any) => {
    try {
      const result = await fetch(`${baseURL}/api/admin/announces/${id}/validation`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          validation: data.validation,
          reason: data.reason,
        }),
      });

      if (!result.ok) {
        const errorData = await result.json();
        throw new Error(errorData.error || "An error occurred while updating announce validation.");
      }
      if (data.validation === "blocked") {
        toast.success("Annonce bloqué avec success");
      } else {
        toast.success("Annonce approuvé avec success");
      }
      return result.json();
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      // Handle errors here
      throw new Error("Error updating Annonce validation.");
    }
  },

  getAnnouncesByUserId: async (page: number) => {
    const result = await fetch(`${baseURL}/api/announces-userId?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  updateAnnounceStatusByUserId: async (data: any, id: any) => {
    try {
      const result = await fetch(`${baseURL}/api/announces/${id}/status`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
        body: JSON.stringify({
          status: data,
        }),
      });

      if (!result.ok) {
        const errorData = await result.json();
        throw new Error(errorData.error || "An error occurred while updating announce validation.");
      }
      toast.success(await result.json());
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      // Handle errors here
      throw new Error("Error updating Annonce validation.");
    }
  },
  deleteAnnounce: async (id: number) => {
    try {
      const result = await fetch(`${baseURL}/api/announces/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
        },
      });

      if (!result.ok) {
        const errorData = await result.json();
        throw new Error(errorData.error || "An error occurred while deleting announce .");
      }
      const res = await result.json();
      toast.success(res.message);
    } catch (error) {
      console.error("error", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
      throw new Error("Error updating Annonce validation.");
    }
  },

  getAnnounces: async (page: number) => {
    const result = await fetch(`${baseURL}/api/announces?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },
  getAnnouncesBySlug: async (slug: string) => {
    const result = await fetch(`${baseURL}/api/announces/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
      },
    });

    return result.json();
  },

  getFilteredAnnounces: async (page: number, filters: any) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.region) {
        queryParams.append("region", filters.region);
      }
      if (filters.title !== undefined) queryParams.append("title", filters.title);
      if (filters.category !== undefined) queryParams.append("category", filters.category);
      if (filters.minPrice !== undefined) queryParams.append("minPrice", filters.minPrice);
      if (filters.maxPrice !== undefined) queryParams.append("maxPrice", filters.maxPrice);
      const url = `${baseURL}/api/announces-search?${queryParams.toString()}&page=${page}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      return {
        totalPages: data.totalPages,
        count: data.count,
        announces: data.announces,
        totalCount: data.totalCount,
      };
    } catch (error) {
      console.error("Error fetching or processing data:", error);
      throw error;
    }
  },
};
