import { Switch } from "@mui/material";
import WarningModal from "components/DeleteModal/WarningModal";
import { useAppDispatch } from "hooks/hooks";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteAnnounce,
  getAnnouncesByUserId,
  updateAnnounceStatusByUserId,
} from "redux/features/announce/announceSlice";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";

export interface CarCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  featuredImage?: string | string[];
}

const AnnounceCard: FC<CarCardProps> = ({ size = "default", className = "", featuredImage, data }) => {
  const { id, title, validation, city, status, slug } = data;

  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setIsDeletModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateAnnounce = async (item: number, status: string) => {
    const newStatus = status === "inactive" ? "inactive" : "active";
    await dispatch(updateAnnounceStatusByUserId({ data: newStatus, id: item })).then(() => {
      dispatch(getAnnouncesByUserId(1));
    });
  };

  const deleteAnnounceById = async (id: number) => {
    setIsLoading(true);
    await dispatch(deleteAnnounce(id));
    setIsLoading(false);
    dispatch(getAnnouncesByUserId(1));
  };

  const handleDeleteClick = () => {
    setIsDeletModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (id) {
      deleteAnnounceById(id);
    }
    setIsDeletModalOpen(false);
  };

  const renderSliderGallery = () => {
    const imageToShow = Array.isArray(featuredImage) ? featuredImage[0] : featuredImage;

    return (
      <div className="relative w-full overflow-hidden rounded-2xl">
        <div className="aspect-w-16 aspect-h-9 ">
          <Link to={`/annonces/${slug}`}>
            <NcImage
              containerClassName="flex items-center justify-center"
              className="w-full"
              src={imageToShow ? process.env.REACT_APP_CLOUD_FRONT + imageToShow : ""}
            />
          </Link>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const isChecked = status === "publié";
    const isDisabled = validation !== "approuvé";
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Badge name={validation} color="green" />
          </div>
          <div>
            <h2
              className={`  capitalize ${size === "default" ? "text-xl font-semibold" : "text-base font-medium"}`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-2 text-sm text-neutral-500 dark:text-neutral-400">
            <span className="">{city} </span>
          </div>
        </div>
        <div className="border-b w-14 border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex items-center justify-between">
          <Switch
            checked={isChecked}
            disabled={isDisabled}
            onClick={() => updateAnnounce(id, isChecked ? "inactive" : "publié")}
          />
          <Link to={`/update-announce/${id}`}>
            <Badge
              color="green"
              name={<i className="text-2xl cursor-pointer las la-edit" onClick={() => handleDeleteClick()}></i>}
            />
          </Link>
          <Badge
            color="red"
            name={<i className="text-2xl cursor-pointer las la-trash-alt" onClick={() => handleDeleteClick()}></i>}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-AnnounceCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="AnnounceCard"
    >
      {renderSliderGallery()}
      {renderContent()}

      {isDeleteModalOpen && (
        <WarningModal
          open={isDeleteModalOpen}
          handleClose={() => setIsDeletModalOpen(false)}
          handleDelete={handleDeleteConfirm}
          isLoading={isLoading}
          id={id}
          title={title}
        />
      )}
    </div>
  );
};

export default AnnounceCard;
