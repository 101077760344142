import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Card, CardBody, CardFooter, CardHeader, Typography } from "@material-tailwind/react";
import DashboardMenu from "containers/Admin/DashboardPage/DashboardMenu";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router";
import { registerCreator } from "redux/features/signUp/signUpSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { z } from "zod";
const formSchema = z.object({
  login: z.string().min(1, { message: "Titre est obligatoire." }),
  email: z.string().email({ message: "Veuillez entrer une adresse email valide." }),

  password: z
    .string()
    .regex(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ]).{8,26}$/,
      "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character"
    ),
});

const AccountAdd = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signUpError = useAppSelector((state) => state.signUp.signUpError);

  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    const res = await dispatch(registerCreator(data));
    if (res.payload.status === "OK") {
      navigate("/admin/dashboard/users/creators");
    }
  };

  return (
    <div>
      <DashboardMenu>
        <div className="space-y-6 sm:space-y-8">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            <Card className="mx-auto w-full max-w-[24rem] dark:bg-gray-800">
              <CardHeader className="grid mb-4 h-28 place-items-center dark:bg-gray-800">
                {signUpError ? (
                  <Alert color="red" className="mt-5 text-xs">
                    {signUpError}
                  </Alert>
                ) : (
                  <div className="dark:text-white">Rédacteur</div>
                )}
              </CardHeader>
              <CardBody className="flex flex-col gap-4">
                <span className="text-neutral-800 dark:text-neutral-200">Pseudo *</span>
                <Input type="text" placeholder="username" className="mt-1" {...register("login")} name="login" />
                {errors.login?.message && <div className="text-xs text-red-500 ">{errors.login?.message}</div>}
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">Adresse Email *</span>
                  <Input
                    type="text"
                    placeholder="example@example.com"
                    className="mt-1"
                    {...register("email")}
                    name="email"
                  />
                  {errors.email?.message && <div className="text-xs text-red-500 ">{errors.email?.message}</div>}
                </label>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">Mot de passe *</span>
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="mt-1"
                    {...register("password")}
                    name="password"
                    placeholder="mot de passe"
                  />
                  {errors.password?.message && (
                    <div className="text-xs text-red-500 ">{errors.password?.message}</div>
                  )}
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-5 mt-5 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </label>
              </CardBody>
              <CardFooter className="pt-0">
                <Typography variant="small" className="flex justify-center mt-6">
                  <ButtonPrimary type="submit">Ajouter</ButtonPrimary>
                </Typography>
              </CardFooter>
            </Card>
          </form>
        </div>
      </DashboardMenu>
    </div>
  );
};
export default AccountAdd;
