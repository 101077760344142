import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, CardBody, CardFooter, Checkbox, Textarea, Typography } from "@material-tailwind/react";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { AuthContext } from "context/appContext";
import { ProfilClient } from "data/types";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { loadavg } from "os";
import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaBackspace, FaRegWindowClose } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  addAvis,
  getService,
  nextStep,
  previousStep,
  setCurrentStep,
  setServiceAvis,
  setTotalSteps,
} from "redux/features/service/serviceSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { socket } from "utils/socket";
import { z } from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 10;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

const formSchema = z.object({
  like: z.number(),
  text: z.string().min(1, { message: "La description est obligatoire." }),
  price: z.number().optional(),
  visitor: z.number().optional(),
  isWebHelp: z.boolean(),
  images: z
    .optional(z.instanceof(File, { message: "L'image est obligatoire." }))
    .refine(
      (file) => !file || (file?.size <= MAX_FILE_SIZE && ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type)),
      "Le fichier doit être une image au format JPEG, PNG ou GIF et ne pas dépasser 10MB."
    ),
});

interface RatingModalProps {
  onClose: (value: boolean) => void;
  id: any;
}
const RatingModal: React.FC<RatingModalProps> = ({ onClose, id }) => {
  const serviceState = useAppSelector((state) => state.service);

  const [open, setOpen] = React.useState(false);
  const authContext = useContext(AuthContext);
  const handleClose = () => {
    setOpen(false);
    onClose(open);
    if (serviceState?.currentStep === 2) {
      dispatch(setCurrentStep(1));
    }
  };

  const handleChange = (checked: boolean) => {
    setValue("isWebHelp", checked);
    dispatch(setServiceAvis({ isWebHelp: checked }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { images: undefined, isWebHelp: false },
    mode: "onSubmit",
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTotalSteps(2));
    dispatch(setCurrentStep(1));
  }, []);

  let profile = authContext.user?.profil as ProfilClient;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userState: any = useAppSelector((state) => state?.auth?.user);

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    if (serviceState?.currentStep === 2) {
      setIsLoading(true);
      await dispatch(
        addAvis({
          serviceAgentId: serviceState?.serviceData?.ServiceAgent?.id,
          serviceId: serviceState?.serviceData?.id,
          clientId: authContext.user?.profil?.id,
          firstName: profile?.name,
          lastName: profile?.lastName,
          email: authContext.user?.email,

          ...data,
        })
      );

      if (serviceState?.serviceData?.ServiceAgent?.id !== userState.id) {
        socket.emit("send-notification", {
          senderId: userState?.id,
          prestationReceiverId: serviceState?.serviceData?.ServiceAgent?.userId,
          action: "send_presta_avis",
          prestationId: serviceState?.serviceData?.id,
          prestation: serviceState.serviceData,
        });
      }
    }
    setOpen(false);
    onClose(open);
    if (serviceState?.serviceData?.id) {
      dispatch(getService(serviceState?.serviceData?.id));
    }
  };

  const handleNextStep = async () => {
    const isValidText = await trigger("text");
    const isValidLike = await trigger("like");

    if (isValidText && isValidLike) {
      dispatch(nextStep());
    }
  };

  useEffect(() => {
    switch (serviceState.loading) {
      case "pending": {
        setIsLoading(true);
        break;
      }
      case "succeeded": {
        setIsLoading(false);
        if (serviceState.totalSteps === serviceState.currentStep) {
        } else {
          dispatch(nextStep());
        }
        break;
      }
      case "failed": {
        setIsLoading(false);
        break;
      }
    }
  }, [serviceState.loading]);

  const [images, setImages] = useState<File[]>([]);
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files) {
      const fileArray = Array.from(files);
      setImages(fileArray);
      // Set the first file from the array or undefined
      setValue("images", fileArray[0] || undefined);
      trigger("images");
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const [showAlert, setShowAlert] = React.useState(false);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      {serviceState?.currentStep === 1 && (
        <div className="flex flex-col  max-w-[27rem] ">
          <div className="self-end mr-5">
            <FaRegWindowClose className="text-xl cursor-pointer" onClick={handleClose} />
          </div>
          <h2 className="sticky top-0 w-full p-4 text-2xl font-extrabold text-center border-b border-grey">
            {serviceState?.serviceData?.title}
          </h2>
          <main className="overflow-y-scroll">
            <div className="">
              <h5 className="mt-2 font-bold text-center">Partagez votre expérience</h5>
              <p className="mt-5 text-sm">
                Nous apprécions les commentaires honnêtes sur nos fournisseurs de la part des clients qui ont
                réservé leurs prestations .
              </p>
              <p className="mt-3 font-semibold text">Évaluez le prestataire*</p>
              <div className="mt-3">
                <FiveStartIconForRate
                  iconClass="w-6 h-6"
                  className="space-x-0.5"
                  onClick={(value: number) => {
                    setValue("like", value);
                    dispatch(setServiceAvis({ like: value }));
                  }}
                />
                {errors.like?.message && <div className="text-xs text-red-500">{errors.like?.message}</div>}
              </div>
              <p className="mt-5 font-semibold text">Donnez votre avis *</p>
              <div className="relative mt-3">
                <Textarea
                  placeholder="Partage tes pensées ..."
                  rows={3}
                  {...register("text")}
                  onChange={(e) => {
                    setValue("text", e.target.value);
                    dispatch(setServiceAvis({ text: e.target.value }));
                  }}
                  className="rounded-none"
                />
              </div>
              {errors.text?.message && <div className="text-xs text-red-500">{errors.text?.message}</div>}
              <p className="mt-5 font-semibold text">Event To Bee vous a-t-il aidé à embaucher ce prestataire ?</p>
              <div className="">
                <Checkbox
                  key="true"
                  label="Oui"
                  value="true"
                  checked={getValues("isWebHelp")}
                  onChange={(e) => handleChange(e.target.checked)}
                />
              </div>
            </div>
          </main>
          <br />
          <ButtonPrimary
            type="button"
            className="w-full px-4 py-2 text-white bg-indigo-600 rounded"
            onClick={handleNextStep}
          >
            Suivant
          </ButtonPrimary>
        </div>
      )}

      {serviceState?.currentStep === 2 && (
        <div className="flex flex-col  max-w-[27rem] ">
          <div className="flex justify-between">
            <div className="self-start">
              <FaBackspace className="ml-5 text-xl cursor-pointer" onClick={() => dispatch(previousStep())} />
            </div>
            <div className="self-end mr-5">
              <FaRegWindowClose className="text-xl cursor-pointer" onClick={handleClose} />
            </div>
          </div>
          <h2 className="sticky top-0 w-full p-4 text-2xl font-extrabold text-center border-b border-grey">
            {serviceState?.serviceData?.title}
          </h2>
          <main className="overflow-y-scroll">
            <div className="">
              <p className="mt-3 font-semibold text">Ajoutez des photos à votre avis (facultatif)</p>
              <div className="mb-8" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="mt-5">
                  {getValues("images") && (
                    <div className="mt-3">
                      <p className="text-sm text-green-500">Vos images:</p>
                      <div className="flex flex-wrap gap-2 mt-2">
                        <div key={""} className="relative">
                          <img
                            src={getValues("images") ? URL.createObjectURL(getValues("images") as File) : ""}
                            className="h-auto max-h-20 max-w-20"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-dashed rounded-md border-neutral-300 dark:border-neutral-6000">
                    <div className="space-y-1 text-center">
                      <svg
                        className="w-12 h-12 mx-auto text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="fileInput"
                          className="relative font-medium rounded-md cursor-pointer text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Ajouter</span>
                          <input
                            id="fileInput"
                            name="images"
                            type="file"
                            multiple
                            className="sr-only"
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                setValue("images", file);
                                trigger("images");
                              }
                            }}
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                          />
                        </label>
                        <p className="pl-1">ou déposer</p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF jusqu'à 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-3 font-semibold text">Questions budgétaires*</p>
              <p className="mt-3 text-sm">Combien avez-vous dépensé avec cet Agent ?</p>

              <Input
                type="Number"
                placeholder="Dépense"
                className={`mt-1`}
                {...register("price", { setValueAs: (v) => (v === "" ? undefined : parseFloat(v)) })}
              />
              {errors.price?.message && <div className="mt-1 text-xs text-red-500">{errors.price?.message}</div>}

              <p className="mt-3 text-sm">Combien d’invités étaient présents ?</p>

              <Input
                type="Number"
                placeholder="Invités"
                className={`mt-1`}
                {...register("visitor", { setValueAs: (v) => (v === "" ? undefined : parseInt(v)) })}
              />
              {errors.visitor?.message && (
                <div className="mt-1 text-xs text-red-500">{errors.visitor?.message}</div>
              )}
              <div className="">
                <p className="font-semibold">
                  En cliquant sur « Soumettre votre avis », vous acceptez la politique de confidentialité et les
                  conditions d'utilisation de Event To Bee.
                </p>
              </div>
            </div>
          </main>
          <ButtonPrimary
            className="w-full px-4 py-2 text-white bg-indigo-600 rounded"
            type="submit"
            loading={isLoading}
          >
            Soumettez votre avis
          </ButtonPrimary>
        </div>
      )}
    </form>
  );
};
export default RatingModal;
